@charset "UTF-8";
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Oxygen&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #566CF0;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #566CF0;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #1432e5;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d0d6fb;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a7b3f7;
}

.table-hover .table-primary:hover {
  background-color: #b9c2f9;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b9c2f9;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c;
}

.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #cbd2fa;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(86, 108, 240, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(86, 108, 240, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #566CF0;
  border-color: #566CF0;
}
.btn-primary:hover {
  color: #fff;
  background-color: #334ded;
  border-color: #2743ec;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #334ded;
  border-color: #2743ec;
  box-shadow: 0 0 0 0.2rem rgba(111, 130, 242, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #566CF0;
  border-color: #566CF0;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #2743ec;
  border-color: #1b39eb;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(111, 130, 242, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-outline-primary {
  color: #566CF0;
  border-color: #566CF0;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #566CF0;
  border-color: #566CF0;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 108, 240, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #566CF0;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #566CF0;
  border-color: #566CF0;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 108, 240, 0.5);
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #566CF0;
  text-decoration: none;
}
.btn-link:hover {
  color: #1432e5;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #566CF0;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #566CF0;
  background-color: #566CF0;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(86, 108, 240, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #cbd2fa;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fafbff;
  border-color: #fafbff;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #566CF0;
  background-color: #566CF0;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(86, 108, 240, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(86, 108, 240, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(86, 108, 240, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(86, 108, 240, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #cbd2fa;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(86, 108, 240, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #cbd2fa;
  box-shadow: 0 0 0 0.2rem rgba(86, 108, 240, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(86, 108, 240, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(86, 108, 240, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(86, 108, 240, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #566CF0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #fafbff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #566CF0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #fafbff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #566CF0;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #fafbff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #566CF0;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #566CF0;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #1432e5;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(86, 108, 240, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #566CF0;
  border-color: #566CF0;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #566CF0;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #2743ec;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(86, 108, 240, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #545b62;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #343a40;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1d2124;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #2d387d;
  background-color: #dde2fc;
  border-color: #d0d6fb;
}
.alert-primary hr {
  border-top-color: #b9c2f9;
}
.alert-primary .alert-link {
  color: #202758;
}

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #566CF0;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #566CF0;
  border-color: #566CF0;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #2d387d;
  background-color: #d0d6fb;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #2d387d;
  background-color: #b9c2f9;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2d387d;
  border-color: #2d387d;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #566CF0 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2743ec !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-right {
  border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #566CF0 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #343a40 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #566CF0 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #1432e5 !important;
}

.text-secondary {
  color: #6c757d !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #343a40 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
@media print {
  li {
    list-style: square !important;
  }
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .allSameSize {
    font-size: 0.9rem !important;
  }
}
/* Chrome 22-28 */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .allSameSize {
    font-size: 0.9rem !important;
  }
}
/*@media screen{
  html {
    overflow-x: hidden !important;
  }

  body{
    overflow-x: hidden !important;
  }
}*/
.success_light {
  background-color: rgba(85, 210, 188, 0.2274509804);
}

a:hover {
  text-decoration: none;
}

.product {
  background-color: transparent !important;
}

.bg-primary-opac {
  background-color: rgba(89, 116, 184, 0.1333333333);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .annonce small, .annonce p {
    font-size: 10px !important;
  }
}
.presta .pres-price {
  flex-wrap: wrap;
}

.label-none label {
  display: none;
}

.bg-input-none input {
  background-color: transparent !important;
  border-color: transparent !important;
}

.label-bigger label {
  font-weight: 500 !important;
}

.select-higher select, .select-higher option {
  min-height: 40px !important;
  height: 40px !important;
  border: 1px solid transparent;
  background-color: white;
}

@media (max-width: 976px) {
  .mobile-content {
    min-height: 90vh !important;
    max-height: 90vh !important;
    overflow: auto;
    margin-bottom: 40px;
  }
}

.progress-1-complete {
  width: 34%;
}
@media (max-width: 768px) {
  .progress-1-complete {
    width: 19%;
  }
}

.progress-2-complete {
  width: 50%;
}

.progress-3-complete {
  width: 65%;
}
@media (max-width: 768px) {
  .progress-3-complete {
    width: 81%;
  }
}

@media (max-width: 976px) {
  ._correct-input-tva {
    max-width: 89% !important;
  }
}

.wrap-labels .appointment-class {
  display: flex;
  flex-wrap: wrap !important;
}

.cookie {
  height: 15vh;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 50px !important;
  padding-bottom: 50px !important;
  width: 30%;
}
.cookie div {
  width: 85%;
}
@media (max-width: 976px) {
  .cookie {
    height: 8vh !important;
    margin-bottom: 90px !important;
    width: 80%;
  }
  .cookie button {
    min-width: 70px;
  }
}
@media (max-width: 676px) {
  .cookie {
    height: 28vh;
    margin-bottom: 20px;
    width: 100%;
    background-color: transparent;
  }
  .cookie img {
    width: 30px !important;
    height: 30px !important;
  }
  .cookie button {
    min-width: 120px;
    margin-bottom: 2px;
  }
}

.go_submit_acc {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
@media (max-width: 876px) {
  .go_submit_acc {
    border-radius: 30px;
  }
}

@media (max-width: 776px) {
  .mb-perso-banner {
    margin-bottom: 0% !important;
  }
}

.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #566CF0;
}

.checkmark_animate {
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark_quick {
  stroke-width: 4;
}

.checkmark_animate_primary {
  animation: fill-primary 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #566CF0;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__circle_quick {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 0;
  stroke-miterlimit: 0;
  stroke: transparent;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-width: 4 !important;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.text-light-perso {
  color: rgba(77, 77, 77, 0.137254902);
}

.checkmark__check_quick {
  transform-origin: 50% 50%;
  stroke-dasharray: 58;
  stroke-dashoffset: 58;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.2s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #44B4AB;
  }
}
@keyframes fill-primary {
  100% {
    box-shadow: inset 0px 0px 0px 30px #566CF0;
  }
}
.roundedImage {
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  width: 90px;
  height: 90px;
}

.roundedImage img {
  min-width: 100%;
  height: 100%;
}

.roundedImageSmall {
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  width: 60px;
  height: 60px;
  border: 1px solid #566CF0;
}

.roundedImageSmall img {
  min-width: 100%;
  height: 100%;
}

.squareRoundedImage {
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  width: 90px;
  height: 90px;
}

.squareRoundedImage .imageHorse {
  min-width: 100%;
  height: 100%;
}

.text-blue {
  color: #597BF1;
}

.text-green {
  color: #44B4AB;
}

.roundedImageBig {
  overflow: hidden;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  width: 120px;
  height: 120px;
}

.roundedImageBig img {
  min-width: 100%;
  height: 100%;
}

.border-bottom-perso {
  border-bottom: 1px solid white;
}

.text-gradient {
  background: -webkit-linear-gradient(#743ad5, #d53a9d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.border-gradient {
  border-image: linear-gradient(to left, #743ad5 0%, #d53a9d 100%);
  border-image-slice: 1;
  border-width: 2px;
}

.btn-outline-pink {
  border-radius: 15px;
}

p, h1, h2, h3, h4, h5, h6, span, small {
  font-weight: 300 !important;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.font-special-valkae {
  font-family: "Montserrat", sans-serif;
  font-size: 3rem;
}
@media (max-width: 776px) {
  .font-special-valkae {
    font-size: 2rem;
  }
}

@media (max-width: 776px) {
  .font-special-valkae-pro {
    font-size: 2.5rem;
  }
}

@media (max-width: 776px) {
  .font-special-valkae-pro .badge {
    font-size: 0.9rem !important;
  }
}

.table-pink {
  background-color: white;
}

.btn-outline-pink:hover {
  color: white;
}

img {
  height: 100%;
}

#header_blocB div {
  max-height: 50px;
}

@media (max-width: 970px) {
  #header_blocG .roundedImage {
    max-width: 40px;
    max-height: 40px;
  }
}
@media (max-width: 770px) {
  #header_blocG .roundedImage {
    width: 90px;
    height: 90px;
  }
}

#date-of-today {
  min-height: 100px;
  max-height: 200px;
}

.bg-image-pattern {
  background-image: url("../../public/icones/pattern.png");
  background-repeat: repeat;
  background-size: contain;
}

._bg-annonces {
  background-image: url("../../public/icones/bg_annonces.svg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 20vh !important;
}

.rond {
  width: 40px;
  height: 40px;
}

.bg-success-green {
  background-image: linear-gradient(-20deg, #f794a4 0%, #fdd6bd 100%);
}

.resize-select {
  max-width: 280px !important;
  width: 100% !important;
  overflow: hidden;
  padding: 4px;
  font-size: 0.95rem;
}
.resize-select option {
  max-width: 100% !important;
}

#horse-icon {
  opacity: 1;
}

.loading-form img {
  opacity: 0.4;
}

.z-index-less {
  z-index: 1;
}

.z-index-more {
  z-index: 90;
}

.background_red {
  background-image: url("../../public/fond_red.svg");
  background-size: cover;
}

.input-100 input {
  width: 100%;
}

.input-90 input {
  width: 90%;
}

.bug-telephone div {
  margin: 0px;
}

.bug-telephone input {
  margin: 0px;
  padding: 0px;
}

.btn-width-div button {
  width: 100%;
}

.w-100-div div {
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 776px) {
  .media-resize {
    width: 170px !important;
    max-width: 170px !important;
    height: 170px !important;
    max-height: 170px !important;
  }
}

@media (max-width: 776px) {
  .media-75 {
    width: 75% !important;
  }
}

@media (max-width: 776px) {
  .media-w-50 {
    min-width: 50% !important;
    max-width: 200px !important;
  }
}

@media (max-width: 776px) {
  .media-w-100 {
    min-width: 100% !important;
    max-width: 200px !important;
  }
}

.form-perso-moncompte {
  width: 300px;
}
@media (max-width: 776px) {
  .form-perso-moncompte {
    width: 90%;
  }
}

._being_recall {
  max-width: 180px !important;
}
@media (max-width: 876px) {
  ._being_recall {
    max-width: 260px !important;
    width: 100% !important;
  }
}

.big-border {
  border: 8px solid yellow;
}

.little-size {
  font-size: 9px;
  letter-spacing: 1px;
}

.bg-yellow {
  background-color: #235346;
}

.cursor-pointer {
  cursor: pointer;
}

li {
  list-style: none;
}

.footer div ul a {
  text-decoration: none;
}

.footer div ul li {
  transition: 0.7s;
}

.footer div ul li:hover {
  background-color: white;
  color: #566CF0;
  padding: 2px;
}

.container {
  width: 100%;
}

.force-width {
  width: 100% !important;
}

@media (max-width: 676px) {
  .form-xs-column {
    flex-direction: column !important;
  }
}

#form_creneaux___name__ {
  display: flex;
  flex-direction: row;
  width: 100% !important;
}
@media (max-width: 768px) {
  #form_creneaux___name__ {
    flex-direction: column;
  }
}

@media (min-width: 776px) {
  #form_creneaux___name___heure_deb {
    margin-left: 30%;
  }
}
@media (max-width: 776px) {
  #form_creneaux___name___heure_deb {
    margin-left: 0%;
  }
}

@media (min-width: 768px) {
  .media-size-btn {
    width: 30% !important;
  }
}

.pac-item {
  color: #566CF0;
  padding: 10px;
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.big-text-complete {
  font-size: 1.2rem !important;
  text-transform: uppercase !important;
  margin-left: 0 !important;
  margin-right: auto !important;
  text-align: left !important;
}
.big-text-complete::placeholder {
  opacity: 0.5 !important;
  text-transform: uppercase;
}
@media (max-width: 776px) {
  .big-text-complete {
    font-size: 1rem !important;
  }
}

.big-text-account {
  font-size: 1.3rem !important;
  text-transform: capitalize !important;
  margin-left: 0 !important;
  margin-right: auto !important;
  text-align: left !important;
  border-color: transparent !important;
}
.big-text-account::placeholder {
  opacity: 0.5 !important;
  text-transform: uppercase;
}
.big-text-account:focus {
  border-color: transparent !important;
  box-shadow: transparent !important;
}
@media (max-width: 776px) {
  .big-text-account {
    font-size: 1.3rem !important;
    max-width: 80%;
  }
}

.big-text-other {
  font-size: 2rem !important;
  padding: 0px !important;
  text-transform: capitalize !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}
.big-text-other::placeholder {
  opacity: 0.3 !important;
  font-size: 1rem !important;
}
@media (max-width: 776px) {
  .big-text-other {
    font-size: 1.2rem !important;
  }
}

.big-text-complete::placeholder {
  opacity: 0.3 !important;
  text-transform: uppercase;
}

.addSearchIcon {
  background-image: url("../../public/icones/search.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.under-placeholder-opacity input::placeholder {
  opacity: 0.3 !important;
}

.placeholder-opacity::placeholder {
  opacity: 0.3 !important;
}

.input-styled input {
  border: 1px solid transparent !important;
  background-color: transparent !important;
}

.text-white-input input {
  color: white;
}

.label-hide label {
  display: none;
}

.input-text-center input {
  text-align: center;
}

.little-text-input input {
  font-size: 0.7rem;
}

@media (max-width: 776px) {
  .remove-label-media label {
    display: none;
  }
}

@media (max-width: 876px) {
  .media-background-reconduct {
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    background-color: #566CF0;
  }
}

@media (min-width: 876px) {
  .text-md-primary {
    color: #6c757d !important;
  }
}

.force-50-md {
  min-width: 50%;
  width: 50%;
  max-width: 50% !important;
}
@media (max-width: 876px) {
  .force-50-md {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    display: none;
  }
}

/* OLD UI AUTOCOMPLETE

.ui-autocomplete{
//	background-color: #AC5E5F6E;
	//background-color: #D0D4E0;
	border:1.5px solid $primary;
	z-index:9999;
	cursor:pointer;
	padding:0px;
	margin:0px;
//	padding-top: 5px;
	max-height: 140px;
	overflow-y: auto;
	/* prevent horizontal scrollbar
	overflow-x: hidden;
	max-width:300px;
	@media (max-width: 776px){
		max-width:200px;
		margin-left:-80px;
	}
	width:300px;
	color:white !important;
	font-weight: 400;
}
.ui-autocomplete-notBlank{
//	background-color: #AC5E5F6E;
//	background-color: #D0D4E0;
	border:0.5px solid grey;
	z-index:9999;
//	cursor:pointer;
	padding:0px;
	margin:0px;
//	padding-top: 5px;
	max-height: 140px;
	overflow-y: auto;
	/* prevent horizontal scrollbar
	overflow-x: hidden;
	max-width:300px;
	width:300px;
	color:$primary !important;
	opacity:1 !important;
	font-weight: 400;
}


	.ui-widget{
		padding:0px;
	}

	.ui-menu .ui-menu-item a{
		margin:0px;
		height:70px;
		color:$primary !important;
	}

.ui-menu li{
	//border-bottom: 0.5px solid grey;
	padding:0px;
	padding-bottom:10px;
	margin:0px;
	// transition: all 0.1s ease-in-out;
}

.ui-menu{
	padding:0px;
}

.ui-menu ul{
	padding:0px;
}

.ui-state,.ui-widget-content .ui-state,.ui-widget-header .ui-state,a.ui-button,.ui-button,.ui-state-active.ui-button{
	//transition: all 0.4s ease-in-out;
//	padding:15px;
}

.ui-widget-content li{
	//padding:15px;
	padding-top:10px;
	padding-left:10px;
	padding-right:10px;
	color:$primary !important;
}

.ui-widget-content li:hover {
	.ui-menu .ui-menu-item a, .ui-menu li,.ui-widget-content li,.ui-autocomplete li,.ui-autocomplete-notBlank li:hover{
		color:$primary !important;
		background-color:#E6E6E6 !important;
	}
	a{
	background-color:#E6E6E6 !important;
	color:$primary !important;
	}
}


.ui-widget-content div:hover, .ui-widget-content li:hover, .ui-menu-item li:hover, .ui-menu-item:hover, .ui-menu:hover ,.ui-widget:hover ,.ui-widget-content:hover ,.ui-autocomplete:hover ,.ui-front:hover {

	li:hover{
	//	background-color:white !important;
		border:transparent;
		color: $primary !important;

		.ui-menu-item-wrapper{
		//	background-color:white !important;
			border:transparent;
			color: $primary !important;
			font-weight: 700 !important;

		}
	}
	background-color:#F2F2F2 !important;
}

.ui-menu .ui-menu-item a:hover, .ui-menu li:hover,.ui-widget-content li:hover,.ui-autocomplete li:hover,.ui-autocomplete-notBlank li:hover{
	a{
		background-color:#E6E6E6 !important;
	}
}

FIN OLD UI AUTOCOMPLETE */
#chev_presta {
  /*.ui-autocomplete{
  	min-width:500px !important;
  	max-width:500px;
  	width:500px !important;
  	color:white !important;
  	font-weight: 400;
  	min-height:160px;
  	max-height:380px !important;
  	@media (max-width: 996px){
  		min-width:170px !important;
  		max-width:250px !important;
  	}
  }

  .ui-menu-item{
  	min-height:140px !important;
  	border-bottom:1px solid grey;
  	overflow:hidden;
  	&:hover{
  		background-color: #55D2BC !important;
  		color:white !important;
  		span, a, small, p{
  			color:white !important;
  			background-color: transparent !important;
  		}
  	}
  }*/
}

/*
.ui-autocomplete{
  z-index:9999;
  cursor:pointer;
  padding-left:20px;
  padding-right:20px;
  padding-bottom:20px;
  padding-top:0px !important;
  margin-top:-20px !important;
  max-height: 140px;
  overflow-y: auto;
  /* prevent horizontal scrollbar
  border-top: 1px dotted $primary;
  border-top: 1px dotted $primary;
  box-shadow: 0 3px 8px rgba(0,0,0,0.25);
  overflow-x: hidden;
  @media (max-width: 776px){
  }
  font-weight: 400;
}*/
.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  background-color: #fff;
  max-height: 200px;
  padding: 0px !important;
  width: 20px;
  overflow-y: auto; /* prevent horizontal scrollbar */
  overflow-x: hidden; /* add padding to account for vertical scrollbar */
  z-index: 999999999999999 !important;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.05), 0 10px 10px rgba(0, 0, 0, 0.02);
}

.ui-menu {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 10px !important;
  border: 0px solid transparent !important;
}

.ui-menu-item {
  color: rgba(42, 52, 101, 0.6039215686);
  padding: 15px 20px;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 13px;
  line-height: 1;
  max-height: 90px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  border-bottom: 1px solid transparent;
}
.ui-menu-item span, .ui-menu-item small {
  color: rgba(42, 52, 101, 0.6039215686);
  padding: 10px 17px;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 9px;
}
.ui-menu-item.ui-state-active {
  margin: 0;
  background-color: #ededed;
  border-color: #ededed;
  color: #333;
}
.ui-menu-item:hover {
  background-color: rgba(86, 108, 240, 0.1647058824);
  color: #566CF0;
  border-bottom: 1px solid #566CF0;
}
.ui-menu-item:hover .ui-menu-item-wrapper {
  background-color: transparent !important;
  border: transparent !important;
  color: #566CF0;
}
.ui-menu-item .ui-menu-item-wrapper:hover {
  background-color: transparent !important;
  border: transparent !important;
  color: #566CF0;
}

.ui-menu-item-wrapper {
  line-height: 0.8;
}

.loading {
  position: fixed;
  z-index: 9999;
  top: 0px;
  left: 0px;
  height: 70px;
  width: 70px;
  background-color: red;
  cursor: wait;
}

.big-input input {
  width: 70px;
  height: 70px;
}

.bckg-green {
  background-color: rgba(127, 174, 147, 0.3137254902);
}

.highlight-h2 h2, .highlight-h5 h5, .highlight-span span, .highlight-span p {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, rgba(71, 180, 172, 0.1882352941) 45%);
  letter-spacing: 1px;
}

@media (max-width: 350px) {
  .highlight-h2 h2 {
    font-size: 1.5rem !important;
  }
}

.highlight {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, rgba(255, 255, 255, 0.3) 15%);
}

.highlight-green {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, rgba(107, 169, 129, 0.2274509804) 15%);
}

.bg-none {
  background-color: transparent;
}

.parallax {
  /* The image used */
  background-image: url("../../public/icones/clouds.svg");
  /* Full height */
  height: 100%;
  overflow-x: hidden;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 776px) {
  .media-resize-200 {
    max-height: 200px !important;
  }
}

@media (max-width: 776px) {
  .position-fixed {
    position: fixed;
    top: 0 !important;
    height: 500px;
    width: 100% !important;
  }
}

.div-cheval {
  width: inherit;
  min-width: 250px;
  width: 250px;
  height: auto;
  border: 0.5px solid #5875be;
  border-radius: 10px !important;
}
@media (max-width: 776px) {
  .div-cheval {
    width: 320px;
  }
}

@media (max-width: 776px) {
  .margin-port-round {
    margin-top: -30% !important;
  }
}

@media (max-width: 776px) {
  .margin-port-acc2 {
    margin-top: -10% !important;
  }
}

.test-confirm {
  flex: 0 0 50%;
  max-width: 50%;
}
@media (max-width: 991.98px) {
  .test-confirm {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.more-col {
  flex: 0 0 100%;
  max-width: 100%;
}
@media (min-width: 576px) {
  .more-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (max-width: 991.98px) {
  .more-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .more-col {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (min-width: 992px) {
  .more-col {
    flex: 0 0 14.16666667%;
    max-width: 14.16666667%;
  }
}

@media (max-width: 776px) {
  .flex-xs-column {
    flex-direction: column !important;
  }
}

.myform-title {
  color: black;
}

.hover-effect {
  -webkit-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
  -moz-transition: background 0.3s, color 0.3s, box-shadow 0.3s;
  transition: background 0.3s, color 0.3s, box-shadow 0.3s;
}

.hover-effect:hover {
  background: rgb(255, 255, 255);
  color: #702fa8;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.3);
}

img {
  image-orientation: from-image;
}

.annonce {
  width: 40%;
  height: 290px;
  max-width: 400px;
  background-color: white;
  margin: 2px;
}
@media (max-width: 996px) {
  .annonce {
    min-height: 290px;
    height: 290px;
  }
}
@media screen and (max-width: 996px) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .annonce {
    min-height: 360px;
    height: 360px;
  }
}
.annonce h3 {
  line-height: 20px;
}
.annonce .description {
  min-height: 34px;
  max-height: 34px;
}
@media (max-width: 1076px) {
  .annonce .description {
    min-height: 44px;
    max-height: 44px;
  }
}
@media (max-width: 776px) {
  .annonce {
    min-height: 340px;
    max-height: 340px;
    /* Chrome 29+ */
  }
  .annonce h3 {
    line-height: 20px;
    font-size: 1.3rem;
  }
}
@media screen and (max-width: 776px) and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .annonce {
    min-height: 340px;
  }
  .annonce h3 {
    font-size: 1.2rem !important;
  }
}

.ecart-side {
  margin-top: 0px;
  margin-left: 9%;
  width: 100%;
}

.rounded-button {
  border-radius: 20px !important;
}

.bg-none {
  background-color: transparent;
}

.bg-green-one {
  background-color: rgba(68, 180, 171, 0.3333333333);
}

.header_today {
  background-image: url("../../public/icones/header_today.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.2901960784);
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .header_today {
    background-size: 360%;
  }
}

.header_today_thin {
  background-image: url("../../public/icones/header_today_thin.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.2901960784);
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .header_today_thin {
    background-size: 260%;
  }
}

.bg-tarifs {
  background-image: url("../../public/icones/bg-tarifs.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .bg-tarifs {
    background-size: 560% !important;
  }
}

.bg-paillettes {
  background-image: url("../../public/icones/payment_icons/paillettes.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}

.bg-note {
  background-image: url("../../public/icones/note_icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}

.resize-inscription {
  min-height: 80vh;
}
@media (max-width: 776px) {
  .resize-inscription {
    min-height: 30vh;
  }
}

.bg-inscription {
  background-image: url("../../public/icones/fond_inscription.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  margin-top: -260px;
  padding-top: 40px;
}
@media (max-width: 776px) {
  .bg-inscription {
    margin-top: -180px;
  }
}

.header_today_resized {
  background-image: url("../../public/icones/header_today.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.2901960784);
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .header_today_resized {
    background-size: 260% !important;
  }
}
@media (max-width: 2086px) {
  .header_today_resized {
    background-size: 100%;
    overflow-y: visible;
  }
}

#ui-datepicker-div {
  display: none;
}

.force-row div {
  display: flex;
  flex-direction: row;
}

.bg-bottom-wave {
  background-image: url("../../public/icones/bg_bottom_wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 90%;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .bg-bottom-wave {
    background-size: 260%;
  }
}

.media-size-pro {
  width: 100%;
}
@media (max-width: 1176px) {
  .media-size-pro {
    height: 80%;
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (max-width: 876px) {
  .media-size-pro {
    width: 100%;
    max-width: 100%;
    height: 80%;
    margin-left: auto;
  }
}

.perso-icones-media {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 990px) {
  .perso-icones-media {
    margin-left: 30px;
    margin-top: 10px !important;
  }
}

.bg-top-phone {
  background-image: url("../../public/icones/top-header.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

.bg-new-illus {
  background-image: url("../../public/icones/new_illus.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  overflow-x: hidden;
  overflow-y: visible;
  min-height: 90vh !important;
}
.bg-new-illus h1 {
  font-weight: bold;
}
@media (max-width: 700px) {
  .bg-new-illus {
    background-image: url("../../public/icones/new_illus.svg");
    background-size: 200%;
  }
}
@media (min-width: 410px) and (max-width: 600px) {
  .bg-new-illus {
    background-image: url("../../public/icones/new_illus.svg");
  }
  .bg-new-illus h2 {
    margin-top: 38% !important;
  }
}
@media (max-height: 650px) and (min-width: 406px) {
  .bg-new-illus {
    min-height: 90vh !important;
    background-size: 100%;
    background-size: contain;
    background-image: url("../../public/icones/new_illus.svg");
  }
}
@media (max-width: 350px) {
  .bg-new-illus {
    min-height: 90vh !important;
    background-size: 200%;
  }
  .bg-new-illus h1 {
    font-size: 1.7rem;
  }
}

.width-illus-pro {
  width: 50%;
}
@media (max-width: 1806px) {
  .width-illus-pro {
    width: 90%;
  }
}
@media (max-width: 906px) {
  .width-illus-pro {
    width: 50%;
  }
}
@media (max-width: 886px) {
  .width-illus-pro {
    width: 80%;
  }
}

.bg-accueil-new {
  background-image: url("../../public/icones/new_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 700px) {
  .bg-accueil-new {
    background-position: center;
    background-size: 100%;
    background-image: url("../../public/icones/illus_mobile.svg");
  }
}
@media (min-height: 1000px) and (min-width: 750px) and (max-width: 900px) {
  .bg-accueil-new {
    background-image: url("../../public/icones/illus_mobile.svg");
    margin-top: 60px !important;
  }
}
@media (max-height: 750px) and (min-width: 650px) {
  .bg-accueil-new {
    margin-top: -60px !important;
  }
}
@media (max-height: 750px) and (min-width: 767px) {
  .bg-accueil-new {
    margin-top: -30px !important;
  }
}
@media (max-height: 650px) {
  .bg-accueil-new {
    background-position: top !important;
    background-size: 120%;
    margin-top: -12%;
    background-image: url("../../public/icones/illus_mobile.svg");
  }
}
@media (max-height: 650px) and (min-width: 650px) {
  .bg-accueil-new {
    background-position: bottom !important;
    background-size: cover;
    margin-top: 0%;
    background-image: url("../../public/icones/new_bg.svg");
  }
}

.bg-accueil-new-new {
  background-image: url("../../public/icones/new_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 700px) {
  .bg-accueil-new-new {
    background-position: right;
    background-position: top;
    background-size: 100%;
    background-image: url("../../public/icones/bg-acc-mobile.svg");
  }
}
@media (min-height: 1000px) and (min-width: 750px) and (max-width: 900px) {
  .bg-accueil-new-new {
    background-image: url("../../public/icones/bg-acc-mobile.svg");
    margin-top: 0px !important;
  }
}
@media (max-height: 750px) and (min-width: 650px) {
  .bg-accueil-new-new {
    margin-top: -60px !important;
  }
}
@media (max-height: 750px) and (min-width: 767px) {
  .bg-accueil-new-new {
    margin-top: -30px !important;
  }
}
@media (max-height: 650px) {
  .bg-accueil-new-new {
    background-position: right !important;
    background-size: 120%;
    margin-top: -12%;
    background-image: url("../../public/icones/bg-acc-mobile.svg");
  }
}
@media (max-height: 650px) and (min-width: 650px) {
  .bg-accueil-new-new {
    background-position: bottom !important;
    background-size: cover;
    margin-top: 0%;
    background-image: url("../../public/icones/new_bg.svg");
  }
}

@media (max-height: 650px) {
  .perso-size-footer {
    margin-top: auto;
  }
}

@media (max-width: 330px) {
  .media-xs-height {
    overflow: hidden;
  }
  .media-xs-height img {
    min-height: 300px !important;
  }
}

.resize-plant {
  height: 50vh;
}
@media (max-width: 800px) {
  .resize-plant {
    height: 20vh;
    opacity: 0.6;
    margin-top: 15%;
  }
}

.media-lady {
  right: 0;
  height: 30vh;
  margin-top: 40%;
}
@media (max-width: 1000px) {
  .media-lady {
    height: 20vh;
  }
}
@media (max-width: 800px) {
  .media-lady {
    height: 20vh;
    bottom: 0 !important;
    margin-right: 35%;
  }
}

.bg-accueil-connected {
  background-image: url("../../public/icones/neutral_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 700px) {
  .bg-accueil-connected {
    background-position: center;
  }
}
@media (max-height: 650px) {
  .bg-accueil-connected {
    background-position: top;
  }
}

.bg-primary-wave {
  background-image: url("../../public/icones/bg_primary_wave.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 80%;
  overflow-x: hidden;
  overflow-y: visible !important;
}

.map-bg {
  background-image: url("../../public/icones/map-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-x: hidden;
  overflow-y: visible !important;
}

.fond-round {
  background-image: url("../../public/icones/fond-round.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 80%;
  overflow-x: hidden;
  overflow-y: visible !important;
}

@media (max-width: 776px) {
  .bg-infos-pro {
    background-image: url("../../public/icones/bg-pro-infos.svg");
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: top;
    height: 30%;
    overflow-x: hidden;
    overflow-y: visible;
  }
}

@media (max-width: 776px) {
  .resize-txt {
    font-size: 1.2rem;
  }
}

@media (max-width: 776px) {
  .resize-big {
    font-size: 1.6rem;
  }
}

@media (max-width: 776px) {
  .resize-txt-medium {
    font-size: 0.9rem;
  }
}

@media (max-width: 876px) {
  .resize-txt-little {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 876px) {
  .media-height {
    min-height: 80px !important;
  }
}

.moving-image {
  overflow: visible;
}

.send-bird {
  position: absolute;
  left: 0;
  top: 20%;
  margin-left: -50%;
}

.moving-image {
  margin-left: 0% !important;
  display: block !important;
}

.moving-image img {
  animation-duration: 2.5s;
  animation-name: move-image;
  animation-iteration-count: 1;
  position: absolute;
  left: 1%;
  top: 50%;
  z-index: 1;
}

@keyframes move-image {
  0% {
    left: 1%;
    bottom: 0%;
  }
  30% {
    left: 20%;
    bottom: 0%;
  }
  99% {
    bottom: 50%;
    left: 110%;
    transform: rotate(5deg);
  }
  100% {
    display: none;
  }
}
#user_password_first {
  margin-bottom: 15px;
}

@media (max-width: 776px) {
  .media-bigger {
    min-height: 30vh;
  }
}

@media (max-width: 776px) {
  .media-bigger div {
    width: 75%;
  }
}
@media (max-width: 896px) {
  .media-bigger div {
    width: 80%;
  }
}
@media (max-width: 776px) {
  .height-profile {
    height: auto !important;
    max-height: 48vh !important;
  }
}

.header_profile {
  background-image: url("../../public/icones/header_profile.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  background-color: white;
  overflow-x: hidden;
  overflow-y: visible;
}

.sidebar .activeNav {
  background-color: rgba(255, 255, 255, 0.1254901961);
  opacity: 1 !important;
}

.navSidebar a {
  opacity: 0.7;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.navSidebar a:hover {
  background-color: rgba(255, 255, 255, 0.1254901961);
  opacity: 1 !important;
}

.sidebar {
  background: #566CF0;
  flex-direction: column;
  padding-top: 5px;
  height: 100vh;
  position: fixed;
  z-index: 998;
  width: inherit;
  width: auto;
  max-width: 80px;
  min-width: 80px;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
@media (max-width: 767px) {
  .sidebar {
    flex: 0 0 100%;
    max-width: 100%;
    max-width: none;
  }
}

.sidebar-proprio {
  background: #566CF0;
  flex-direction: column;
  padding-top: 20px;
  height: 100vh;
  position: fixed;
  z-index: 998;
  border-right: 1px solid #EFEFEF;
  max-width: 170px;
  min-width: 170px;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
@media (max-width: 767px) {
  .sidebar-proprio {
    flex: 0 0 100%;
    max-width: 100%;
    max-width: none;
  }
}

.inactivePartOnglet {
  border-radius: 10px;
  color: #2A3465;
  opacity: 0.5;
  transition: 0.3s;
}

.inactivePartOnglet:hover {
  opacity: 1;
}

.activePartOnglet {
  border-right: 2px solid #566CF0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #566CF0;
  opacity: 1;
}

.form-radius {
  border-radius: 30px;
}

.height-perso {
  min-height: 80px;
  max-height: 80px !important;
}
.height-perso img {
  height: 40px;
  width: 40px;
}
@media (max-width: 1024px) {
  .height-perso {
    min-height: 50px;
    max-height: 50px !important;
  }
}
@media (max-width: 776px) {
  .height-perso {
    min-height: 40px;
    max-height: 40px !important;
  }
  .height-perso img {
    height: 20px;
    width: 20px;
  }
}

@media (max-width: 776px) {
  .w-xs-100 {
    width: 100% !important;
  }
}

.margin-top-perso {
  margin-top: 40vh;
}
@media (max-width: 776px) {
  .margin-top-perso {
    margin-top: 25vh;
  }
}

@media (max-width: 376px) {
  .pagin-responsive li:first-child {
    display: none;
  }
}

.logo_valkae {
  max-width: 100px;
  max-height: 100px;
}
@media (max-width: 776px) {
  .logo_valkae {
    max-height: 60px;
    width: auto;
    max-width: auto;
  }
}

.perso_size_200 {
  width: 200px;
}

@media (max-width: 776px) {
  .under-div-100-media > div {
    min-width: 100% !important;
  }
  .under-div-100-media > div textarea {
    min-width: 100% !important;
  }
}

.under-div-100 > div {
  min-width: 100% !important;
}

.under-div-94 > div {
  min-width: 94% !important;
}

.radio-btn label {
  padding: 3px;
  margin-right: 6px;
}

label.label_perso {
  display: inline;
}

.big-text {
  font-size: 1.5rem !important;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center !important;
}

.transparent-input {
  background-color: transparent;
  border: 0.05px solid white;
  color: white;
  font-size: 1.2rem;
}

.transparent-input:focus {
  background-color: transparent !important;
  color: white;
  font-size: 1.2rem;
}

.carre-rdv {
  height: 250px;
}

.custom-file ul {
  display: none;
}

.pres-price label {
  margin-right: 15px;
  margin-left: 2px;
}

.perso_size_card {
  width: 400px;
}
.perso_size_card .absolute-position {
  margin-left: 320px;
  margin-top: -10px;
}
@media (max-width: 1176px) {
  .perso_size_card .absolute-position {
    margin-left: 320px !important;
    margin-top: -10px !important;
  }
}
@media (max-width: 725px) and (min-width: 590px) {
  .perso_size_card {
    min-width: 330px;
  }
  .perso_size_card .absolute-position {
    margin-left: 260px !important;
    margin-top: -10px !important;
  }
}
@media (max-width: 590px) {
  .perso_size_card {
    min-width: 330px;
  }
  .perso_size_card .absolute-position {
    margin-left: 240px !important;
    margin-top: -10px !important;
  }
}
@media (max-width: 776px) {
  .perso_size_card {
    max-width: 60% !important;
    position: inherit;
  }
}
@media (max-width: 340px) {
  .perso_size_card {
    min-width: 260px !important;
  }
  .perso_size_card .absolute-position {
    margin-left: 190px !important;
    margin-top: -10px;
  }
}

.perso_size_400 {
  width: 400px;
}
.perso_size_400 .absolute-position {
  margin-left: 320px;
  margin-top: -10px;
}
@media (min-width: 1000px) {
  .perso_size_400 {
    position: fixed;
    margin-top: 30vh !important;
  }
}
@media (max-width: 1176px) {
  .perso_size_400 .absolute-position {
    margin-left: 270px !important;
    margin-top: -10px !important;
  }
}
@media (max-width: 590px) {
  .perso_size_400 {
    min-width: 330px;
  }
  .perso_size_400 .absolute-position {
    margin-left: 240px !important;
    margin-top: -10px !important;
  }
}
@media (max-width: 776px) {
  .perso_size_400 {
    max-width: 60% !important;
    position: inherit;
  }
}
@media (max-width: 340px) {
  .perso_size_400 {
    min-width: 260px !important;
  }
  .perso_size_400 .absolute-position {
    margin-left: 190px !important;
    margin-top: -10px;
  }
}

.perso_size_400_multi {
  width: 400px;
}
.perso_size_400_multi .absolute-position {
  margin-left: 320px;
  margin-top: -10px;
}
@media (min-width: 1000px) {
  .perso_size_400_multi {
    position: fixed;
    margin-top: 17vh !important;
  }
}
@media (max-width: 1176px) {
  .perso_size_400_multi .absolute-position {
    margin-left: 270px !important;
    margin-top: -10px !important;
  }
}
@media (max-width: 590px) {
  .perso_size_400_multi {
    min-width: 330px;
  }
  .perso_size_400_multi .absolute-position {
    margin-left: 240px !important;
    margin-top: -10px !important;
  }
}
@media (max-width: 776px) {
  .perso_size_400_multi {
    max-width: 60% !important;
    position: inherit;
  }
}
@media (max-width: 340px) {
  .perso_size_400_multi {
    min-width: 260px !important;
  }
  .perso_size_400_multi .absolute-position {
    margin-left: 190px !important;
    margin-top: -10px;
  }
}

.margin-perso {
  margin-top: -20px;
}
@media (max-width: 886px) {
  .margin-perso {
    margin-top: -35px;
    margin-bottom: 200px !important;
  }
}
@media (max-width: 380px) {
  .margin-perso {
    margin-top: -20px;
    margin-bottom: 200px !important;
    font-weight: 700 !important;
  }
}

.color-pink {
  background-color: #A2605D;
}

.gradient-pink {
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
}

.bg-blue-clear {
  background-color: #c9cfe5;
}

.gradient-blue {
  background-image: linear-gradient(60deg, #2193B0 0%, #6DD5ED 100%);
}

.gradient-lightblue {
  background-image: linear-gradient(45deg, rgba(116, 235, 213, 0.6274509804) 0%, rgba(172, 182, 229, 0.6274509804) 70%);
}

.gradient-lightpink {
  background-image: linear-gradient(45deg, rgba(255, 154, 158, 0.6666666667) 0%, rgba(250, 208, 196, 0.6666666667) 99%, rgba(250, 208, 196, 0.6666666667) 100%);
}

@media (max-width: 776px) {
  .mt-xs-5 {
    margin-top: 20% !important;
  }
}

.bg-rectangle {
  background-image: url("../../public/icones/bg-rectangle.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
}

.bg-accueil {
  background-image: url("../../public/icones/second_landing.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  overflow-x: hidden;
}
@media (max-width: 776px) {
  .bg-accueil {
    background-image: url("../../public/icones/landing_ills_media.svg");
  }
}

.bg-corner-pro {
  background-image: url("../../public/icones/bg-corner-pro.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 100%;
  overflow-x: hidden;
}
@media (max-width: 776px) {
  .bg-corner-pro {
    background-image: url("../../public/icones/corner-mobile.svg");
  }
}

.bg-bandeau {
  background-image: url("../../public/icones/bg_bandeau.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  overflow-x: hidden;
}

.bg-devices {
  background-image: url("../../public/icones/every_devices.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  overflow-x: hidden;
}
.bg_device_acc {
  background-image: url("../../public/icones/bg_devices.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  background-position: right;
  width: 100%;
  overflow-x: hidden;
}
.behind-top-right {
  background-image: url("../../public/icones/decor_haut.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  background-position: right;
  overflow-x: hidden;
}
@media (max-width: 776px) {
  .behind-top-right {
    background-position: top;
  }
}

.highlight-on {
  border: 1px solid transparent;
  animation: border 3.3s ease-in-out 1 forwards;
  border-radius: 50px;
  animation-iteration-count: 1;
}

@keyframes bg-pro {
  0% {
    background-color: transparent;
    border-color: transparent;
  }
  60% {
    background-color: rgba(255, 255, 255, 0.6274509804);
    border-top-color: white;
  }
  100% {
    background-color: transparent;
    border-color: transparent;
  }
}
@keyframes border {
  0% {
    box-shadow: 60px -60px 0 2px rgba(0, 108, 148, 0.0039215686), -60px -60px 0 2px rgba(0, 108, 148, 0.0039215686), -60px 60px 0 2px rgba(0, 108, 148, 0.0039215686), 60px 60px 0 2px rgba(0, 108, 148, 0.0039215686), 0 0 0 2px #47b4ac;
  }
  25% {
    box-shadow: 0 -125px 0 2px rgba(0, 108, 148, 0.0039215686), -60px -60px 0 2px rgba(0, 108, 148, 0.0039215686), -60px 60px 0 2px rgba(0, 108, 148, 0.0039215686), 60px 60px 0 2px rgba(0, 108, 148, 0.0039215686), 0 0 0 2px #fff;
  }
  50% {
    box-shadow: 0 -125px 0 2px rgba(0, 108, 148, 0.0039215686), -125px 0px 0 2px rgba(0, 108, 148, 0.0039215686), -60px 60px 0 2px rgba(0, 108, 148, 0.0039215686), 60px 60px 0 2px rgba(0, 108, 148, 0.0039215686), 0 0 0 2px #fff;
  }
  75% {
    box-shadow: 0 -125px 0 2px rgba(0, 108, 148, 0.0039215686), -125px 0px 0 2px rgba(0, 108, 148, 0.0039215686), 0px 125px 0 2px rgba(0, 108, 148, 0.0039215686), 60px 60px 0 2px rgba(0, 108, 148, 0.0039215686), 0 0 0 2px #fff;
  }
  100% {
    box-shadow: 0 -125px 0 2px transparent, -125px 0px 0 2px transparent, 0px 125px 0 2px transparent, 120px 40px 0 2px transparent, 0 0 0 2px transparent;
  }
}
.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.1882352941);
}

.bg-dark-transparent {
  background-color: rgba(0, 0, 0, 0.1882352941);
}

.badge-sidebar {
  position: sticky;
  top: 0;
}

.fade_out_pro {
  animation-iteration-count: 1;
  animation-duration: 1s;
  animation-delay: -0.5s;
  border-radius: 15px;
}

@keyframes fade_out {
  0% {
    background-color: rgba(255, 255, 255, 0.6274509804);
  }
  100% {
    background-color: transparent;
  }
}
.bg-illus_pro {
  background-image: url("../../public/icones/background_pro.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 150%;
  overflow-x: hidden;
}
@media (max-width: 776px) {
  .bg-illus_pro {
    background-size: 200% auto;
    background-position: left;
    width: 250%;
  }
}

.bg_pro_accueil {
  background-image: url("../../public/icones/bg_pro_accueil.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin: 0px;
  overflow-x: hidden;
}

.bg-avion {
  background-image: url("../../public/icones/bg-round-grey.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-color: #1C2A33;
}

.focus-bg-transparent:focus {
  background-color: transparent !important;
}

.svg-img img {
  width: 100%;
  height: auto;
}

@media (max-width: 776px) {
  .svg-img-xs-50 img {
    width: 65%;
  }
}

@media (max-width: 776px) {
  .media-higher {
    height: 150px !important;
  }
}

@media (max-width: 776px) {
  .svg-xs-height-200 {
    height: 200%;
  }
}

@media (max-width: 776px) {
  .resizable-h1 {
    font-size: 30px;
  }
}

.bg-green-perso {
  background-color: #009EAA;
}

.margin-perso-web {
  margin-top: 5%;
}
@media (max-width: 776px) {
  .margin-perso-web {
    margin-top: 15%;
    padding-bottom: 10%;
  }
}

.website_big {
  width: 100%;
}
@media (max-width: 776px) {
  .website_big {
    width: 200%;
    margin-left: -50%;
    overflow-x: hidden !important;
  }
}

@media (max-width: 776px) {
  .bg-responsive-pink {
    background-color: #DD7571;
  }
}

.gradient-primary {
  background: rgb(65, 164, 152);
  background: -moz-linear-gradient(195deg, rgba(65, 164, 152, 0.8855917367) 0%, rgba(39, 140, 200, 0.8393732493) 53%, rgba(122, 183, 233, 0.7805497199) 100%);
  background: -webkit-linear-gradient(195deg, rgba(65, 164, 152, 0.8855917367) 0%, rgba(39, 140, 200, 0.8393732493) 53%, rgba(122, 183, 233, 0.7805497199) 100%);
  background: linear-gradient(195deg, rgba(65, 164, 152, 0.8855917367) 0%, rgba(39, 140, 200, 0.8393732493) 53%, rgba(122, 183, 233, 0.7805497199) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#41a498",endColorstr="#7ab7e9",GradientType=1);
}

.bg-round {
  background-image: url("../../public/icones/wallpaper.svg");
  background-repeat: no-repeat;
}

.bg-neutre {
  background-image: url("../../public/icones/bg-neutre.svg");
  background-repeat: no-repeat;
}

.bg-steps {
  background-image: url("../../public/icones/STEPS.svg");
  background-repeat: no-repeat;
}

.bg-steps-high {
  background-image: url("../../public/icones/bg-steps-high.svg");
  background-repeat: no-repeat;
}

.bg-steps-second {
  background-image: url("../../public/icones/steps-wide.svg");
  background-repeat: no-repeat;
  background-size: 60%;
}

.bg-envoi {
  background-image: url("../../public/icones/bg_envoi.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.bg-round-grey {
  background-image: url("../../public/icones/bg-round-grey.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  margin-right: 0;
  padding-right: 0px;
  background-color: white;
}

.bg-fact {
  background-image: url("../../public/icones/bg_fact.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  margin-right: 0;
  padding-right: 0px;
  background-color: white;
}

.bg-menu {
  background-image: url("../../public/icones/bg-modify-fact.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  margin-right: 0;
  padding-right: 0px;
}

.bg-fact-wide {
  background-image: url("../../public/icones/bg-fact-wide.svg");
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  background-size: cover;
  margin-right: 0;
  padding-right: 0px;
}
@media (max-width: 776px) {
  .bg-fact-wide {
    background-image: none;
  }
}

.bloc_img_icone_small {
  width: 30px;
  max-width: 30px;
}

.bloc_img_icone_illus {
  width: 100px;
  max-width: 150px;
}

.bloc_img_icone_small img {
  width: 100%;
}

.bloc_img_icone_sidebar {
  width: 50px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: auto;
}
@media (max-width: 976px) {
  .bloc_img_icone_sidebar {
    width: 40px;
  }
}
@media (max-width: 776px) {
  .bloc_img_icone_sidebar {
    width: 30px !important;
    margin-bottom: auto !important;
  }
}
@media (max-height: 600px) {
  .bloc_img_icone_sidebar {
    width: 30px;
  }
}

#header_blocB a {
  height: 60px;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  #header_blocB a h6 {
    font-size: 10px !important;
  }
}

#header_blocB a:hover {
  background-color: rgba(255, 255, 255, 0.2274509804);
  border-radius: 0px;
  min-height: 60px;
  display: flex;
  justify-content: center;
}
#header_blocB a:hover div {
  margin-top: auto !important;
}
#header_blocB a:hover h6 {
  background-color: rgba(255, 255, 255, 0.2274509804);
  margin-bottom: auto !important;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  #header_blocB a:hover h6 {
    font-size: 10px !important;
  }
}

#header_blocB a.link-active {
  min-height: 90px !important;
  display: flex;
  justify-content: start;
  background-color: rgba(255, 255, 255, 0.2274509804);
  padding-bottom: 20px;
  border-radius: 0px !important;
}
#header_blocB a.link-active div {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
#header_blocB a.link-active h6 {
  margin-bottom: auto !important;
}

.bloc_img_icone_sidebar_bigger {
  width: 45px !important;
}
@media (max-width: 776px) {
  .bloc_img_icone_sidebar_bigger {
    width: 30px !important;
  }
}

.bloc_img_icone_sidebar_small {
  width: 40px;
  margin: auto;
}

#logoSidebar {
  min-width: 70px !important;
}
@media (max-width: 950px) {
  #logoSidebar {
    margin-left: -15px;
  }
}
@media (max-width: 767px) {
  #logoSidebar {
    margin-left: 0px;
    max-width: 20px !important;
    max-height: 40% !important;
    margin-bottom: 20px !important;
  }
}

#logoSidebar img {
  min-width: 70px !important;
  min-height: 70px !important;
}
@media (max-width: 767px) {
  #logoSidebar img {
    max-width: 20px !important;
    max-height: 40% !important;
    margin-top: -17px !important;
  }
}

@media (max-width: 787px) {
  .remove-padding-xs {
    padding-left: 0px !important;
  }
}

.bulle-media {
  width: 30px;
  height: 30px;
}
@media (max-width: 900px) {
  .bulle-media {
    max-width: 25px !important;
    min-width: 25px !important;
    max-height: 25px !important;
    min-height: 25px !important;
    font-size: 1rem;
    margin: auto;
  }
}

.hover-sidebar {
  animation: all ease-in-out;
}

.hover-sidebar::after {
  background-color: #5875BE;
}

.hover-sidebar:hover {
  background-color: #5875BE;
}

.resizable-big {
  font-size: 1.2rem;
}

#collapseSidebar {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  min-height: 100vh; /* Full height */
  margin-bottom: 50px;
  overflow: auto; /* Enable scroll if needed */
  background-color: white; /* Fallback color */
  border-bottom-left-radius: 0px !important;
}

.high-textarea {
  min-height: 130px;
}

.resize-menu {
  height: 40% !important; /* Full height */
  bottom: 0 !important;
  top: auto !important;
  margin-bottom: 0px !important;
}

.resize-menu-pro {
  height: 100vh; /* Full height */
  bottom: 0 !important;
  top: auto !important;
  margin-bottom: 0px !important;
}

@media (max-width: 380px) {
  .text-mobile th {
    text-transform: uppercase;
    font-weight: 600 !important;
    opacity: 0.6;
    font-size: 0.5rem;
  }
}

@media (max-width: 820px) {
  .small-nav-style {
    text-transform: uppercase;
    font-weight: 500 !important;
    opacity: 0.6;
    font-size: 0.5rem;
  }
}

.small-nav-style-opac {
  text-transform: uppercase;
  font-weight: 500 !important;
  opacity: 0.9;
  font-size: 0.8rem;
}
@media (max-width: 820px) {
  .small-nav-style-opac {
    text-transform: uppercase;
    font-weight: 500 !important;
    opacity: 0.9;
    font-size: 0.7rem;
  }
}

.fond-profile {
  background-image: url("../../public/icones/fond_profile.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-opac-primary {
  background-color: rgba(89, 116, 184, 0.1882352941);
  font-weight: 500 !important;
}

.radius-contre-prop {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
@media (max-width: 750px) {
  .radius-contre-prop {
    border-bottom-left-radius: 0px;
  }
}

.pro-mobile h2 {
  font-weight: 500 !important;
}
@media (max-width: 470px) {
  .pro-mobile h2 {
    padding-top: 4px;
    font-size: 0.7rem !important;
    font-weight: 500 !important;
  }
  .pro-mobile .bloc_img_icone {
    min-width: 35%;
    max-width: 35%;
  }
  .pro-mobile img {
    height: 70px !important;
    min-width: 70px !important;
    width: 70px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
  }
}

.collapse .perso-account > div > div {
  width: 45px;
  height: 45px;
}
@media (max-width: 370px) {
  .collapse .perso-account > div > div {
    max-height: 30px !important;
    width: 30px;
  }
}

.userDiv {
  opacity: 0.4;
  transition: 0.2s ease-in-out;
}

.userDiv:hover {
  opacity: 1;
}

.userDivChoosen {
  opacity: 1 !important;
}

.size-pro-menu {
  padding-top: 30px;
}
.size-pro-menu a {
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 370px) {
  .collapse .perso-account h2 {
    align-items: center;
  }
}

@media (max-width: 767px) {
  .media-sidebar {
    position: fixed;
    bottom: 0;
    height: 63px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .media-sidebar .size1 {
    font-size: 1.5rem;
  }
  .media-sidebar .size2 {
    font-size: 1.9rem;
  }
}
@media (max-width: 340px) {
  .media-sidebar {
    height: 50px;
  }
  .media-sidebar i {
    font-size: 1.1rem;
  }
}

.opacity-medium {
  opacity: 0.5;
}

.opacity-7 {
  opacity: 0.7;
}

.w-min-200 {
  width: 290px;
  min-width: 290px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.overflow-x {
  overflow-x: auto;
}

.overflow-y {
  overflow-y: auto;
}

.border-pink {
  border: 2px solid #A2605D;
}

.let-space-big {
  letter-spacing: 20px;
}

.let-space-medium {
  letter-spacing: 3px;
}

.let-space-little {
  letter-spacing: 1px;
}

.gradient-perso {
  background-image: linear-gradient(to left bottom, #181f2a, #1f3340, #234855, #295f67, #377674);
}

.min-height-100 {
  min-height: 100vh;
}

.bg-green {
  background-color: #44B4AB;
}

.bg-green-opac {
  background-color: rgba(68, 180, 171, 0.5411764706);
}

.bg-primary-opac {
  background-color: rgba(89, 116, 184, 0.4156862745);
}

@media (max-width: 776px) {
  .resizable-media-big {
    font-size: 1.2rem;
  }
}

@media (max-width: 776px) {
  .annonce {
    width: 90%;
  }
  .ecart-side {
    margin-top: 50px;
    margin-left: 0%;
    width: 100%;
  }
  .resizable-txt-small {
    font-size: 8px;
  }
  .resizable-txt {
    font-size: 0.625rem;
  }
  .resizable-txt-medium {
    font-size: 0.895rem;
  }
  .resizable-txt-big {
    font-size: 1.635rem;
  }
  .resizable-txt-little {
    font-size: 0.625rem;
  }
  .max-width-50 {
    width: inherit;
    width: 100%;
  }
}
.nav-txt {
  font-size: 11px;
  color: white;
}

.one_page {
  height: 100vh;
}

.row {
  width: 100%;
  min-height: 100vh;
  margin-right: 0px;
}

.inherit-row {
  min-height: auto;
}

@media (max-width: 867.98px) {
  .inherit-row-media {
    min-height: 70vh;
  }
}

.large-input input {
  width: 500px;
}

.btn-pro {
  letter-spacing: 10px;
}

.select-perso {
  width: 200px;
  padding: 3px;
}

.little-size {
  font-size: 9px;
}

.hideme {
  opacity: 0;
}

.footer {
  bottom: 0;
  width: 100%;
  min-height: inherit;
  height: 350px;
  max-height: 350px;
  background-color: #5974B8;
}

.footer li {
  font-size: 10px;
  color: white;
}

.bloc_img_300 {
  max-height: 300px;
}

.bloc_img {
  max-height: 100px;
  height: 100px;
}

.bloc_img img {
  width: auto;
}

.bloc_img_120 {
  height: 120px;
}

.bloc_img_icone {
  height: 60px;
}

.btn-prev, .btn-next, .del-creneau {
  cursor: pointer;
}

.under div {
  width: 100%;
}

.progress-content {
  width: 45%;
  height: 20px;
  background-color: #73966D;
}

.h-cren {
  height: 350px;
  overflow-y: auto;
}

.min-width-100 {
  min-width: 100px;
}

.border-right {
  border-right: 1px solid black;
}

.max-width-300 {
  max-width: 300px;
}

.h-perso-50 {
  height: 15px;
}

.h-perso-70 {
  max-height: 20vh;
  min-height: 100px;
}

.h-perso-30 {
  max-height: 30px;
  height: 30px;
}

.w-perso-100 {
  width: 100px;
}

.little-text {
  font-size: 8px;
}

.medium-text {
  font-size: 10px;
}

.border-top-bottom {
  border-top: 0.8px solid black;
  border-bottom: 0.8px solid black;
  padding-top: 20px;
  padding-bottom: 20px;
}

.h-perso-cren {
  min-height: 100px;
  height: 120px;
  background-color: white;
}

.del-creneau-fake {
  display: none;
}

.bg-creneaux {
  height: 200px;
  max-height: 220px;
}

.mt-md-30 {
  margin-top: 30% !important;
}
@media (max-width: 867.98px) {
  .mt-md-30 {
    margin-top: 10% !important;
  }
}

.bg-lightgrey {
  background-color: rgba(230, 230, 230, 0.6);
}

.bg-lightgrey-opac {
  background-color: rgba(230, 230, 230, 0.5215686275);
}

.padding-td-10 td {
  padding: 10px;
}

.media_previ_facture_width {
  width: 570px;
}
@media (max-width: 867.98px) {
  .media_previ_facture_width {
    display: none;
  }
}
@media (max-width: 350px) {
  .media_previ_facture_width {
    display: none;
  }
}

.media_previ_facture {
  min-height: 830px;
  max-height: 830px;
  height: 830px;
  width: 570px;
}
@media (max-width: 867.98px) {
  .media_previ_facture {
    display: none;
  }
}
@media (max-width: 350px) {
  .media_previ_facture {
    display: none;
  }
}

.text-dark-force p, .text-dark-force small, .text-dark-force span, .text-dark-force h1, .text-dark-force h2, .text-dark-force h3, .text-dark-force h4, .text-dark-force h5, .text-dark-force h6 {
  color: black;
}

.submit-rdv {
  position: relative;
  font-weight: 200;
}

@media (max-width: 900.98px) {
  .resize-svg {
    width: 90%;
    margin-left: auto;
  }
}

.next-content {
  height: 200px;
  overflow: auto;
}

.del-creneaux {
  position: relative;
  top: -66px;
  left: 220px;
  min-width: 40px;
  width: 40px;
  height: 40px;
}

.progress-bar {
  background-color: white;
  height: 20px;
}

.resume-creneau {
  height: 880px;
  width: 288px;
}

.creneaux:hover {
  background-color: rgba(76, 117, 83, 0.2196078431);
  color: white;
  cursor: pointer;
}

.btn-big {
  width: 150px;
  height: 80px;
  font-size: 20px;
  letter-spacing: 1px;
  margin-right: 0px;
}

.btn-medium {
  width: 100px;
  height: 60px;
  font-size: 17px;
  letter-spacing: 1px;
}

.input-big {
  width: 33% !important;
}
@media (max-width: 867.98px) {
  .input-big {
    min-width: 100% !important;
    height: 85px;
  }
  .input-big small {
    margin-left: 10px;
    margin-top: 5px;
  }
}

.under-500-small {
  margin-left: -190px;
  margin-top: -55px;
}
@media (max-height: 580px) {
  .under-500-small {
    margin-left: 10px;
    font-size: 0.5rem !important;
    margin-bottom: 0px !important;
    margin-top: 0px;
  }
}

@media (min-height: 1000px) and (min-width: 750px) and (max-width: 900px) {
  .ipad_acc {
    margin-top: -90px !important;
  }
}

@media (max-height: 880px) {
  .under-500 {
    font-size: 2.1rem;
  }
}
@media (max-height: 880px) and (max-height: 580px) {
  .under-500 {
    font-size: 1.5rem;
  }
}

.height-110 {
  height: 110px;
  min-height: 110px;
}
@media (max-width: 867.98px) {
  .height-110 {
    min-height: 50px !important;
    height: 50px;
    max-height: 50px !important;
  }
}

.input-big input, .input-big button {
  width: 100%;
  height: 80px;
  font-size: 20px;
  letter-spacing: 1px;
  margin-right: 0px;
}
@media (max-width: 867.98px) {
  .input-big input, .input-big button {
    min-height: 50px !important;
    height: 50px;
    max-height: 50px !important;
  }
}

.overflow-ext {
  max-height: 280px;
  overflow: auto;
}

.minsize100 {
  min-height: 50px;
  max-height: 70px;
}

.minsize200 {
  min-height: 100px;
}

.height-100 {
  height: 100vh;
}

.min-height-600 {
  min-height: 600px;
}

.min-height-400 {
  min-height: 60vh;
  height: 100%;
}

.max-height-40 {
  max-height: 40px;
}

.max-height-100 {
  max-height: 100px;
}

.max-height-70 {
  max-height: 70px;
}

.max-height-120 {
  max-height: 120px;
}

.max-height-170 {
  max-height: 170px;
}

.max-height-400 {
  max-height: 400px;
  overflow: auto;
}

.max-height-500 {
  max-height: 600px;
  overflow: auto;
  height: 100%;
}

.min-height-5 {
  min-height: 5px;
}

.min-height-2 {
  min-height: 2px;
}

.bg-blue {
  background-color: #006C94;
}

.bg-blue-dark {
  background-color: #00658a;
}

.bg-blue-opac {
  background-color: rgba(0, 108, 148, 0.062745098);
}

.bg-blue-broken {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 45%, rgba(0, 108, 148, 0.062745098) 35%);
}

.bg-blue-broken-media {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 45%, rgba(0, 108, 148, 0.062745098) 35%);
}
@media (max-width: 767.98px) {
  .bg-blue-broken-media {
    background: none;
    background-color: rgba(0, 108, 148, 0.062745098);
  }
}

.bg-blue-darken {
  background-color: #5EB9AC;
}

.row3-4 {
  min-height: inherit;
  height: 80vh;
}

.fluent-transition button {
  position: float;
  height: 38px;
  width: 97px;
  padding: 0px;
}

.photo_profile_cheval {
  min-height: 120px;
  min-width: 120px;
  height: 120px;
  width: 120px;
  max-height: 120px;
  max-width: 120px;
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 467.98px) {
  .photo_profile_cheval {
    min-height: 100px;
    min-width: 100px;
    height: 100px;
    width: 100px;
    max-height: 100px;
    max-width: 100px;
  }
}

.photo_profile_cheval_bis {
  min-height: 120px;
  min-width: 120px;
  height: 120px;
  width: 120px;
  max-height: 120px;
  max-width: 120px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 467.98px) {
  .photo_profile_cheval_bis {
    min-height: 100px;
    min-width: 100px;
    height: 100px;
    width: 100px;
    max-height: 100px;
    max-width: 100px;
  }
}

.photo_profile_cheval_minitature {
  min-height: 50px;
  min-width: 50px;
  height: 50px;
  width: 50px;
  max-height: 50px;
  max-width: 50px;
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 467.98px) {
  .photo_profile_cheval_minitature {
    min-height: 50px;
    min-width: 50px;
    height: 50px;
    width: 50px;
    max-height: 50px;
    max-width: 50px;
  }
}

.photo_profile_cheval_minitature_rounded {
  min-height: 50px;
  min-width: 50px;
  height: 50px;
  width: 50px;
  max-height: 50px;
  max-width: 50px;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 467.98px) {
  .photo_profile_cheval_minitature_rounded {
    min-height: 50px;
    min-width: 50px;
    height: 50px;
    width: 50px;
    max-height: 50px;
    max-width: 50px;
  }
}

/*Spécifiques*/
.bloc_img_large {
  height: 550px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.bloc_img_medium {
  height: 350px;
  z-index: -1;
}

.bg-grey {
  background-color: #F2F2F2;
}

.bg-light-grey {
  background-color: rgba(242, 242, 242, 0.2901960784);
}

.bg-white-opacity {
  background-color: rgba(255, 255, 255, 0.2274509804);
}

.bg-dark-opacity {
  background-color: rgba(0, 0, 0, 0.2274509804);
}

.border-rounded-pink {
  border: 3px solid #DD7571;
  border-radius: 100%;
}

.h-perso-200 {
  height: 290px;
  overflow: auto;
}
@media (max-width: 767.98px) {
  .h-perso-200 {
    min-height: 200px;
  }
}

@media (max-width: 1300px) {
  ._handle_width_app {
    max-width: 730px;
  }
}
@media (max-width: 950px) {
  ._handle_width_app {
    max-width: 530px;
  }
}
@media (max-width: 750px) {
  ._handle_width_app {
    max-width: 360px;
  }
}

.h-perso-300 {
  height: 350px;
  overflow: auto;
}
@media (max-width: 767.98px) {
  .h-perso-300 {
    min-height: 200px;
  }
}

.h-perso-400 {
  height: 400px;
  overflow: auto;
}
@media (max-width: 767.98px) {
  .h-perso-400 {
    min-height: 400px;
  }
}

@media (max-width: 767.98px) {
  .media_up_margin {
    margin-top: -16px !important;
  }
}

.color-sidebar {
  background-color: #1E74D4;
}

.color-sidebar-opac {
  background-color: rgba(30, 116, 212, 0.6274509804);
}

.h-perso-500 {
  max-height: 530px;
  overflow: auto;
}

.bg-third {
  background-color: #5875be;
}

.bg-blue {
  background-color: #dbdff5;
}

.behind {
  z-index: -10;
  height: 100vh;
  position: absolute;
  left: 20%;
  bottom: 0;
}

.behind-relative {
  z-index: -10;
  position: absolute !important;
}

.txt_acc h3 {
  font-size: 40px;
}

.img_acc {
  margin: auto;
}

.replace-label {
  top: -30px;
}

.max-height-300 {
  max-height: 300px;
}

.max-height-450 {
  max-height: 450px;
}

#add-horse {
  height: 50px;
  width: 200px;
  border-radius: 10px;
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%);
}
#add-horse p {
  color: white;
  letter-spacing: 1px;
  transition-property: letter-spacing;
  transition-duration: 1s;
}

.border-left-pink {
  border-left: 2px solid #fad0cf;
}

.border-left-blue {
  border-left: 2px solid #c9cfe5;
}

.border-left-lightblue {
  border-left: 2px solid #51BBBE;
}

#add-horse:hover {
  background-image: linear-gradient(45deg, #ff9a9f 0%, #fad0cf 99%, #fad0cf 100%);
}
#add-horse:hover p {
  letter-spacing: 2px;
}

.etape1 .chevaux_presta > div {
  width: 100%;
}

.etape1 .radio-chev label, .etape1 .radio-pres label {
  font-size: 10px;
}

.etape1 .chev_presta {
  padding: 10px;
  width: auto;
}
.etape1 .chev_presta div {
  margin-bottom: 4px;
}

@media (max-width: 867.98px) {
  .appointment-class {
    max-height: none;
    overflow-x: auto;
  }
  .appointment-class-horse label {
    min-width: 20% !important;
  }
  .appointment-class label {
    min-width: 40%;
    margin-top: auto;
    margin-bottom: auto !important;
    padding: 3px;
    word-break: keep-all !important;
    text-align: center;
  }
}
.input-not-rounded input {
  border-radius: 0px !important;
}

.myscrollbar ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  -webkit-overflow-scrolling: auto;
}
.myscrollbar ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.horse {
  width: 100%;
  height: auto;
  align-items: start;
  padding-top: 0px !important;
  margin-top: -30px !important;
}

@media (max-width: 867.98px) {
  .del-horse {
    padding-left: 5px !important;
    padding-right: 5px !important;
    font-size: 1rem;
    width: 100%;
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
}

.chev_presta > div {
  padding-top: 0px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 3px;
  max-width: 620px;
}

.horse {
  margin-top: -30px;
  padding-top: 0px;
}

.horse .radio-chevpres label {
  margin-left: 0;
}

.radio-perso > input {
  margin-right: 5px;
  margin-left: 5px;
}

.horse .radio_pres, .horse .radio_chev, .radio-perso {
  overflow-y: auto;
}

.horse .radio_chev, .horse .radio_chev {
  padding: 5px;
  border-radius: 3px;
  transition: 0.2s ease;
}

.checked_radio {
  margin: auto !important;
  padding: 0px !important;
}

.valid_radio {
  border-bottom: 1px solid white;
}

.checked_radio:hover {
  background-color: transparent !important;
  color: black !important;
}

.horse .radio_chev input, .horse .radio_pres input {
  display: none;
}

.horse .radio_chev label, .horse .radio_pres label {
  padding: 5px;
  margin-right: 4px;
  cursor: pointer;
}

.horse .radio-chevpres > label, .radio-perso > label {
  font-weight: 200;
}

.custom-file:hover, .custom-file div input:focus {
  border-color: #51BBBE;
}

.checked_radio_green {
  background-color: rgba(107, 169, 129, 0.6274509804);
  color: white;
}

.bg-green {
  background-color: rgba(107, 169, 129, 0.3529411765);
  color: white;
  border: 0px;
}

.petit-text {
  font-size: 0.6rem;
}

.child-row > div {
  padding: 10px;
  background-color: rgba(204, 204, 204, 0.0588235294);
  margin: 3px;
  margin-bottom: 6px;
  border-radius: 5px;
}

.child-row > div > div {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}
.child-row > div > div div {
  width: 100% !important;
}

.bg-acc {
  background-image: url("../../public/icones/bg-accueil.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-deco {
  background-image: url("../../public/icones/bg-deco.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-round {
  background-image: url("../../public/icones/header_today.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-clouds {
  background-image: url("../../public/icones/clouds.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-wallpaper {
  background-image: url("../../public/icones/wallpaper.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 600px;
  max-width: 750px;
  max-height: 750px;
  padding: 10px;
  margin: auto;
  padding-top: 20px;
}

.bg-wallpaper-little {
  background-image: url("../../public/icones/little-wallpaper.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100px;
}

.bg-wallpaper input[type=text]::placeholder {
  color: white;
  font-size: 12px;
  opacity: 0.8;
  font-style: italic;
}

#search_profession::placeholder, .acc #autocomplete {
  opacity: 0.9 !important;
}

.field-icon {
  float: right;
  margin-left: 55px;
  margin-top: -27px;
  opacity: 0.5;
  margin-bottom: 4px;
  position: relative;
  z-index: 2;
}

.border-gradient-danger {
  border-image: linear-gradient(195deg, rgba(135, 2, 115, 0.9794292717) 0%, rgba(200, 39, 62, 0.8393732493) 53%, rgba(0, 140, 255, 0.7805497199) 100%);
  border-image: -webkit-linear-gradient(195deg, rgba(135, 2, 115, 0.9794292717) 0%, rgba(200, 39, 62, 0.8393732493) 53%, rgba(0, 140, 255, 0.7805497199) 100%);
  border-image-slice: 1;
  border-width: 2px;
}

.border-left-perso {
  border-left: 1px solid #566CF0 !important;
}

/*OLD FLOATING LABEL */
.opacity-9 {
  opacity: 0.9 !important;
}

.showTool span {
  display: none;
  transition: all 0.65s ease-in-out;
}
.showTool:hover span {
  display: block;
}

.custom_radio {
  padding: 2px;
}

.custom_radio input {
  display: none;
}

.custom_radio label {
  border: 2px solid #566CF0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 4px;
  padding-right: 4px;
  width: 200px;
  border-radius: 6px;
  font-weight: 400;
  color: #6c757d;
  font-size: 0.9rem;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.custom_radio label:hover {
  color: white;
  background-color: #566CF0;
  opacity: 0px !important;
}

.custom_radio_nosize {
  width: auto !important;
  display: block;
}

.custom_radio_checked {
  background-color: #566CF0;
  color: white !important !important;
}
.custom_radio_checked .bi {
  color: white !important !important;
}

.new_custom_radio input {
  display: none;
}

.new_custom_radio label {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 4px;
  padding-right: 4px;
  min-width: 200px;
  border-radius: 0px;
  font-weight: 400;
  color: #6c757d;
  font-size: 0.9rem;
  text-align: center;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  /*&:hover{
  	color:white;
  	background-color: $primary;
  	opacity:0px !important;
  }*/
}
@media (max-width: 991.98px) {
  .new_custom_radio label {
    min-width: 130px;
    max-width: 130px;
  }
}

.text-white-force label, .text-white-force p, .text-white-force small, .text-white-force .select-selected {
  color: white !important;
}

.text-styled {
  height: 22px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 200;
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.text-styled-nosize, p, h1, h2, h3, h4, h5, h6 {
  font-weight: 200;
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.toggle-border {
  border-color: rgba(0, 0, 0, 0) !important;
}

.placeholder_opac input::placeholder, .placeholder_opac textarea::placeholder {
  opacity: 0.4 !important;
}

.placeholder_white::placeholder {
  color: white !important;
  opacity: 0.7 !important;
}

.hover-dark {
  border-color: rgba(0, 0, 0, 0) !important;
}
.hover-dark::placeholder {
  color: grey;
  font-weight: 400;
}

.hover-dark:focus {
  background-color: white;
  color: white;
}
.hover-dark:focus:focus {
  color: white;
  border-color: rgba(0, 0, 0, 0) !important;
}
.hover-dark:focus::placeholder {
  color: white;
  font-style: italic;
  opacity: 0.7;
}

.cadrant {
  cursor: pointer;
}

.cadrant:hover {
  background-color: #55D2BC;
  border-color: #55D2BC !important;
}
.cadrant:hover small {
  color: white !important;
}

.cadrant_active {
  background-color: #55D2BC !important;
  border-color: #55D2BC !important;
}
.cadrant_active small {
  color: white !important;
}

.w-120 {
  width: 120px;
}

.floating-style {
  height: 22px;
  line-height: 122px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 200;
  background: transparent;
  color: rgba(0, 0, 0, 0.6);
}

.floating-label input:hover, .floating-label input:focus {
  border-color: #51BBBE;
}

.floating-label div input:focus {
  background-color: transparent;
  border: transparent;
}

/*
.floating-label div input:valid + div label, .floating-label  div input:focus + div  label {
  height: 20px;
  line-height: 20px;
  font-size: 11px;
  color: #51BBBE;
}*/
.floating-label div input:focus ::-webkit-input-placeholder {
  color: transparent;
  font-size: 0;
}

.floating-label div input:focus :-moz-placeholder {
  color: transparent;
  font-size: 0;
}

.floating-label div input:focus ::-moz-placeholder {
  color: transparent;
  font-size: 0;
}

.floating-label div input:focus :-ms-input-placeholder {
  color: transparent;
  font-size: 0;
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #51BBBE;
  border-color: #51BBBE transparent #51BBBE transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fixed-right {
  right: 0px;
}

.opacity-5 {
  opacity: 0.6;
}

@media (max-width: 767.98px) {
  .hidden-small {
    display: none;
    border: 3px solid red;
  }
}
.ui-datepicker, .ui-widget {
  min-width: 250px;
  background-color: white;
  padding: 10px;
  color: #2A3465;
  display: flex;
  justify-content: around;
  flex-direction: column;
}
.ui-datepicker a, .ui-widget a {
  color: #2A3465;
}

.ui-datepicker-calendar {
  min-width: 100%;
}

.ui-datepicker-header {
  display: flex;
  justify-content: around;
  flex-direction: row;
  color: black;
}

.ui-datepicker-next {
  cursor: pointer;
  color: #2A3465;
  margin-left: 10%;
}

.ui-datepicker-prev {
  cursor: pointer;
  color: #2A3465;
}

.ui-datepicker-title {
  margin-left: auto;
  margin-right: auto;
  min-width: 70%;
  text-align: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: 1px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #88ae6f;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.remove-display {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.flash-impossible {
  background-color: #fefefe;
  margin: 20% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 60%; /* Could be more or less, depending on screen size */
}

.flash-success_rdv {
  background-color: #fefefe;
  margin: auto; /* 15% from the top and centered */
  z-index: 999;
  position: fixed;
  border: 1px solid #888;
  width: 100%; /* Could be more or less, depending on screen size */
  height: 100%;
}

.width-100 {
  width: 100%;
  max-width: inherit;
}

.nav-pills-no-border .nav-link.active,
.nav-pills-no-border .show > .nav-link {
  border-bottom: thin outset transparent !important;
}

.nav-pills {
  border-radius: 0px;
  flex-direction: row !important;
}
.nav-pills .nav-link {
  color: grey;
  border-radius: 0px;
  border-bottom: thin outset transparent;
}
.nav-pills .nav-link-perso {
  width: 100%;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  transition: 0.25 ease;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: rgba(86, 108, 240, 0.3333333333);
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent !important;
  border-bottom: thin outset rgba(86, 108, 240, 0.3333333333);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link-perso {
  background-color: transparent;
  color: #566CF0;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent !important;
  border-bottom: thin outset rgba(86, 108, 240, 0.3333333333);
}

.nav-pills-perso {
  display: flex;
  justify-content: center;
}

.nav-pills-perso2 {
  display: flex;
  justify-content: center;
}
.nav-pills-perso2 .nav-link-perso {
  width: 100%;
  opacity: 0.7;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  transition: 0.25 ease;
  font-weight: 600 !important;
  color: rgba(42, 52, 101, 0.6039215686) !important;
}
.nav-pills-perso2 .nav-link.active,
.nav-pills-perso2 .show > .nav-link-perso {
  background-color: transparent;
  color: #566CF0 !important;
  opacity: 1;
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-top: 0px solid transparent !important;
  border-bottom: thin outset rgba(86, 108, 240, 0.3333333333);
}

.myNavPill .nav-link-perso {
  opacity: 0.5;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  transition: 0.25 ease;
  font-weight: 500 !important;
  margin-right: 20px;
}
.myNavPill .nav-link-perso p {
  color: #2A3465;
}
.myNavPill .nav-link-perso.active,
.myNavPill .show > .nav-link-perso {
  background-color: transparent;
  opacity: 1;
}
.myNavPill .nav-link-perso.active p,
.myNavPill .show > .nav-link-perso p {
  color: #566CF0 !important;
}

.big-select {
  height: 100%;
}

@media (max-width: 991.98px) {
  .m-sm-auto {
    display: flex !important;
    justify-content: center !important;
  }
}

@media (max-width: 991.98px) {
  .w-sm-75 {
    width: 75% !important;
  }
}

@media (min-width: 991.98px) {
  .w-md-100 {
    width: 100% !important;
  }
}

@media (min-width: 991.98px) {
  .w-md-perso {
    width: 15% !important;
  }
}

@media (min-width: 991.98px) {
  .w-md-75 {
    width: 75% !important;
  }
}

@media (min-width: 991.98px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media (min-width: 991.98px) {
  .w-md-25 {
    width: 25% !important;
  }
}

@media (min-width: 991.98px) {
  .w-md-auto {
    width: auto !important;
  }
}

@media (max-width: 991.98px) {
  .border-port {
    border: 0.5px solid #666666;
  }
}

.w-70 {
  width: 20px !important;
}

.border-bottom-light {
  border-bottom: 0.5px solid white;
}

.bg-opacity-warning {
  background-color: #F2F2F2;
}

.not-responsive nav ul li span, .not-responsive nav ul li a {
  font-size: 0.9rem;
}
@media (max-width: 306px) {
  .not-responsive nav ul li span, .not-responsive nav ul li a {
    font-size: 0.3rem;
  }
}

@media (max-width: 776px) {
  .w-sm-100 {
    width: 100%;
  }
}

@media (min-width: 776px) {
  .w-md-50 {
    width: 50% !important;
  }
}

.custom-pagin .page-link {
  border: none;
  color: grey;
  font-weight: 100;
  background-color: transparent !important;
}
.custom-pagin .page-link:hover {
  background-color: transparent;
  border-bottom: 1px solid #566CF0;
  color: #566CF0;
}

.custom-pagin .page-item {
  background-color: transparent !important;
  border: none;
}
.custom-pagin .page-item.active .page-link {
  background-color: transparent !important;
  border-bottom: 1px solid #566CF0;
  color: #566CF0;
  font-weight: 600 !important;
}
.custom-pagin .page-item.disabled {
  background-color: transparent !important;
  color: #566CF0;
}
@media (max-width: 776px) {
  .custom-pagin .page-item .page-link {
    font-size: 0.8rem !important;
  }
}

.custom-pagin-2 .page-link {
  border: none;
  color: rgba(42, 52, 101, 0.6039215686);
  font-weight: 100;
  font-size: 0.65rem !important;
  background-color: transparent !important;
}
.custom-pagin-2 .page-link:hover {
  background-color: transparent;
  border-bottom: 1px solid #566CF0;
  color: #566CF0;
}

.custom-pagin-2 .page-item {
  background-color: transparent !important;
  border: none;
  font-size: 0.65rem !important;
}
.custom-pagin-2 .page-item.active .page-link {
  background-color: transparent;
  border-bottom: 1px solid #566CF0;
  color: #566CF0;
  font-weight: 600 !important;
  opacity: 1 !important;
  border-radius: 0px !important;
}
.custom-pagin-2 .page-item.disabled {
  background-color: transparent !important !important;
  color: #566CF0;
  opacity: 0.4;
}
@media (max-width: 776px) {
  .custom-pagin-2 .page-item .page-link {
    font-size: 0.75rem !important;
  }
}

.custom-pagin-3 .page-link {
  border: none;
  color: rgba(42, 52, 101, 0.6039215686);
  font-weight: 100;
  font-size: 0.75rem !important;
  background-color: transparent !important;
}
.custom-pagin-3 .page-link:hover {
  background-color: transparent;
  border-bottom: 1px solid #566CF0;
  color: #566CF0;
}

.custom-pagin-3 .page-item {
  background-color: transparent !important;
  border: none;
  font-size: 0.75rem !important;
}
.custom-pagin-3 .page-item.active .page-link {
  background-color: transparent;
  border-bottom: 1px solid #566CF0;
  color: #566CF0;
  font-weight: 600 !important;
}
.custom-pagin-3 .page-item.disabled {
  background-color: transparent !important !important;
  color: #566CF0;
}
@media (max-width: 776px) {
  .custom-pagin-3 .page-item .page-link {
    font-size: 0.75rem !important;
  }
}

.custom-pagin-3 .disabled {
  background-color: red !important !important;
}

@media (max-width: 326px) {
  ._chart_part {
    width: 300px;
    max-width: 300px;
    height: 300px;
  }
}
@media (min-width: 327px) and (max-width: 526px) {
  ._chart_part {
    width: 370px;
    max-width: 370px;
    height: 500px;
  }
}
@media (min-width: 527px) and (max-width: 726px) {
  ._chart_part {
    width: 600px;
    max-width: 600px;
    height: 700px;
  }
}
@media (min-width: 727px) and (max-width: 1166px) {
  ._chart_part {
    width: 290px;
    max-width: 290px;
    height: 400px;
  }
}
@media (min-width: 1167px) {
  ._chart_part {
    width: auto;
    max-width: 220px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: auto;
  }
}

@media (max-width: 326px) {
  ._chart_part2 {
    width: 300px;
    max-width: 300px;
    height: 300px;
  }
}
@media (min-width: 327px) and (max-width: 526px) {
  ._chart_part2 {
    width: 370px;
    max-width: 370px;
    height: 500px;
  }
}
@media (min-width: 527px) and (max-width: 726px) {
  ._chart_part2 {
    width: 600px;
    max-width: 600px;
    height: 700px;
  }
}
@media (min-width: 727px) and (max-width: 1166px) {
  ._chart_part2 {
    width: 290px;
    max-width: 290px;
    height: 400px;
  }
}
@media (min-width: 1167px) {
  ._chart_part2 {
    width: auto;
    max-width: 220px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: auto;
  }
}

@media (max-width: 326px) {
  ._chart_payments {
    width: 300px;
    max-width: 300px;
    height: 300px;
  }
}
@media (min-width: 327px) and (max-width: 526px) {
  ._chart_payments {
    width: 370px;
    max-width: 370px;
    height: 500px;
  }
}
@media (min-width: 527px) and (max-width: 726px) {
  ._chart_payments {
    width: 600px;
    max-width: 600px;
    height: 700px;
  }
}
@media (min-width: 727px) and (max-width: 1166px) {
  ._chart_payments {
    width: 300px;
    max-width: 300px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1167px) {
  ._chart_payments {
    width: 320px;
    max-width: 320px;
    height: 360px;
  }
}

@media (min-width: 993px) and (max-width: 1098px) {
  ._media_lg_perso {
    min-width: 290px;
    max-width: 290px;
    margin-left: 100px !important;
  }
}

.page-item .disabled {
  background-color: transparent !important;
}

/*floating-label*/
.floating-label-field {
  border-width: 0;
  color: rgba(108, 117, 125, 0.1);
}
.floating-label-field:focus {
  outline: 0;
  box-shadow: 0;
  color: grey;
}
.floating-label-field::placeholder {
  color: grey;
}

.floating-label-field-bluedark {
  border-width: 0;
  color: #2A3465;
}
.floating-label-field-bluedark:focus {
  outline: 0;
  box-shadow: 0;
  color: #2A3465;
}
.floating-label-field-bluedark::placeholder {
  color: rgba(42, 52, 101, 0.6352941176);
}

.floating-label-field, .floating-label-field + .floating-label {
  line-height: 0.3;
  font: inherit;
  padding: 1em;
  height: 2.2em;
  color: white;
}

.floating-label-field + .floating-label {
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
  color: white;
}

.floating-label-field, .floating-label-field + .floating-label {
  position: relative;
  color: white;
}

.floating-label-field {
  border-bottom: 2px solid rgba(42, 52, 101, 0.55);
  background-color: transparent;
  color: grey;
}
.floating-label-field::placeholder {
  color: grey;
  font-style: italic;
}

.floating-label-field-less-border {
  border-bottom: 1.1px solid rgba(42, 52, 101, 0.55);
  background-color: transparent;
  color: #2A3465;
}
.floating-label-field-less-border::placeholder {
  color: rgba(42, 52, 101, 0.1607843137) !important;
  font-style: italic;
}

.floating-label-field-white {
  border-width: 0;
  border-bottom: 2px solid rgba(108, 117, 125, 0.25);
  background-color: transparent;
  color: white;
}
.floating-label-field-white::placeholder {
  color: white;
  font-style: italic;
}
.floating-label-field-white:focus {
  outline: 0;
  box-shadow: 0;
  color: white;
}
.floating-label-field-white:focus, .floating-label-field-white:not(:placeholder-shown) {
  border-color: rgba(86, 108, 240, 0.9);
}
.floating-label-field-white:focus + .floating-label, .floating-label-field-white:not(:placeholder-shown) + .floating-label {
  transform: translate(1%, -30%) scale(0.8);
  opacity: 0.25;
  color: red;
}
.floating-label-field-white:hover {
  border-color: rgba(108, 117, 125, 0.9);
}

.floating-label-field-fill {
  border-width: 0;
  border-bottom: 2px solid rgba(86, 108, 240, 0.95);
  background-color: transparent;
  color: black;
}
.floating-label-field-fill::placeholder {
  color: black;
  font-style: italic;
}
.floating-label-field-fill:focus {
  outline: 0;
  box-shadow: 0;
  color: black;
}
.floating-label-field-fill:focus, .floating-label-field-fill:not(:placeholder-shown) {
  border-color: rgba(86, 108, 240, 0.9);
}
.floating-label-field-fill:focus + .floating-label, .floating-label-field-fill:not(:placeholder-shown) + .floating-label {
  transform: translate(1%, -30%) scale(0.8);
  opacity: 0.25;
  color: red;
}
.floating-label-field-fill:hover {
  border-color: rgba(86, 108, 240, 0.9);
}

.floating-label-field + .floating-label {
  transition: transform 0.25s, opacity 0.25s ease-in-out;
  transform-origin: 0 0;
  opacity: 0.5;
  color: rgba(128, 128, 128, 0.25);
}

.floating-label-field:focus, .floating-label-field:not(:placeholder-shown) {
  border-color: rgba(88, 117, 190, 0.9);
}
.floating-label-field:focus + .floating-label, .floating-label-field:not(:placeholder-shown) + .floating-label {
  transform: translate(1%, -30%) scale(0.8);
  opacity: 0.25;
  color: red;
}
.floating-label-field:hover {
  border-color: rgba(86, 108, 240, 0.9);
}

.invalid {
  border-bottom: 2px solid rgba(255, 0, 0, 0.25) !important;
}

.valid {
  border-bottom: 2px solid rgba(0, 128, 0, 0.25) !important;
}

.floating-label-input:valid:not(:placeholder-shown) {
  border-bottom: 2px solid rgba(0, 128, 0, 0.25) !important;
}
.floating-label-input:invalid {
  border-bottom: 2px solid rgba(255, 0, 0, 0.25) !important;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #566CF0; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.little-loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #566CF0; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.biggest_case input {
  -webkit-appearance: none;
  min-width: 30px !important;
  max-width: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
  background: white !important;
  border-radius: 5px !important;
  border: 2px solid #555 !important;
}

@media (max-width: 330px) {
  .badge {
    font-size: 0.6rem;
    word-break: break-all !important;
  }
}

@media (max-width: 776px) {
  .show_from_769 {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
  }
}

.select-filtres {
  min-width: 160px !important;
  width: 160px;
}
@media (max-width: 996px) {
  .select-filtres {
    width: 245px;
  }
}

.hide_from_769 .form-group > div {
  max-width: 155px !important;
  width: 155px !important;
  padding-right: 5px;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (max-width: 996px) {
  .hide_from_769 .form-group > div {
    max-width: 240px !important;
    min-width: 240px !important;
    min-height: 43px;
    font-size: 14px;
    padding-top: 12px;
    margin-left: 0 !important;
  }
}
.hide_from_769 input::placeholder {
  font-size: 13px;
  padding-top: 4px;
  opacity: 0.5;
}
.hide_from_769 input {
  max-width: 90px;
  min-width: 150px !important;
  min-height: 43px;
  font-size: 14px;
  padding-top: 12px;
  border: 0px solid transparent;
  border-bottom: 0.8px solid #CCCCCC;
  border-radius: 0px;
}
@media (max-width: 996px) {
  .hide_from_769 input {
    max-width: 240px !important;
    min-width: 240px !important;
    min-height: 43px;
    font-size: 14px;
    padding-top: 12px;
  }
}
.hide_from_769 label {
  position: absolute;
  margin-bottom: -14px;
  padding-left: 6px;
  opacity: 0.6 !important;
}
@media (max-width: 776px) {
  .hide_from_769 {
    width: 100%;
  }
  .hide_from_769 .form-group {
    width: auto !important;
  }
}

.perso-dropdown {
  position: relative;
  display: inline-block;
}

.perso-dropdown-content {
  display: none;
  position: absolute;
  background-color: #fAfAfA;
  min-width: 160px;
  padding: 12px 16px;
  margin-left: 10px;
  z-index: 1;
}

.perso-dropdown:hover .perso-dropdown-content {
  display: block;
}

@media (max-width: 1050px) {
  .resize-media-sidebar {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -3px;
    font-size: 0.5rem;
  }
}
@media (max-width: 889px) {
  .resize-media-sidebar {
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -9px;
    font-size: 0.5rem;
  }
}

.media-plant {
  height: 50vh;
}
@media (max-width: 889px) {
  .media-plant {
    height: 35vh;
  }
}

.perso-input input {
  display: none;
}

.perso-input label {
  padding: 8px;
  margin: 5px;
  border: 1px solid transparent;
  border-radius: 13px;
  opacity: 0.7;
}

.perso-input label:hover {
  cursor: pointer;
  border: 1px solid #566CF0;
  opacity: 1;
}

.perso-input input:checked + label {
  border: 1px solid #566CF0;
  color: #566CF0;
  opacity: 1;
}

.input-100 > div {
  min-width: 100%;
}

.custom-select {
  position: relative;
  min-width: 100px;
  border: 0px solid transparent !important;
  padding: 0px !important;
  background-color: transparent !important;
}

.custom-select select {
  display: none !important; /*hide original SELECT element: */
}

.select-selected {
  width: 100%;
}

.custom-select-transparent .select-selected {
  background-color: transparent;
  width: 100%;
  border: 0px solid transparent !important;
  color: grey;
  font-weight: 400;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div, .select-selected {
  color: #6c757d;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  max-height: 300px;
  overflow: scroll;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

@media (max-width: 375px) {
  .collapse h5 {
    font-size: 0.6rem !important;
  }
}

.select-items div:hover, .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.label-opac label {
  opacity: 0.7 !important;
}

.uppercase {
  text-transform: uppercase;
}

.showMoreEcurie {
  margin-left: 60%;
  margin-right: 20%;
  width: 200px;
  min-width: 200px;
}
@media (max-width: 1050px) {
  .showMoreEcurie {
    margin-left: 40% !important;
    max-width: 250px;
    min-width: 200px;
  }
}
@media (max-width: 750px) {
  .showMoreEcurie {
    margin-left: 30% !important;
    max-width: 250px !important;
    min-width: 200px;
  }
}

.margin-according-height {
  margin-top: 0px !important;
}
@media (max-height: 900px) {
  .margin-according-height {
    margin-top: 80px !important;
  }
}
@media (max-width: 1024px) {
  .margin-according-height {
    margin-top: 80px !important;
  }
}
@media (min-width: 1025px) {
  .margin-according-height {
    margin-top: 30px !important;
  }
}

@media (max-width: 777px) {
  .media_border_bottom {
    border-bottom: 1px solid #2A3465;
  }
}

* {
  box-sizing: border-box;
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.slideshow-container .prev, .slideshow-container .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 1s ease;
  border-radius: 50%;
  user-select: none;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5411764706);
}

/* Position the "next button" to the right */
.slideshow-container .next {
  right: 0;
  border-radius: 10%;
  padding: 4px;
}

.slideshow-container .prev {
  left: 0;
  border-radius: 10%;
  padding: 4px !important;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: #E6E6E6;
  color: white;
}

/* Caption text */
.slideshow-container .text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.slideshow-container .active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.slideshow-container .fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.MyCardElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.MyCardElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.MyCardElement--invalid {
  border-color: #fa755a;
}

.MyCardElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.media-100-insc {
  max-width: 200px !important;
}
@media (max-width: 786px) {
  .media-100-insc {
    min-width: 100% !important;
    width: 100%;
  }
  .media-100-insc h2 {
    font-size: 0.8rem;
  }
  .media-100-insc h6, .media-100-insc small, .media-100-insc span {
    font-size: 0.5rem !important;
  }
}

@media (max-width: 786px) {
  ._media_width_100 {
    min-width: 100%;
  }
}

.profil-cheval {
  border-color: transparent !important;
  overflow: hidden;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .profil-cheval small {
    font-size: 0.85rem !important;
  }
}
@media (max-width: 767px) {
  .profil-cheval small {
    font-size: 0.8rem !important;
  }
}

.ui-datepicker-calendar .ui-state-active {
  border: 1px solid #55D2BC;
  padding: 5px;
  border-radius: 50%;
  min-width: 18px !important;
  min-height: 18px;
  color: white;
  background-color: #55D2BC;
  font-weight: 600 !important;
}

.hover-animation:hover {
  color: #566CF0;
  border-bottom: 1px solid #566CF0;
}

.infos-creneau {
  position: fixed;
  top: 0;
  margin-left: auto;
  margin-right: 0;
  right: 0;
  min-width: 400px;
  max-width: 400px;
  min-height: 100vh;
  background-color: white;
  z-index: 999;
}
.infos-creneau .append-here {
  max-height: 80vh;
}
@media (max-width: 776px) {
  .infos-creneau {
    padding-top: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 0px;
    top: 0 !important;
    top: inherit;
  }
  .infos-creneau .close {
    position: fixed;
    bottom: 0;
    margin-bottom: 40px;
  }
  .infos-creneau .append-here > div {
    max-height: 80% !important;
    height: 80% !important;
    overflow: auto;
    justify-content: flex-start;
    margin-bottom: 50px;
  }
}
@media (max-width: 400px) {
  .infos-creneau {
    margin-bottom: -10px;
  }
}

.media-height-chevaux {
  max-height: 50vh;
}
@media (max-width: 800px) {
  .media-height-chevaux {
    max-height: 60vh;
  }
}

.success-modal {
  width: 40%;
  min-width: 30%;
  background-color: #47b1ad;
  border-radius: 20px;
  margin-top: 15px;
  right: 0;
  margin-right: 20px;
  color: white;
}
@media (max-width: 900px) {
  .success-modal {
    width: 90%;
    min-width: 90%;
    center: 0;
  }
}

.success-modal-no-disapear {
  width: 40%;
  min-width: 30%;
  background-color: #47b1ad;
  border-radius: 20px;
  margin-top: 15px;
  right: 0;
  margin-right: 20px;
  color: white;
}
@media (max-width: 900px) {
  .success-modal-no-disapear {
    width: 90%;
    min-width: 90%;
    center: 0;
  }
}

.warning-modal {
  width: 60%;
  min-width: 30%;
  background-color: rgba(247, 147, 30, 0.0980392157);
  border-radius: 20px;
  margin-top: 15px;
  right: 0;
  margin-right: 20px;
}
.warning-modal p, .warning-modal h4 {
  color: #F7931E;
}
@media (max-width: 900px) {
  .warning-modal {
    width: 90%;
    min-width: 90%;
    center: 0;
  }
}

.add_app .active {
  color: #55D2BC;
  border: 1px solid #55D2BC;
}

.add_app_che_pres #chevauxContainer {
  min-height: 30vh;
  max-height: 30vh;
  overflow: hidden !important;
}
@media (max-width: 900px) {
  .add_app_che_pres #chevauxContainer {
    min-height: 40vh;
    max-height: 40vh;
    overflow: hidden !important;
  }
  .add_app_che_pres #chevauxContainer label {
    font-size: 0.85rem !important;
  }
}

.add_app_che_pres .radio-chevpres {
  max-height: none !important;
}

.width-presta {
  width: 40%;
  height: 70vh;
  overflow: auto;
  margin-left: -150px;
}
@media (max-width: 900px) {
  .width-presta {
    width: 90%;
    height: 85vh;
    overflow: auto;
    margin-left: auto !important;
  }
}

.text-color-payed td p, .text-color-payed td span {
  color: #45B2A9 !important;
}

.media-max-height {
  max-height: 350px;
}
@media (max-width: 900px) {
  .media-max-height {
    max-height: none !important;
  }
}

.hover-style {
  border-bottom: 1px solid transparent;
  transition: 0.2s ease-in-out;
}

.hover-style:hover {
  border-bottom: 1px solid #566CF0;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  background: #d3d3d3; /* Grey background */
  height: 15px;
  border-radius: 5px;
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  z-index: 99999 !important;
}

.slider::before {
  background-color: transparent !important;
  display: none;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none !important; /* Override default look */
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  border-radius: 50%;
  background: #566CF0; /* Green background */
  cursor: pointer; /* Cursor on hover */
  z-index: 99999999999 !important;
}

.slider::-moz-range-thumb {
  appearance: none;
  width: 25px; /* Set a specific slider handle width */
  height: 25px;
  border-radius: 50%;
  background: #566CF0; /* Green background */
  cursor: pointer; /* Cursor on hover */
  z-index: 99999999999 !important;
}

.working-input input {
  display: none;
}

.working-input label {
  padding: 8px;
  margin: 5px;
  border: 1px solid transparent;
  border-radius: 13px;
  color: #A1A1A1;
}

.working-input label:hover {
  cursor: pointer;
  border: 1px solid #55D2BC;
  background-color: #55D2BC;
  color: white;
  font-weight: 600;
}

.working-input input:checked + label {
  border: 1px solid #55D2BC;
  background-color: #55D2BC;
  color: white;
  font-weight: 600;
}

.check-input input {
  display: none;
}

.check-input label {
  padding: 8px;
  margin: 5px;
  border: 1px solid transparent;
  border-radius: 10px;
  color: #28005F;
  font-weight: 500;
}

.check-input label:hover {
  cursor: pointer;
  border: 1px solid #566CF0;
  background-color: rgba(86, 108, 240, 0.0666666667);
  color: #566CF0;
  font-weight: 500;
}

.check-input input:checked + label {
  cursor: pointer;
  border: 1px solid #566CF0;
  background-color: rgba(86, 108, 240, 0.0666666667);
  color: #566CF0;
  font-weight: 500;
}

.bg-real-green {
  background-color: #55D2BC;
}

.border-real-green {
  border: 2px solid #55D2BC;
}

.border-bottom-green {
  border-bottom: 2px solid #55D2BC !important;
}

@media (min-width: 776px) {
  .border-md-real-green {
    border: 2px solid #55D2BC !important;
    border-radius: 0px;
  }
}

@media (min-width: 1076px) {
  .border-lg-left {
    border-left: 1px solid rgba(1, 1, 1, 0.1019607843) !important;
  }
}

.border-bottom-real-green {
  border-bottom: 2px solid #55D2BC !important;
  border-radius: 0px;
}

.border-bottom-primary {
  border-bottom: 2px solid #566CF0 !important;
  border-radius: 0px;
}

.underSelect100 select {
  min-width: 100% !important;
}

.force-padding {
  height: 40px !important;
}

.border-left-green {
  border-left: 2px solid #55D2BC;
}

.hover-fact:hover {
  background-color: rgba(71, 97, 176, 0.0705882353) !important;
}

.bg-lightblue {
  background-color: rgba(71, 97, 176, 0.0705882353);
}

.text-real-green {
  color: #55D2BC;
}

.text-real-green-important {
  color: #55D2BC !important;
}

.pastille {
  box-shadow: 0 1px 6px rgba(85, 210, 188, 0.6352941176), 0 1px 6px rgba(85, 210, 188, 0.6352941176);
}

.pastille-warning {
  box-shadow: 0 1px 6px rgba(247, 147, 30, 0.6352941176), 0 1px 6px rgba(247, 147, 30, 0.6352941176);
}

.pastille-danger {
  box-shadow: 0 1px 6px rgba(255, 129, 149, 0.6352941176), 0 1px 6px rgba(255, 129, 149, 0.6352941176);
}

.pastille-grey {
  box-shadow: 0 1px 6px rgba(1, 1, 1, 0.1019607843), 0 1px 6px rgba(1, 1, 1, 0.1019607843);
}

.pastille-primary {
  box-shadow: 0 1px 6px #566CF0, 0 1px 6px #566CF0;
}

.loading_demarrage_1 {
  min-width: 45%;
  width: 45%;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-height: 5px;
}

.loading_demarrage_2 {
  min-width: 100%;
  width: 100%;
  background-color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-height: 5px;
}

.loading_demarrage_parent {
  min-width: 90%;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.2901960784);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-height: 5px;
}

.dropdown-custom-danger {
  -webkit-appearance: none !important; /* Override default look */
  appearance: none;
  padding: 8px;
  background-color: red;
  color: white;
}

.dropdown-custom-success {
  -webkit-appearance: none !important; /* Override default look */
  appearance: none;
  padding: 8px;
  background-color: #55D2BC;
  color: white;
}

.custom_select {
  -webkit-appearance: none !important; /* Override default look */
  appearance: none;
  padding: 8px;
  background-color: white;
  color: #566CF0;
  font-weight: 600 !important;
  background-image: url("../../public/icones/arrow_select.svg");
  background-position: right;
  background-repeat: no-repeat;
  width: 100% !important;
}

.pre-inscripion-label label {
  font-size: 0.7rem;
  text-transform: uppercase;
  font-weight: 600 !important;
  opacity: 0.7;
}

.adapt-width-reminder {
  width: 25%;
  min-width: 30%;
}
@media (max-width: 1200px) {
  .adapt-width-reminder {
    width: 50%;
    max-width: 50%;
  }
}
@media (max-width: 800px) {
  .adapt-width-reminder {
    width: 100%;
    max-width: 100%;
  }
}

.button_slide {
  color: #FFF;
  border-radius: 0px;
  padding: 18px 36px;
  display: inline-block;
  font-family: "Lucida Console", Monaco, monospace;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #D80286;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.button_custom_slide {
  border-radius: 0px;
  padding: 18px 36px;
  display: inline-block;
  font-size: 14px;
  letter-spacing: 1px;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #5BA49C;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.roundedLabels label {
  border-radius: 10px;
}

.slide_custom_right:hover {
  box-shadow: inset 400px 0 0 0 #55D2BC;
  color: white;
}

.slide_custom_right_label label:hover {
  box-shadow: inset 400px 0 0 0 #55D2BC;
  color: white !important;
}

.slide_right:hover {
  box-shadow: inset 400px 0 0 0 #D80286;
}

.bg-success-insc {
  background-image: url("../../public/icones/new_bgTEST.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .bg-success-insc {
    background-size: contain;
    background-image: url("../../public/icones/bg_newTESTMOBILE.svg");
  }
}

.bg-modify-fact {
  background-image: url("../../public/icones/bg-modify-fact.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .bg-modify-fact {
    background-color: white;
  }
}

.bg-particulier {
  background-image: url("../../public/icones/fond_ordi.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .bg-particulier {
    background-image: url("../../public/icones/fond_mobile.svg");
  }
}

.bg-modify-fact-big {
  background-image: url("../../public/icones/bg-modify-fact.svg");
  background-repeat: no-repeat;
  background-size: 190%;
  background-position: right;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .bg-modify-fact-big {
    background-color: white;
  }
}

.replace-bouton-fact {
  position: fixed;
  right: 0;
  margin-left: auto;
  margin-right: 10%;
  max-width: 150px;
}
@media (max-width: 996px) {
  .replace-bouton-fact {
    position: static;
    margin-left: auto;
    margin-right: auto;
  }
}

.fond_stat {
  background-image: url("../../public/icones/fond_stat.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .fond_stat {
    background-size: contain;
    background-image: none;
  }
}

.fond_fact {
  background-image: url("../../public/icones/fond_fact.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  overflow-x: visible;
  overflow-y: visible;
}
@media (max-width: 776px) {
  .fond_fact {
    background-size: cover;
    background-image: url("../../public/icones/fond_fact.svg");
  }
}

.div-pro-facture {
  min-width: 250px !important;
  width: 400px;
  max-width: 400px;
  margin-top: 10%;
}
@media (max-width: 776px) {
  .div-pro-facture {
    width: 270px;
    max-width: 270px;
  }
}
@media (max-width: 560px) {
  .div-pro-facture {
    display: none;
  }
}

.button_change_actif {
  background-color: transparent;
  opacity: 1;
  border: 1px solid #566CF0;
}
.button_change_actif p {
  color: #566CF0;
  opacity: 1;
  font-weight: 500 !important;
  font-size: 0.8rem;
}

.button_change_inactif {
  background-color: transparent;
  border: 1px solid rgba(42, 52, 101, 0.1647058824);
}
.button_change_inactif p {
  color: #2A3465;
  opacity: 0.6;
  font-weight: 400;
  font-size: 0.8rem;
}

.button_change_inactif:hover {
  background-color: transparent;
  border: 1px solid #566CF0;
}
.button_change_inactif:hover p {
  color: #566CF0;
  opacity: 1;
}
.button_change_inactif:hover i {
  color: #566CF0 !important;
  opacity: 1;
}

.factures .nav-link {
  border-bottom: transparent !important;
}
.factures .nav-link.active p {
  font-weight: 600 !important;
  color: #55D2BC;
  opacity: 1 !important;
}
.factures .nav-link div h6 {
  font-size: 0.6rem !important;
}
.factures .nav-link.active div h6 {
  font-size: 0.75rem !important;
}

.factures .nav-link {
  border-bottom: transparent !important;
}
.factures .nav-link p {
  font-weight: 400;
  color: grey;
  opacity: 0.4 !important;
  font-size: 0.75rem !important;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .factures .nav-link p {
    font-size: 0.75rem !important;
  }
}

.round-notif {
  border: 2px solid red;
  width: 30px;
  min-width: 30px;
  height: 30px;
}
@media (max-width: 976px) {
  .round-notif {
    background-color: #FF8195;
    width: 10px;
    min-width: 10px;
    height: 10px;
  }
}

.bg-new-danger {
  background-color: #FF8195;
}

.border-new-danger {
  border: 2px solid #FF8195;
}

.bg-badge-new-danger {
  background-color: rgba(255, 129, 149, 0.1019607843);
}

.bg-badge-real-green {
  background-color: rgba(85, 210, 188, 0.0823529412);
}

.bg-badge-primary {
  background-color: rgba(89, 116, 184, 0.1019607843);
}

.bg-badge-bluedark {
  background-color: rgba(42, 52, 101, 0.1019607843);
}

.bg-badge-warning {
  background-color: rgba(247, 147, 30, 0.6352941176);
}

.text-new-danger {
  color: #FF8195;
}

@media (max-width: 976px) {
  .disponibilites {
    max-height: none;
  }
}

.icone_top {
  position: relative;
  margin-top: -45px;
  margin-left: 22px;
}
@media (max-width: 976px) {
  .icone_top {
    margin-left: 80% !important;
    margin-top: -45px;
  }
}

.name-proprietaire {
  font-size: 1rem !important;
  font-weight: 600 !important;
  border: 0px solid transparent !important;
  min-height: 20px;
  margin-left: auto;
  margin-right: auto;
}

#chev_presta input[type=text]::placeholder, input[type=number]::placeholder {
  opacity: 0.4;
  font-style: italic;
}

.no-overflow {
  overflow-x: hidden;
  max-width: 100%;
}

.info_back {
  overflow-x: hidden;
  max-width: 100%;
}

.input-under input, .input-under button {
  border-radius: 0px;
  height: 100%;
}

.description_icone {
  font-size: 0.67rem;
}
@media (max-width: 976px) {
  .description_icone {
    font-size: 8px !important;
  }
}

.link-active {
  border-left: 4px solid white;
}

.content_CGU {
  text-align: justify;
  padding-top: 20px;
  padding-bottom: 80px;
  font-weight: 400 !important;
}
.content_CGU h6.sticky-title {
  padding-top: 102px;
  min-height: 140px;
}

.sticky-title {
  position: fixed;
  top: 0;
  width: 100% !important;
  background: rgb(80, 56, 244);
  background: -moz-linear-gradient(195deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
  background: -webkit-linear-gradient(195deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
  background: linear-gradient(195deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
}

.list_cgu {
  list-style-image: url("../../public/icones/pastille_list.svg");
  font-weight: bold;
}

.border-green {
  min-width: 40px;
  max-width: 40px;
  min-height: 3px;
  max-height: 3px;
}

.newlabelApp {
  font-size: 0.72rem;
  font-weight: 500 !important;
  text-transform: uppercase;
  color: #6c757d;
}

.newlabelAppNoColor {
  font-size: 0.72rem;
  font-weight: 500 !important;
  text-transform: uppercase;
}

.newlabelAppNoColorNoTransform {
  font-size: 0.67rem;
  font-weight: 500 !important;
}

.img-hover {
  opacity: 0.6;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}
@media (max-width: 776px) {
  .img-hover {
    opacity: 1;
  }
}

.dotted {
  border: none;
  border-top: 1px dashed #cccccc;
  color: #cccccc;
  background-color: #fff;
  height: 1px;
  width: 100%;
}

.dotted-white {
  border: none;
  border-top: 1px dashed rgba(255, 255, 255, 0.1647058824);
  color: #cccccc;
  background-color: transparent !important;
  height: 1px;
  width: 100%;
}

.border-left-dashed-pro {
  border-left: 1px dashed #cccccc;
  color: #cccccc;
  background-color: #fff;
}

.img-hover:hover {
  opacity: 1;
}

.perso-input-spe input {
  display: none;
}

.perso-input-spe label {
  padding: 8px;
  margin: 5px;
  border: 1px solid transparent;
  border-radius: 0px;
  opacity: 0.7;
  transition: 0.1s ease-in-out;
  -webkit-transition: background-color 0.1s ease-in-out;
  font-weight: 600 !important;
  margin-bottom: auto !important;
  background-color: transparent !important;
}

.perso-input-spe label:hover {
  cursor: pointer;
  background-color: #55D2BC !important;
  color: white;
  border-color: #55D2BC;
  font-weight: 600 !important;
  opacity: 1;
}

.perso-input-spe input:checked + label {
  cursor: pointer;
  background-color: #55D2BC !important;
  color: white;
  font-weight: 600 !important;
  opacity: 1;
}

.appointment-class label {
  margin-right: auto;
  margin-left: 0px;
}

.under-input-20vh textarea {
  min-height: 25vh !important;
  background-color: rgba(226, 226, 226, 0.6274509804);
}

.mini-trait {
  min-height: 3px;
  max-height: 3px;
  min-width: 24px;
  max-width: 24px;
  background-color: #55D2BC;
}

.resume-div {
  height: 100vh;
  min-width: 150px;
  max-width: 300px !important;
}
@media (max-width: 776px) {
  .resume-div {
    height: 45vh;
    min-width: 100%;
    max-width: 100% !important;
  }
}

@media (max-width: 776px) {
  .media_max_height_resume {
    min-height: 15vh;
    max-height: 15vh;
    overflow: auto;
  }
}

/*
#form_struct{
	max-width:200px !important;
}

.input-force {
	max-width:200px !important;
	width:200px !important;
	display:block;
	option{
		max-width:200px !important;
	}
	@media (max-width: 776px){
		max-width:100% !important;
		border:2px solid blue;
	}
}*/
.horse-media {
  min-width: 100%;
  max-width: 100%;
  margin-right: 10px;
  min-height: 30px;
}
@media (max-width: 776px) {
  .horse-media {
    min-width: 90px;
    max-width: 90px;
    margin-right: 10px;
    min-height: 90px;
  }
}

.creneau-resume {
  min-height: 19vh !important;
  max-height: 19vh !important;
}
@media (max-width: 776px) {
  .creneau-resume {
    min-height: 31vh !important;
    max-height: 31vh !important;
  }
}

.input-force {
  /* Style the arrow inside the select element: */
  /* Point the arrow upwards when the select box is open (active): */
  /* style the items (options), including the selected item: */
  /* Style items (options): */
  /* Hide the items when the select box is closed: */
}
.input-force .custom-select {
  position: relative;
  font-family: Arial;
  margin-bottom: 35px;
  font-size: 10px;
  padding-right: 10px !important;
  background-color: transparent;
}
@media (max-width: 1176px) {
  .input-force .custom-select {
    max-width: 170px;
    margin-bottom: 40px;
  }
}
@media (max-width: 776px) {
  .input-force .custom-select {
    max-width: 100%;
    margin-bottom: 0px !important;
  }
}
.input-force .custom-select select {
  display: none; /*hide original SELECT element: */
}
.input-force .select-selected {
  background-color: #55D2BC;
}
.input-force .select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 14px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}
.input-force .select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}
.input-force .select-items div, .input-force .select-selected {
  color: #ffffff;
  font-size: 12px;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}
.input-force .select-items {
  position: absolute;
  background-color: #55D2BC;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}
.input-force .select-hide {
  display: none;
}
.input-force .select-items div:hover, .input-force .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 776px) {
  .showRecap .recap {
    min-height: 70px;
    border-bottom: 1px solid rgba(85, 210, 188, 0.6274509804);
  }
}

.media-chevaux-list {
  max-height: 50vh;
  height: 100%;
}
@media (min-width: 557px) and (max-width: 776px) {
  .media-chevaux-list {
    max-height: 35vh;
    height: 100%;
  }
}
@media (max-width: 556px) {
  .media-chevaux-list {
    max-height: 30vh;
    height: 100%;
  }
}

.min-height-200 {
  min-height: 200px;
}

.bg-lightwhite {
  background-color: rgba(255, 255, 255, 0.1647058824);
}

#ui-datepicker-div {
  min-width: 270px !important;
  width: 90%;
  max-width: 400px;
  min-height: 400px !important;
  border: 2px solid #F2F2F2;
}
#ui-datepicker-div .ui-datepicker-calendar td, #ui-datepicker-div .ui-datepicker-calendar th {
  padding: 10px;
}
#ui-datepicker-div .ui-datepicker-today {
  font-weight: 700 !important;
  border-bottom: 0.2px solid #2A3465;
}
#ui-datepicker-div .ui-datepicker-title {
  text-transform: uppercase;
  opacity: 0.7;
  color: #2A3465;
  margin-top: auto;
  margin-bottom: auto;
}
#ui-datepicker-div .ui-datepicker-prev, #ui-datepicker-div .ui-datepicker-next {
  color: #2A3465;
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 10px;
}
#ui-datepicker-div .ui-datepicker-header {
  border-bottom: 1px solid #2A3465;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#ui-datepicker-div .ui-datepicker-calendar th {
  opacity: 0.7;
}
#ui-datepicker-div .ui-datepicker-close {
  position: absolute;
  bottom: 0;
  right: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  text-shadow: none;
  font-size: 0.8rem;
  line-height: 1;
  padding: 8px;
  color: #1d1415;
  font-weight: 600;
  opacity: 0.8;
}
#ui-datepicker-div .ui-datepicker-current {
  position: absolute;
  bottom: 0;
  left: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  text-shadow: none;
  font-size: 0.8rem;
  line-height: 1;
  padding: 8px;
  color: #2A3465;
  font-weight: 600;
  opacity: 0.8;
}
#ui-datepicker-div .ui-datepicker-close:focus, #ui-datepicker-div .ui-datepicker-current {
  outline: none;
}
#ui-datepicker-div .ui-datepicker-close:hover, #ui-datepicker-div .ui-datepicker-current {
  opacity: 1;
}

.ui-datepicker {
  background-color: rgba(255, 255, 255, 0.9176470588) !important;
}

.ui-datepicker-title {
  text-transform: uppercase;
  opacity: 0.7;
  color: #2A3465;
  margin-top: auto;
  margin-bottom: auto;
}

.ui-datepicker-prev, .ui-datepicker-next {
  color: #566CF0;
  font-weight: 700;
  font-size: 1.2rem;
  padding-left: 10px;
}

.ui-datepicker-header {
  border-bottom: 1px solid #2A3465;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.ui-datepicker-calendar th {
  opacity: 0.7;
}

.datepickerCSS .ui-datepicker-calendar td {
  padding: 10px;
  text-align: center;
}
.datepickerCSS .ui-datepicker-calendar th {
  text-align: center;
}

#datepickerAppointment .ui-widget-content,
#datepickerAppointment .ui-widget-content,
#datepickerAppointment .ui-datepicker .ui-datepicker-header,
#datepickerAppointment .ui-datepicker .ui-datepicker-title,
#datepickerAppointment .ui-datepicker .ui-datepicker-title,
#datepickerAppointment .ui-datepicker .ui-datepicker-prev,
#datepickerAppointment .ui-datepicker .ui-datepicker-next,
#datepickerAppointment .ui-datepicker table,
#datepickerAppointment .ui-state-default,
#datepickerAppointment .ui-widget-content .ui-state-default,
#datepickerAppointment .ui-widget-header .ui-state-default,
#datepickerAppointment .ui-state-default {
  background: #484848;
}
#datepickerAppointment .ui-datepicker-inline {
  padding: 0;
}
#datepickerAppointment .ui-widget-header {
  border: none;
}
#datepickerAppointment .ui-datepicker .ui-datepicker-header {
  padding: 1.2em 0;
}
#datepickerAppointment .ui-datepicker .ui-datepicker-prev, #datepickerAppointment .ui-datepicker .ui-datepicker-next {
  top: 23px;
}
#datepickerAppointment .ui-icon,
#datepickerAppointment .ui-datepicker-month,
#datepickerAppointment .ui-datepicker-year {
  color: #ffe800;
}
#datepickerAppointment a.ui-state-default,
#datepickerAppointment .ui-datepicker th {
  color: #ffe800 !important;
  border: none !important;
  text-align: center !important;
}
#datepickerAppointment .ui-datepicker {
  width: 24.4em;
  text-transform: uppercase;
}
#datepickerAppointment .ui-datepicker td {
  padding: 15px;
  border: 1px solid transparent;
}
#datepickerAppointment .ui-state-active,
#datepickerAppointment .ui-widget-content .ui-state-active,
#datepickerAppointment .ui-widget-header .ui-state-active {
  color: #f5f5f5 !important;
}
#datepickerAppointment .ui-state-highlight,
#datepickerAppointment .ui-widget-content .ui-state-highlight,
#datepickerAppointment .ui-widget-header .ui-state-highlight {
  background-color: #ffe800;
  color: #484848 !important;
  padding: 15px;
  border-top-left-radius: 1.5em;
  border-bottom-right-radius: 1.5em;
}

.bg-light-opac {
  background-color: rgba(255, 255, 255, 0.1647058824);
}

@media (min-width: 776px) {
  .bg-md-white {
    margin-top: 44vh !important;
  }
}

.validCGUPro {
  margin-top: -6% !important;
}
@media (max-width: 776px) {
  .validCGUPro {
    margin-top: -18% !important;
  }
}

.focus-none:focus {
  background-color: transparent !important;
  border: 2px solid red;
}

.inloading {
  font-size: 0.75rem;
}

.border-bottom-white {
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
  border-top: 0px solid transparent !important;
  border-bottom: 1px solid #3B4E88 !important;
  border-radius: 0px;
}

.mobile_advanced {
  margin-top: 10%;
  width: 60%;
  position: fixed;
}
@media (max-width: 876px) {
  .mobile_advanced {
    position: static;
    margin-top: 0%;
    width: 100%;
    margin-left: 0% !important;
  }
}

.champRecherche {
  max-width: 70%;
  border-left: 1px solid white;
}
@media (max-width: 876px) {
  .champRecherche {
    max-width: 90%;
    width: 80%;
    border-left: 1px solid transparent;
  }
}

@media (max-width: 776px) {
  .form_fixed_mobile {
    position: fixed;
    background-color: #4761AF;
    margin-left: -21% !important;
  }
}

.sayYes, .sayNo {
  transition: 0.3s ease-in-out;
}

.sayNo:hover {
  background-color: #FF8195;
  color: white;
}

.sayNoChecked {
  background-color: #FF8195;
  color: white;
}

.sayYes:hover {
  background-color: #55D2BC;
  color: white;
}

.sayYesChecked {
  background-color: #55D2BC;
  color: white;
}

.no-border-radius input {
  border-radius: 0px !important;
}

.button_complete {
  position: inherit;
  margin-bottom: 20%;
  margin-top: 0% !important;
  padding: 16px;
  max-height: 60px;
}
@media (max-width: 776px) {
  .button_complete {
    position: fixed;
    bottom: 0;
    min-height: 10vh;
    margin-bottom: 0%;
    min-width: 100%;
  }
}

.topContainer {
  z-index: 10000000000000000000000000 !important;
}

.bandeau-chevaux > a {
  transition: 0.2s ease-in-out;
}

.bandeau-chevaux > a:hover .horsePic {
  border: 2px solid #55D2BC;
}

.max-width-80 {
  max-width: 80% !important;
}

.photo-media-compte {
  border-radius: 50%;
  overflow: hidden;
}

.styled-li {
  transition: 0.1s ease-in-out;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}

.styled-li:hover {
  background-color: rgba(86, 108, 240, 0.0666666667);
  color: #566CF0;
}

.myLi {
  background-color: #55D2BC;
  color: white;
}

.height-resize {
  height: 400px;
  min-height: 400px !important;
}
@media (max-width: 776px) {
  .height-resize {
    height: 70%;
    min-height: 70% !important;
  }
}

.square-body .notValid {
  opacity: 0.3;
}

.validTitle {
  color: #55D2BC !important;
}

.heightTextArea textarea {
  min-height: 70px;
}

.divChoiceUnselected {
  background-color: rgba(226, 226, 226, 0.6274509804);
  color: grey;
  cursor: pointer;
}

.divChoiceUnselected:hover {
  background-color: rgba(85, 210, 188, 0.0392156863);
  color: #55D2BC;
}

.divChoiceSelected {
  background-color: rgba(85, 210, 188, 0.0392156863);
  color: #55D2BC;
  cursor: pointer;
}

#lastSelect {
  /* CSS from Bootstrap End */
}
#lastSelect select,
#lastSelect select.form-control {
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 0;
  padding-right: 0\9 ;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
  -webkit-background-size: 13px 13px;
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-box-shadow: inset 0 -1px 0 #dddddd;
  box-shadow: inset 0 -1px 0 #dddddd;
  font-size: 16px;
  line-height: 1.5;
}
#lastSelect select::-ms-expand,
#lastSelect select.form-control::-ms-expand {
  display: none;
}
#lastSelect select.input-sm,
#lastSelect select.form-control.input-sm {
  font-size: 12px;
}
#lastSelect select.input-lg,
#lastSelect select.form-control.input-lg {
  font-size: 17px;
}
#lastSelect select:focus,
#lastSelect select.form-control:focus {
  -webkit-box-shadow: inset 0 -2px 0 #55D2BC;
  box-shadow: inset 0 -2px 0 #55D2BC;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEUhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISF8S9ewAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
}
#lastSelect select[multiple],
#lastSelect select.form-control[multiple] {
  background: none;
}

.underSelect150 select {
  min-width: 180px;
}

.underSelect220 select {
  min-width: 230px !important;
}

.underSelect280 select {
  min-width: 280px !important;
}

.colorRole {
  transition: 0.2 ease-in-out;
  opacity: 0.7;
}

.colorRole:hover {
  border: 1px solid #566CF0 !important;
  opacity: 1;
}

.colorRole-checked {
  border: 1px solid #566CF0 !important;
  opacity: 1;
}

.newSelect {
  /* CSS from Bootstrap End */
}
.newSelect select,
.newSelect select.form-control {
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 0;
  padding-right: 0\9 ;
  background-color: transparent !important;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEVmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmaP/QSjAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
  -webkit-background-size: 13px 13px;
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-box-shadow: inset 0 -1px 0 #dddddd;
  box-shadow: inset 0 -1px 0 #dddddd;
  font-size: 16px;
  line-height: 1.9;
}
.newSelect select::-ms-expand,
.newSelect select.form-control::-ms-expand {
  display: none;
}
.newSelect select.input-sm,
.newSelect select.form-control.input-sm {
  font-size: 12px;
}
.newSelect select.input-lg,
.newSelect select.form-control.input-lg {
  font-size: 17px;
}
.newSelect select:focus,
.newSelect select.form-control:focus {
  -webkit-box-shadow: inset 0 -2px 0 #55D2BC;
  box-shadow: inset 0 -2px 0 #55D2BC;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAMAAACelLz8AAAAJ1BMVEUhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISEhISF8S9ewAAAADHRSTlMAAgMJC0uWpKa6wMxMdjkoAAAANUlEQVR4AeXJyQEAERAAsNl7Hf3X6xt0QL6JpZWq30pdvdadme+0PMdzvHm8YThHcT1H7K0BtOMDniZhWOgAAAAASUVORK5CYII=);
}
.newSelect select[multiple],
.newSelect select.form-control[multiple] {
  background: none;
}

.newSelectPrimary select, .newSelectPrimary select.form-control {
  -webkit-box-shadow: inset 0 -1px 0 #566CF0;
  box-shadow: inset 0 -1px 0 #566CF0;
}

.newSelect08 select {
  font-size: 0.8rem;
}
.newSelect08 select option {
  font-size: 0.8rem;
}

.newSelectNoBorder select, .newSelectNoBorder select.form-control {
  border-color: transparent !important;
  -webkit-box-shadow: inset 0 -1px 0 transparent;
  box-shadow: inset 0 -1px 0 transparent;
}
.newSelectNoBorder select:focus,
.newSelectNoBorder select.form-control:focus {
  border-color: transparent !important;
  -webkit-box-shadow: inset 0 -1px 0 transparent !important;
  box-shadow: inset 0 -1px 0 transparent !important;
  background-image: none !important;
}

.newSelectTextPrimary select {
  color: #566CF0 !important;
}

.newSelectTextWhite select {
  color: white !important;
}

.newSelectMinWidth250 select {
  min-width: 100% !important;
  margin-right: 10px !important;
  margin-left: 0px !important;
}

.newSelectDate select {
  min-width: 50px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

@media (max-width: 776px) {
  .newSelectDateResp select {
    min-width: 40px !important;
    margin-right: 5px !important;
    margin-left: 5px !important;
  }
}

.placeholder_perso::placeholder {
  color: black !important;
  font-size: 0.8rem;
  opacity: 0.7;
  font-style: italic;
}

.planning_media {
  min-height: 79vh;
  max-height: 79vh;
}
@media (max-width: 776px) {
  .planning_media {
    min-height: 87vh;
    max-height: 87vh;
  }
}

.hover-primary {
  transition: 0.2s ease-in-out;
  cursor: pointer;
  opacity: 0.7;
  color: #6c757d !important;
}

.hover-primary:hover {
  color: #566CF0 !important;
  opacity: 1 !important;
}

.hover-primary-bis:hover p {
  color: #566CF0 !important;
}

.grecaptcha-badge {
  visibility: hidden;
  max-width: 1px !important;
  width: 1px;
  max-height: 2px;
  height: 2px;
}

.g-recaptcha {
  max-width: 100% !important;
}

.caldendarNotif {
  min-height: 15px;
  max-height: 15px;
  max-width: 15px;
  min-width: 15px;
  border-radius: 50%;
  position: relative;
  margin-left: 55%;
  margin-bottom: -13%;
}
.caldendarNotif small {
  font-size: 7px !important;
}

.caldendarNotifMobile {
  min-height: 15px;
  max-height: 15px;
  max-width: 15px;
  min-width: 15px;
  border-radius: 50%;
  position: absolute;
  margin-left: 4%;
  margin-top: 4%;
}
.caldendarNotifMobile small {
  font-size: 7px !important;
}

.guide_app {
  margin-top: 102px !important;
}
@media (max-width: 776px) {
  .guide_app {
    margin-top: -142px !important;
  }
}

.resetButtonStyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.btnSearchAnnonce {
  margin-right: -3px !important;
}
@media (max-width: 776px) {
  .btnSearchAnnonce {
    margin-right: 0px !important;
  }
}

.divSearchAnnonces {
  margin-bottom: -30px;
}
@media (max-width: 776px) {
  .divSearchAnnonces {
    margin-bottom: -80px !important;
  }
  .divSearchAnnonces .border-right {
    border-color: transparent !important;
  }
}

@media (min-width: 1006px) and (max-width: 1208px) {
  .changeDirectionStats {
    display: flex;
    flex-direction: column !important;
  }
  .changeDirectionStats .col-md-6 {
    min-width: 100%;
    max-height: 150px !important;
  }
}
@media (min-width: 656px) and (max-width: 768px) {
  .changeDirectionStats #myChart {
    max-height: 280px !important;
    width: auto !important;
  }
}

.todayDiv {
  max-height: 700px;
}
@media (max-width: 768px) {
  .todayDiv {
    max-height: none !important;
  }
}

.spanHighlight {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #55D2BC 40%);
}

.spanHighlightPrimary {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, rgba(86, 108, 240, 0.4156862745) 40%);
}

.bg-poster {
  background-image: url("../../public/icones/poster.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

#bg-login {
  background-image: url("../../public/icones/bg_login.svg");
  background-repeat: repeat;
  background-size: 105%;
  background-position: center;
}

._bg_round {
  background-image: url("../../public/icones/bg_round.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
}

#bg-landing-pro {
  background-image: url("../../public/icones/landing/landing_pro.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 50%;
  overflow-x: hidden;
  overflow-y: visible;
}
@media (min-height: 800px) and (max-height: 1200px) and (min-width: 1000px) and (max-width: 1400px) {
  #bg-landing-pro {
    background-size: 60%;
  }
}
@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  #bg-landing-pro {
    background-image: url("../../public/icones/landing/landing_pro.svg");
    background-size: 40%;
  }
}
@media (max-width: 1100px) {
  #bg-landing-pro {
    background-image: url("../../public/icones/landing/landing_pro_md.svg");
  }
}
@media (max-width: 778px) {
  #bg-landing-pro {
    background-image: none;
  }
}
@media (max-height: 500px) {
  #bg-landing-pro {
    min-height: 600px !important;
  }
}

.landingPro2 {
  display: none;
}
@media (min-width: 750px) and (max-width: 778px) {
  .landingPro2 {
    display: flex;
    margin-top: 15% !important;
  }
}
@media (max-width: 750px) {
  .landingPro2 {
    display: flex;
    margin-top: 10% !important;
  }
}

.__adjust_lg_margin_left {
  margin-left: -500px;
}
@media (min-width: 1950px) {
  .__adjust_lg_margin_left {
    margin-left: -640px !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__landing_adjust_ipad {
    min-width: 600px !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__landing_img_adjust_ipad {
    margin-right: 0px !important;
    min-width: 650px !important;
  }
}
@media (min-height: 900px) and (max-height: 1099px) and (min-width: 730px) and (max-width: 950px) {
  .__landing_img_adjust_ipad {
    margin-right: 0px !important;
    min-width: 350px !important;
    width: 42% !important;
    max-width: 42% !important;
    min-height: 55vh !important;
    height: auto !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__landing_img_adjust_ipad_lg {
    margin-right: 0px !important;
    min-width: 650px !important;
    height: 70vh !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__resize_ipad {
    min-height: 40vh !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__resize_ipad_30 {
    min-height: 30vh !important;
    justify-content: flex-start !important;
    padding-bottom: 100px;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__resize_ipad_force_30 {
    height: 30vh !important;
    max-height: 30vh !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__iphone_png_ipad {
    width: 390px !important;
    min-width: 390px !important;
    max-width: 390px !important;
    height: 390px !important;
    min-height: 390px !important;
    max-height: 390px !important;
    margin-left: -50px !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__remove_mt_ipad {
    margin-top: 0px !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__landing_primary_bar {
    display: none !important;
    margin: 0px !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__landing_primary_bar_text {
    color: #566CF0 !important;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__resize_ipad_2 {
    min-height: 60vh !important;
  }
}

@media (min-height: 730px) and (max-height: 1130px) and (min-width: 770px) and (max-width: 990px) {
  .__resize_square_ipad {
    min-height: 60vh !important;
    padding-top: 120px;
  }
}

@media (min-height: 1100px) and (max-height: 1400px) and (min-width: 800px) and (max-width: 1100px) {
  .__resize_padding_ipad {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (min-width: 750px) and (max-width: 798px) {
  .__adapt_pro_1 {
    margin-top: 10% !important;
  }
}
@media (min-width: 1200px) and (min-height: 800px) {
  .__adapt_pro_1 {
    margin-bottom: 22% !important;
    margin-left: 6% !important;
  }
}

.bg-phones-landing {
  background-image: url("../../public/icones/landing/phones_landing.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  margin-top: -4%;
}
@media (max-width: 788px) {
  .bg-phones-landing {
    margin-top: 0%;
    background-image: url("../../public/icones/landing/phones_landing_sm.svg");
    background-size: contain;
  }
}

#bg-landing-pro-blue {
  background-image: url("../../public/icones/landing/bg-landing-blue.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  margin-top: -6%;
}
@media (min-width: 789px) and (max-width: 988px) {
  #bg-landing-pro-blue {
    margin-top: 0%;
    min-height: 620px !important;
  }
  #bg-landing-pro-blue .padding-media {
    padding-top: 50px;
  }
}
@media (max-width: 788px) {
  #bg-landing-pro-blue {
    margin-top: -140px !important;
    background-size: 690%;
  }
  #bg-landing-pro-blue .padding-media {
    padding-top: 260px;
  }
}

#img_slide_pro {
  min-height: 100vh;
}
@media (max-width: 498px) {
  #img_slide_pro {
    background-image: url("../../public/icones/landing/slide_presentation_pro.svg");
    background-repeat: no-repeat;
    background-size: 230%;
    background-position: bottom;
    width: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    min-height: 75vh;
  }
}
@media (min-width: 499px) and (max-width: 588px) {
  #img_slide_pro {
    background-image: url("../../public/icones/landing/slide_presentation_pro.svg");
    background-repeat: no-repeat;
    background-size: 230%;
    background-position: bottom;
    width: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    min-height: 85vh;
  }
}
@media (min-width: 589px) and (max-width: 758px) {
  #img_slide_pro {
    background-image: url("../../public/icones/landing/slide_presentation_pro.svg");
    background-repeat: no-repeat;
    background-size: 150%;
    background-position: bottom;
    width: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    min-height: 75vh;
  }
}
@media (min-width: 759px) and (max-width: 998px) {
  #img_slide_pro {
    background-image: url("../../public/icones/landing/slide_presentation_pro.svg");
    background-repeat: no-repeat;
    background-size: 150%;
    background-position: bottom;
    width: 100%;
    overflow-x: hidden;
    overflow-y: visible;
    min-height: 95vh;
  }
}
@media (min-width: 750px) and (max-width: 850px) and (min-height: 1008px) and (max-height: 1108px) {
  #img_slide_pro {
    background-size: 170%;
    min-height: 65vh;
  }
}

#card_one_landing {
  max-width: 420px;
}
@media (max-width: 788px) {
  #card_one_landing {
    max-width: 290px;
    width: 290px;
  }
}

.imgPaiementLanding {
  min-width: 45%;
  max-width: 550px;
  height: auto;
  max-height: 80vh;
}
@media (max-width: 767px) {
  .imgPaiementLanding {
    min-width: 90%;
    max-width: 450px;
    height: auto;
    max-height: 60vh;
  }
}

.imgPriseRdvLanding {
  min-width: 40%;
  max-width: 40%;
  height: auto;
  max-height: 80vh;
}
@media (max-width: 768px) {
  .imgPriseRdvLanding {
    min-width: 120%;
    max-width: 650px;
    margin-left: -40px !important;
    height: auto;
    max-height: 60vh;
  }
}

@media (max-width: 788px) {
  .img_horse_pforil_landing {
    margin-left: 0px !important;
  }
}

.landingPro .mytooltip {
  position: relative;
  display: inline-block;
}
.landingPro .mytooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #2A3465;
  color: white;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
.landingPro .mytooltip .tooltiptextRight {
  margin-left: -30px !important;
}
.landingPro .mytooltip .tooltiptext-primary {
  background-color: #566CF0 !important;
  visibility: hidden;
  width: 120px;
  color: white;
  text-align: left;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}
.landingPro .mytooltip:hover .tooltiptext {
  visibility: visible;
}

.sendFact svg {
  width: 15px;
  height: 15px;
  margin: 0px;
  display: inline-block;
}

.rdv-existant {
  min-width: 200px !important;
}

.text-bluedark {
  color: #2A3465;
}

.bg-bluedark {
  background-color: #2A3465;
}

.border-bluedark {
  border-color: #2A3465;
}

.labelBlueDark label {
  font-size: 0.78rem;
  color: #2A3465;
  font-weight: 500;
}

.underBlueDark p, .underBlueDark input, .underBlueDark option {
  color: #2A3465 !important;
}

.underBlueDarkNotImportant p, .underBlueDarkNotImportant input, .underBlueDarkNotImportant option {
  color: #2A3465;
}

.allSameSize p, .allSameSize small, .allSameSize input, .allSameSize label, .allSameSize option, .allSameSize select, .allSameSize textarea {
  font-size: 0.8rem !important;
}

.allSameSizeBigger p, .allSameSizeBigger small, .allSameSizeBigger input, .allSameSizeBigger label, .allSameSizeBigger option, .allSameSizeBigger select, .allSameSizeBigger textarea {
  font-size: 0.88rem !important;
}

.underStyled p, .underStyled label, .underStyled small, .underStyled span, .underStyled input::placeholder, .underStyled select, .underStyled option {
  font-weight: 600 !important;
  color: #2A3465 !important;
}

.underBlueDarkAll p, .underBlueDarkAll label, .underBlueDarkAll small, .underBlueDarkAll span, .underBlueDarkAll input::placeholder, .underBlueDarkAll input, .underBlueDarkAll select, .underBlueDarkAll option {
  color: #2A3465 !important;
}

.underPlaceBluedark input::placeholder {
  color: #2A3465 !important;
  opacity: 0.6;
}

@media (min-width: 878px) {
  .border-left-md-none {
    border-left: 0px solid transparent !important;
  }
}

.pac-container {
  z-index: 10000000000000000000000000 !important;
}

._proprio_rdv_media {
  max-width: 440px;
}
@media (min-width: 978px) and (max-width: 1278px) {
  ._proprio_rdv_media {
    max-width: 340px !important;
  }
}

@media (min-width: 878px) and (max-width: 978px) {
  ._proprio_rdv_media_2 {
    max-width: 450px !important;
  }
}
@media (min-width: 979px) and (max-width: 1278px) {
  ._proprio_rdv_media_2 {
    max-width: 590px !important;
  }
}
@media (min-width: 1279px) and (max-width: 1478px) {
  ._proprio_rdv_media_2 {
    max-width: 790px !important;
  }
}
@media (min-width: 1479px) {
  ._proprio_rdv_media_2 {
    max-width: 890px !important;
  }
}

@media (max-width: 978px) {
  .max_w_media_prof {
    max-width: 100% !important;
  }
}

.placeholder_discret input::placeholder {
  color: #2A3465 !important;
  font-style: italic;
  opacity: 0.5;
}

.placeholder_discret_2 input::placeholder {
  color: #2A3465 !important;
  font-style: italic;
  opacity: 0.2;
  font-size: 0.8rem;
}

.typewrite {
  color: #2A3465 !important;
  font-weight: 800 !important;
}

.renderTextCr p, .renderTextCr h6, .renderTextCr h5, .renderTextCr h4, .renderTextCr h3, .renderTextCr h2, .renderTextCr h1 {
  font-size: 0.76rem !important;
  color: #2A3465;
}

.ulTiny {
  font-size: 0.8rem;
  color: #2A3465 !important;
  opacity: 1 !important;
}
.ulTiny p, .ulTiny h6, .ulTiny h5, .ulTiny h4, .ulTiny h3, .ulTiny h2, .ulTiny h1 {
  font-size: 0.76rem !important;
  color: #2A3465;
  margin-bottom: 5px !important;
}
.ulTiny ul {
  margin-bottom: 5px !important;
}

.ulTiny ul {
  list-style: square !important;
  font-size: 0.8rem;
  list-style-image: url("../../public/icones/caret-right-fill.svg");
}

.ulTiny ul:not(.tox-checklist) li::before {
  content: "→  ";
}

.tox-checklist {
  list-style: square;
  font-size: 0.8rem;
}

.tox-checklist > li:not(.tox-checklist--hidden) {
  list-style: none;
  margin: 0.25em 0;
  position: relative;
}

.tox-checklist > li:not(.tox-checklist--hidden)::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-unchecked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2215%22%20height%3D%2215%22%20x%3D%22.5%22%20y%3D%22.5%22%20fill-rule%3D%22nonzero%22%20stroke%3D%22%234C4C4C%22%20rx%3D%222%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
  cursor: pointer;
  height: 1em;
  margin-left: -1.5em;
  margin-top: 0.125em;
  position: absolute;
  width: 1em;
}

.tox-checklist li:not(.tox-checklist--hidden).tox-checklist--checked::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20id%3D%22checklist-checked%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20id%3D%22Rectangle%22%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22%234099FF%22%20fill-rule%3D%22nonzero%22%20rx%3D%222%22%2F%3E%3Cpath%20id%3D%22Path%22%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%20d%3D%22M11.5703186%2C3.14417309%20C11.8516238%2C2.73724603%2012.4164781%2C2.62829933%2012.83558%2C2.89774797%20C13.260121%2C3.17069355%2013.3759736%2C3.72932262%2013.0909105%2C4.14168582%20L7.7580587%2C11.8560195%20C7.43776896%2C12.3193404%206.76483983%2C12.3852142%206.35607322%2C11.9948725%20L3.02491697%2C8.8138662%20C2.66090143%2C8.46625845%202.65798871%2C7.89594698%203.01850234%2C7.54483354%20C3.373942%2C7.19866177%203.94940006%2C7.19592841%204.30829608%2C7.5386474%20L6.85276923%2C9.9684299%20L11.5703186%2C3.14417309%20Z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E%0A");
}

#nouveautesValkae {
  width: 30%;
}
@media (max-width: 1168px) {
  #nouveautesValkae {
    width: 50%;
  }
}
@media (max-width: 668px) {
  #nouveautesValkae {
    width: 100%;
  }
}

.prereglages {
  color: #2A3465;
  opacity: 0.7;
  transition: 0.2s ease-in-out;
  border-bottom: 1px solid transparent;
}
.prereglages p {
  color: #2A3465;
}

.prereglages:hover {
  color: #566CF0 !important;
  opacity: 1;
  border-bottom: 1px solid #566CF0;
}
.prereglages:hover p {
  color: #566CF0 !important;
}

.prereglagesChoosen {
  color: #566CF0 !important;
  opacity: 1;
  border-bottom: 1px solid #566CF0;
}
.prereglagesChoosen p {
  color: #566CF0 !important;
}

.newSelectPaddingRight select {
  padding-right: 20px;
}

.popover-header {
  font-size: 0.7rem;
  font-weight: 700 !important;
  color: #2A3465;
}

.popover-body {
  font-size: 0.7rem;
  font-weight: 500 !important;
  opacity: 0.8;
  color: #2A3465;
}

@media (max-width: 340px) {
  ._resize_title_SE .resize_me {
    font-size: 0.71rem !important;
  }
}

@media (min-width: 940px) {
  ._media_padding_top {
    padding-top: 90px;
  }
}

@media (min-width: 740px) {
  ._media_padding_top_3 {
    padding-top: 140px;
  }
}

@media (min-width: 940px) {
  ._media_padding_top_2 {
    padding-top: 60px;
  }
}

._choose_vermifuge {
  transition: 0.2s ease-in-out;
  border: 2px solid transparent;
  cursor: pointer;
}
._choose_vermifuge:hover {
  border: 2px solid #566CF0;
}
._choose_vermifuge:hover ._nom_vermifuge {
  color: #566CF0 !important;
}
._choose_vermifuge:hover ._check {
  display: block !important;
}
._choose_vermifuge ._check {
  display: none;
  transition: 0.2s ease-in-out;
}

._choosen_vermifuge {
  border: 2px solid #566CF0;
}
._choosen_vermifuge ._nom_vermifuge {
  color: #566CF0 !important;
}
._choosen_vermifuge ._check {
  display: block;
}

.menu-hover-primary p {
  transition: 0.1s ease-in-out;
  color: #2A3465;
}

.menu-hover-primary p:hover {
  color: #566CF0 !important;
}

.media_input_label_jour {
  margin-left: 85%;
}
@media (max-width: 330px) {
  .media_input_label_jour {
    margin-left: 70%;
  }
}
@media (min-width: 331px) and (max-width: 400px) {
  .media_input_label_jour {
    margin-left: 75%;
  }
}
@media (min-width: 401px) and (max-width: 600px) {
  .media_input_label_jour {
    margin-left: 76%;
  }
}
@media (min-width: 601px) and (max-width: 1200px) {
  .media_input_label_jour {
    margin-left: 82%;
  }
}

._outside_app_media {
  width: 190px;
}
@media (max-width: 768px) {
  ._outside_app_media {
    width: 100% !important;
  }
}

._btn_search_media_part {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 100%;
}
@media (max-width: 770px) {
  ._btn_search_media_part {
    max-height: 45px;
    height: auto;
    border-radius: 8px;
  }
}

._form_background {
  background-color: white;
}
@media (max-width: 840px) {
  ._form_background {
    background-color: transparent;
  }
}

.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  min-width: 290px;
  max-width: 100%;
  padding: 25px;
  border: 1px dashed #566CF0;
  border-radius: 3px;
  transition: 0.2s;
}
.file-drop-area .alert-danger {
  display: none;
}

.fake-btn {
  flex-shrink: 0;
  background-color: #566CF0;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
  color: white !important;
}

.file-msg {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #566CF0 !important;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}
.file-input:focus {
  outline: none;
}

.custom02 input[type=radio] {
  display: none;
}

.custom02 input[type=checkbox] {
  display: none;
}

.custom02 label {
  position: relative;
  display: inline-block;
  padding: 3px 3px 3px 20px;
  cursor: pointer;
  color: #2A3465;
}

.custom02 label::before,
.custom02 label::after {
  position: absolute;
  content: "";
  top: 50%;
  border-radius: 100%;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.custom02 label::before {
  left: 0;
  min-width: 15px;
  min-height: 15px;
  max-width: 15px;
  max-height: 15px;
  margin-top: -7px;
  background: transparent;
  border: 1px solid #ccc;
}

.custom02 label:hover::before {
  background: #fff;
}

.custom02 label::after {
  opacity: 0;
  left: 3.6px;
  min-width: 8px;
  min-height: 8px;
  max-width: 8px;
  max-height: 8px;
  margin-top: -3.7px;
  background: #566CF0;
  -webkit-transform: scale(2);
  transform: scale(2);
}

.custom02 input[type=radio]:checked + label::before {
  background: #fff;
  border: 1px solid #566CF0;
}

.custom02 input[type=radio]:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  color: #566CF0;
}

.custom02 input[type=radio]:checked + label {
  color: #566CF0 !important;
}

.custom02 input[type=checkbox]:checked + label::before {
  background: #fff;
  border: 1px solid #566CF0;
}

.custom02 input[type=checkbox]:checked + label::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  color: #566CF0;
}

.custom02 input[type=checkbox]:checked + label {
  color: #566CF0 !important;
}

#securityCode {
  --spacing: 8px;
  --hue: 400;
  --background1: hsl(214, 14%, 20%);
  --background2: hsl(214, 14%, 13%);
  --background3: hsl(214, 14%, 5%);
  --brand1: hsl(var(--hue) 80% 60%);
  --text1: hsl(0,0%,100%);
  --text2: hsl(0,0%,90%);
}
#securityCode .code {
  background: var(--background3);
}
#securityCode a {
  color: var(--brand1);
  text-decoration: none;
}
#securityCode .number-code > div {
  display: flex;
}
#securityCode .number-code > div > input:not(:last-child) {
  margin-right: calc(var(--spacing) * 2);
}
#securityCode .content-area {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 2);
  background: var(--background2);
  padding: var(--spacing);
  border-radius: var(--spacing);
}
#securityCode .content-area p {
  color: var(--text2);
  font-size: 0.8em;
}
#securityCode input.code-input {
  font-size: 1.6rem;
  max-width: 60px;
  text-align: center;
  flex: 1 0 1em;
}
#securityCode input[type=submit] {
  margin-left: auto;
  display: block;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
}
#securityCode input[type=submit]:hover {
  background: var(--background3);
}
#securityCode input {
  font-size: 1.6rem !important;
  padding: var(--spacing);
  border-radius: calc(var(--spacing) / 2);
  color: var(--text1);
  background-color: #2A3465 !important;
  border: 4px solid transparent;
  max-width: 60px;
}
#securityCode input:invalid {
  box-shadow: none;
}
#securityCode input:focus {
  outline: none;
  border: 2px solid #2A3465;
  background-color: #597BF1 !important;
}
#securityCode input::placeholder {
  opacity: 0.3;
  color: white !important;
}

._media_with_carre_annon {
  width: 23%;
}
@media (max-width: 600px) {
  ._media_with_carre_annon {
    width: 43%;
    margin-bottom: 15px;
  }
}

@media (max-width: 800px) {
  .max_height_rdv_facture {
    max-height: 80px !important;
  }
}

._width_image_version_fact {
  min-width: 450px;
  max-height: 90vh;
  min-height: 90vh;
  height: auto;
}
@media (max-width: 800px) {
  ._width_image_version_fact {
    min-width: 300px;
    max-width: 300px;
    height: auto;
    min-height: 430px !important;
    max-height: 430px !important;
  }
}

#persoAccordion .tabTitle {
  font-size: 0.78rem;
  font-weight: 500 !important;
  margin-bottom: 0px;
}

#persoAccordion .tabContent {
  font-size: 0.7rem;
  font-weight: 400 !important;
  margin-top: 0px;
}

.placeholderPrimary::placeholder {
  color: #566CF0 !important;
  opacity: 0.5;
}

._07 {
  font-size: 0.7rem !important;
}

._08 {
  font-size: 0.8rem !important;
}

._09 {
  font-size: 0.9rem !important;
}

.fw500 {
  font-weight: 500 !important;
}

.fw400 {
  font-weight: 400 !important;
}

._color_selected {
  opacity: 1;
  cursor: pointer;
}
._color_selected .bi-check2-circle {
  display: flex;
}
._color_selected .bi-circle {
  display: none;
}

._color_not_selected {
  opacity: 0.6;
}
._color_not_selected .bi-check2-circle {
  display: none;
}
._color_not_selected .bi-circle {
  display: flex;
}

._color_not_selected:hover {
  opacity: 1;
  cursor: pointer;
}

._font_selected {
  opacity: 1;
  cursor: pointer;
}
._font_selected .bi-check2-circle {
  display: flex;
}
._font_selected .bi-circle {
  display: none;
}
._font_selected p {
  color: #566CF0 !important;
}

._font_not_selected {
  opacity: 0.6;
}
._font_not_selected .bi-check2-circle {
  display: none;
}
._font_not_selected .bi-circle {
  display: flex;
}
._font_not_selected p {
  color: #2A3465 !important;
}

._font_not_selected:hover {
  opacity: 1;
  cursor: pointer;
}

._logo_selected {
  opacity: 1;
  cursor: pointer;
}
._logo_selected .bi-check2-circle {
  display: flex;
}
._logo_selected .bi-circle {
  display: none;
}
._logo_selected p {
  color: #566CF0 !important;
}

._logo_not_selected {
  opacity: 0.6;
}
._logo_not_selected .bi-check2-circle {
  display: none;
}
._logo_not_selected .bi-circle {
  display: flex;
}
._logo_not_selected p {
  color: #2A3465 !important;
}

._logo_not_selected:hover {
  opacity: 1;
  cursor: pointer;
}

.hoverAnimateHorse {
  transition: 0.2s ease-in-out;
}
.hoverAnimateHorse .bi {
  display: none;
}

.hoverAnimateHorse:hover p {
  color: #566CF0;
}
.hoverAnimateHorse:hover .bg-lightgrey {
  border: 1px solid #566CF0;
}
.hoverAnimateHorse:hover .bi {
  display: block;
}

._app_hover_check .bi-circle {
  display: flex;
}
._app_hover_check .bi-check-circle-fill {
  display: none;
}

._app_hover_check:hover .bi-circle {
  display: none;
}
._app_hover_check:hover .bi-check-circle-fill {
  display: flex;
}
._app_hover_check:hover p {
  color: #566CF0;
}

.hover-primary-app {
  color: #2A3465;
}

.hover-primary-app:hover {
  color: #566CF0 !important;
}

.datepicker_wrapper .ui-datepicker-month, .datepicker_wrapper .ui-datepicker-year {
  color: #2A3465 !important;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500 !important;
  margin-top: auto;
  margin-bottom: auto;
}
.datepicker_wrapper .ui-datepicker-header, .datepicker_wrapper .ui-widget-header {
  background-color: transparent !important;
  border-bottom: 1px solid #566CF0 !important;
  border-left: 0px solid transparent !important;
  border-right: 0px solid transparent !important;
  border-top: 0px solid transparent !important;
  border-radius: 0px !important;
}
.datepicker_wrapper .ui-datepicker-title {
  padding-top: 5px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.datepicker_wrapper .ui-state-default {
  border-color: transparent !important;
  background-color: transparent !important;
  text-align: center;
}
.datepicker_wrapper .ui-datepicker-calendar td {
  align: center;
}
.datepicker_wrapper .ui-datepicker-calendar a:hover {
  color: #566CF0 !important;
}
.datepicker_wrapper .ui-state-highlight {
  color: #566CF0 !important;
}
.datepicker_wrapper .ui-datepicker-buttonpane {
  padding-top: 20px;
}
.datepicker_wrapper .ui-datepicker-buttonpane button {
  font-size: 0.7rem !important;
}
.datepicker_wrapper .ui-datepicker-buttonpane .ui-datepicker-close {
  color: #2A3465 !important;
  font-weight: 400 !important;
}
.datepicker_wrapper .ui-datepicker-prev span,
.datepicker_wrapper .ui-datepicker-next span {
  background-image: none !important;
}
.datepicker_wrapper .ui-datepicker-prev:before,
.datepicker_wrapper .ui-datepicker-next:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
}
.datepicker_wrapper .ui-datepicker-prev:before {
  content: "\f104";
}
.datepicker_wrapper .ui-datepicker-prev:hover {
  background-color: transparent !important;
  border: 0px solid transparent;
}
.datepicker_wrapper .ui-datepicker-prev:hover span {
  color: #566CF0;
}
.datepicker_wrapper .ui-datepicker-next-hover:hover {
  background-color: transparent !important;
  border: 0px solid transparent !important;
}
.datepicker_wrapper .ui-datepicker-next-hover:hover span {
  color: #566CF0;
}
.datepicker_wrapper .ui-datepicker-next:before {
  content: "\f105";
}

.replace-me-media {
  margin-left: 92%;
}
@media (max-width: 800px) {
  .replace-me-media {
    margin-left: 85%;
  }
}
@media (max-width: 330px) {
  .replace-me-media {
    margin-left: 81%;
  }
}

@media (min-width: 800px) {
  .border-md-left {
    border-left: 1px solid lightgrey;
  }
}

._delete_horse_app_square {
  background-color: rgba(42, 52, 101, 0.1647058824);
  color: #2A3465;
}

._delete_horse_app_square:hover {
  background-color: rgba(86, 108, 240, 0.1647058824);
  color: #566CF0;
}

.remove-pl-first select:first-child {
  margin-left: 3px !important;
  padding-left: 0px !important;
}

.under-div-filtre-client > div {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
@media (max-width: 450px) {
  .under-div-filtre-client > div {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 380px) {
  .under-div-filtre-client > div {
    width: 320px;
    min-width: 320px;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 330px) {
  .under-div-filtre-client > div {
    width: 280px;
    min-width: 280px;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;
  }
}

.under-div-filtre-client .bi {
  margin-left: 370px;
  margin-top: 5px !important;
}
@media (max-width: 450px) {
  .under-div-filtre-client .bi {
    margin-left: 330px;
  }
}
@media (max-width: 380px) {
  .under-div-filtre-client .bi {
    margin-left: 300px;
  }
}
@media (max-width: 330px) {
  .under-div-filtre-client .bi {
    margin-left: 260px;
  }
}

._full_display_form_client .moreFilters {
  width: 400px;
}
._full_display_form_client .searchNomClient, ._full_display_form_client .searchNomClient input {
  max-width: 270px !important;
}
._full_display_form_client input {
  min-width: 270px;
}
._full_display_form_client button {
  width: 40px;
}
._full_display_form_client .under-div-filtre-client > div {
  max-width: 300px !important !important;
  padding-top: 14px;
}
@media (max-width: 450px) {
  ._full_display_form_client input {
    width: 380px !important;
    min-width: 380px !important;
    max-width: 380px !important;
  }
  ._full_display_form_client .moreFilters {
    width: 100%;
  }
  ._full_display_form_client .overButton {
    width: 100% !important;
  }
  ._full_display_form_client button {
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  ._full_display_form_client .overButton {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (max-width: 380px) {
  ._full_display_form_client .form-group {
    margin-left: 10px !important;
  }
  ._full_display_form_client input {
    width: 320px !important;
    min-width: 320px !important;
    max-width: 320px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  ._full_display_form_client .searchNomClient {
    width: 320px !important;
    min-width: 320px !important;
    max-width: 320px !important;
  }
  ._full_display_form_client button {
    width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  ._full_display_form_client .overButton {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (max-width: 330px) {
  ._full_display_form_client input {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
    margin-left: auto;
    margin-right: auto;
  }
  ._full_display_form_client #nomChevalClient {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
  ._full_display_form_client .searchNomClient {
    width: 250px !important;
    min-width: 250px !important;
    max-width: 250px !important;
  }
  ._full_display_form_client button {
    width: 80%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  ._full_display_form_client .overButton {
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

@media (max-width: 330px) {
  ._nom_cheval_media {
    max-width: 200px;
  }
}
@media (min-width: 800px) {
  ._nom_cheval_media {
    max-width: 200px;
  }
}

._resize_client_btn > div {
  width: 23px;
}
@media (max-width: 800px) {
  ._resize_client_btn > div {
    width: 45% !important;
    min-width: 80px;
    max-width: 90px;
  }
}

@media (max-width: 800px) {
  .removePlSelect select {
    padding-left: 0px !important !important;
    margin-left: 0px !important !important;
  }
}

#_lateFactContainer ._lateFact {
  opacity: 0.7;
  cursor: pointer;
}
#_lateFactContainer ._lateFact .bi-check-circle-fill {
  display: none;
}
#_lateFactContainer ._lateFact .bi-circle {
  display: block;
}
@media (hover: hover) and (pointer: fine) {
  #_lateFactContainer ._lateFact:hover {
    opacity: 1 !important;
    cursor: pointer;
  }
  #_lateFactContainer ._lateFact:hover p, #_lateFactContainer ._lateFact:hover .bi, #_lateFactContainer ._lateFact:hover small {
    color: #566CF0 !important;
  }
  #_lateFactContainer ._lateFact:hover .bi-check-circle-fill {
    display: block !important;
  }
  #_lateFactContainer ._lateFact:hover .bi-circle {
    display: none !important;
  }
}
#_lateFactContainer ._lateFact_active {
  opacity: 1 !important;
  cursor: pointer;
}
#_lateFactContainer ._lateFact_active p, #_lateFactContainer ._lateFact_active .bi, #_lateFactContainer ._lateFact_active small {
  color: #566CF0 !important;
}
#_lateFactContainer ._lateFact_active .bi-check-circle-fill {
  display: block !important;
}
#_lateFactContainer ._lateFact_active .bi-circle {
  display: none !important;
}

#testimonyContainer {
  overflow-x: auto;
  padding-right: 25%;
  height: 50vh;
  min-height: 290px;
}
@media (max-width: 800px) {
  #testimonyContainer {
    height: 66vh;
    min-height: 340px;
    min-width: 100% !important;
  }
}
@media (max-width: 321px) {
  #testimonyContainer {
    height: 90vh;
    min-height: 430px;
    overflow-y: hidden;
    min-width: 100% !important;
  }
}
#testimonyContainer .underTest {
  min-width: 340px;
  max-width: 500px;
}
@media (max-width: 330px) {
  #testimonyContainer .underTest {
    min-width: 280px !important;
    width: 280px !important;
    max-width: 280px !important;
  }
}
#testimonyContainer .activeTestimony {
  width: 45%;
  min-width: 45% !important;
  margin-left: 20px;
  margin-right: 20px;
}
@media (max-width: 1150px) {
  #testimonyContainer .activeTestimony {
    width: 45% !important;
    min-width: 450px !important;
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
}
@media (max-width: 800px) {
  #testimonyContainer .activeTestimony {
    width: 85% !important;
    min-width: 85% !important;
    margin-right: 80px !important;
  }
}
#testimonyContainer .inactiveTestimony {
  width: 45%;
  min-width: 45% !important;
  opacity: 0.4;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
  transition: 0.9s ease-in-out;
}
@media (max-width: 1150px) {
  #testimonyContainer .inactiveTestimony {
    width: 45% !important;
    min-width: 450px !important;
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
}
@media (max-width: 800px) {
  #testimonyContainer .inactiveTestimony {
    width: 85% !important;
    min-width: 85% !important;
    margin-right: 100px !important;
  }
}
#testimonyContainer .inactiveTestimony:hover {
  opacity: 1 !important;
}
#testimonyContainer .quote1, #testimonyContainer .quote2 {
  opacity: 0.2;
}
#testimonyContainer .quote2 {
  transform: rotate(180deg);
}

.goTest {
  cursor: pointer;
}

#modeContainer {
  /* Switch Sizes */
  /*--------- END --------*/
}
#modeContainer .toggleSwitch span span {
  display: none;
}
#modeContainer .toggleSwitch {
  display: inline-block;
  height: 18px;
  position: relative;
  overflow: visible;
  padding: 0;
  margin-left: 50px;
  cursor: pointer;
  width: 40px;
}
#modeContainer .toggleSwitch * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#modeContainer .toggleSwitch label,
#modeContainer .toggleSwitch > span {
  line-height: 20px;
  height: 20px;
  vertical-align: middle;
}
#modeContainer .toggleSwitch input:focus ~ a,
#modeContainer .toggleSwitch input:focus + label {
  outline: none;
}
#modeContainer .toggleSwitch label {
  position: relative;
  z-index: 3;
  display: block;
  width: 100%;
}
#modeContainer .toggleSwitch input {
  position: absolute;
  opacity: 0;
  z-index: 5;
}
#modeContainer .toggleSwitch > span {
  position: absolute;
  left: -50px;
  width: 100%;
  margin: 0;
  padding-right: 50px;
  text-align: left;
  white-space: nowrap;
}
#modeContainer .toggleSwitch > span span {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: block;
  width: 50%;
  margin-left: 50px;
  text-align: left;
  font-size: 0.9em;
  width: 100%;
  left: 15%;
  top: -1px;
  opacity: 0;
}
#modeContainer .toggleSwitch a {
  position: absolute;
  right: 50%;
  z-index: 4;
  display: block;
  height: 100%;
  padding: 0;
  left: 2px;
  width: 18px;
  margin-top: -1px;
  background-color: #fff;
  border: 1px solid #CCC;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
#modeContainer .toggleSwitch > span span:first-of-type {
  color: blue;
  opacity: 1;
  left: 45%;
}
#modeContainer .toggleSwitch > span:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50px;
  top: -2px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 30px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
#modeContainer .toggleSwitch input:checked ~ a {
  border-color: #fff;
  left: 100%;
  margin-left: -8px;
  margin-top: -1px;
}
#modeContainer .toggleSwitch input:checked ~ span:before {
  border-color: #566CF0;
  box-shadow: inset 0 0 0 30px #566CF0;
}
#modeContainer .toggleSwitch input:checked ~ span span:first-of-type {
  opacity: 0;
}
#modeContainer .toggleSwitch input:checked ~ span span:last-of-type {
  opacity: 1;
  color: #fff;
}
#modeContainer .toggleSwitch.large {
  width: 60px;
  height: 27px;
}
#modeContainer .toggleSwitch.large a {
  width: 27px;
}
#modeContainer .toggleSwitch.large > span {
  height: 29px;
  line-height: 28px;
}
#modeContainer .toggleSwitch.large input:checked ~ a {
  left: 41px;
}
#modeContainer .toggleSwitch.large > span span {
  font-size: 1.1em;
}
#modeContainer .toggleSwitch.large > span span:first-of-type {
  left: 50%;
}
@media (max-width: 800px) {
  #modeContainer .toggleSwitch {
    margin-left: 14px;
  }
}

._resize_gear_fact {
  font-size: 1.7rem;
}
@media (max-width: 800px) {
  ._resize_gear_fact {
    font-size: 1.2rem;
  }
}

.hover-me-i:hover {
  color: #566CF0 !important;
}

._title_part_resize_media {
  font-size: 0.9rem;
}
@media (max-width: 800px) {
  ._title_part_resize_media {
    font-size: 0.9rem;
  }
}

._resize_price_fact {
  font-size: 0.8rem;
}
@media (max-width: 800px) {
  ._resize_price_fact {
    font-size: 0.75rem;
  }
}

.filter_fact_size {
  min-width: 380px;
  max-width: 430px;
  width: 30%;
}
@media (max-width: 800px) {
  .filter_fact_size {
    min-width: 100%;
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 350px) {
  .resize_dropdown_SE {
    min-width: 300px;
    max-width: 300px;
    overflow-x: hidden;
  }
}

.remove-dropdown-toggle::after {
  content: none !important;
}

.shimmerEffect .shine {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}
.shimmerEffect box {
  height: 54px;
  min-width: 30px;
  width: 100%;
}
.shimmerEffect div {
  display: inline-flex;
  flex-direction: column;
  margin-left: 25px;
  margin-top: 15px;
  vertical-align: top;
}
.shimmerEffect lines {
  height: 10px;
  margin-top: 10px;
  min-width: 30px;
  width: 100%;
}

@-webkit-keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.bg-rendezvous-perso {
  background: repeating-linear-gradient(45deg, #F2F2F2, #F2F2F2 10px, #E6E6E6 10px, #E6E6E6 20px);
}

.bgPGreen {
  background: repeating-linear-gradient(45deg, #CEE7DB, #CEE7DB 10px, #C0DFC9 10px, #C0DFC9 20px);
}

.bgPRed {
  background: repeating-linear-gradient(45deg, #F8BDAD, #F8BDAD 10px, #F9C7BD 10px, #F9C7BD 20px);
}

.bgPBlue {
  background: repeating-linear-gradient(45deg, #DDD7EC, #DDD7EC 10px, #E7E1F1 10px, #E7E1F1 20px);
}

.hover-p-primary:hover p {
  color: #566CF0 !important;
}

.notCheckedColor {
  opacity: 0.5;
  cursor: pointer;
}
.notCheckedColor .bi {
  display: none;
}

.notCheckedColor:hover {
  opacity: 1;
}

.checkedColor {
  opacity: 1;
  cursor: pointer;
}
.checkedColor .bi {
  display: block;
}

.transparentInput input {
  background-color: transparent !important;
  border-color: transparent !important;
  font-size: 1.2rem;
}
.transparentInput input :focus {
  border-color: transparent !important;
}

.smallInput input {
  width: 60px;
  max-width: 70px;
}

.noPaddingInput input {
  padding-left: 0px;
}

._resize_card_pay {
  max-width: 100%;
  width: 400px;
  max-height: 300px;
}
@media (max-width: 700px) {
  ._resize_card_pay {
    max-width: 90%;
    width: 250px;
  }
}

._resize_card_pay3 {
  max-width: 100%;
  width: 400px;
  max-height: 300px;
}
@media (max-width: 700px) {
  ._resize_card_pay3 {
    max-width: 100%;
    min-width: 80%;
  }
}

._resize_card_pay2 {
  min-width: 100px;
  height: auto;
  width: 130px;
  max-width: 220px;
}
@media (min-width: 900px) and (max-width: 1200px) {
  ._resize_card_pay2 {
    min-width: 70px;
    height: auto;
    width: 90px;
    max-width: 90px;
  }
}
@media (max-width: 700px) {
  ._resize_card_pay2 {
    max-width: 90%;
    width: 200px;
    max-width: 210px;
  }
}

@media (max-width: 720px) {
  .media_background_color_success {
    background-color: rgba(85, 210, 188, 0.5215686275);
  }
}

@media (max-width: 720px) {
  ._media_min_height_payment_success {
    min-height: 50vh;
  }
}

@media (max-width: 720px) {
  ._media_min_height_depot_success {
    min-height: 40vh;
  }
}

@media (max-width: 720px) {
  .media_background_color_warning {
    background-color: rgba(244, 188, 68, 0.5215686275);
  }
}

.border-draw {
  border: 2px solid transparent;
  animation: border 2s 1s infinite, myBorderColor 2s 1s infinite;
}

@keyframes myBorderColor {
  0% {
    border-bottom-color: #ff9f1c;
    border-left-color: #ff9f1c;
  }
  50% {
    border-bottom-color: #ff9f1c;
    border-left-color: #ff9f1c;
  }
  51% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  100% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}
.goTo_link {
  cursor: pointer;
  opacity: 0.5;
}
.goTo_link.goTo_link_active {
  opacity: 1;
}

@media (min-width: 1020px) {
  .bg-lg-white {
    background-color: white;
  }
}

.showMdPayColumn {
  position: fixed;
  background-color: white;
}
@media (max-width: 995px) {
  .showMdPayColumn {
    position: static;
    background-color: transparent;
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 995px) {
  .stats_payment {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

._width_square4_stats {
  min-width: 130px;
}
@media (max-width: 995px) {
  ._width_square4_stats {
    min-width: 100px;
    max-width: 270px;
  }
}

._sm-text-white h5, ._sm-text-white li, ._sm-text-white p, ._sm-text-white a {
  color: #2A3465;
}
@media (max-width: 768px) {
  ._sm-text-white h5, ._sm-text-white li, ._sm-text-white p, ._sm-text-white a {
    color: white !important;
  }
}

.bg-sm-primary {
  background-color: transparent;
}
@media (max-width: 768px) {
  .bg-sm-primary {
    background-color: #5656E7;
  }
}

.banner_mes_rdvs {
  height: auto;
}
@media (max-width: 768px) {
  .banner_mes_rdvs {
    min-height: 230px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    margin-bottom: 30px !important;
  }
}

._MT_dashboard_media {
  margin-top: 0px;
}
@media (max-width: 768px) {
  ._MT_dashboard_media {
    margin-top: -180px;
  }
}

._my_media_IMG2L {
  max-width: 400px;
  width: 100%;
}
@media (max-width: 768px) {
  ._my_media_IMG2L {
    max-width: 98% !important;
    width: 98%;
  }
}

._media_min_w_300 {
  min-width: 300px;
  max-width: 300px;
}
@media (max-width: 778px) {
  ._media_min_w_300 {
    min-width: 100%;
  }
}

._hide_sm {
  display: inline;
}
@media (max-width: 778px) {
  ._hide_sm {
    display: none;
  }
}

._hide_md {
  display: none;
}
@media (max-width: 778px) {
  ._hide_md {
    display: inline;
  }
}

._sidebar_planning_padding {
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 700px) {
  ._sidebar_planning_padding {
    padding-left: 20%;
    padding-right: 20%;
  }
}

.buttonAnimation .draw {
  overflow: hidden;
  position: relative;
}
.buttonAnimation .draw::before, .buttonAnimation .draw::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  border: 2px solid transparent;
  width: 0;
  height: 0;
}
.buttonAnimation .draw::before {
  top: 0;
  left: 0;
  border-top-color: #ff9f1c;
  border-right-color: #ff9f1c;
  animation: border 2s infinite;
}
.buttonAnimation .draw-success::before {
  top: 0;
  left: 0;
  border-top-color: #55D2BC !important;
  border-right-color: #55D2BC !important;
  animation: border 2s infinite;
}
.buttonAnimation .draw-danger::before {
  top: 0;
  left: 0;
  border-top-color: #FF8195 !important;
  border-right-color: #FF8195 !important;
  animation: border 2s infinite;
}
.buttonAnimation .draw::after {
  bottom: 0;
  right: 0;
  animation: border 2s 1s infinite, borderColor 2s 1s infinite;
}
.buttonAnimation .draw-success::after {
  bottom: 0;
  right: 0;
  animation: border 2s 1s infinite, borderColorGreen 2s 1s infinite;
}
.buttonAnimation .draw-danger::after {
  bottom: 0;
  right: 0;
  animation: border 2s 1s infinite, borderColorNewDanger 2s 1s infinite;
}
@keyframes border {
  0% {
    width: 0;
    height: 0;
  }
  25% {
    width: 100%;
    height: 0;
  }
  50% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 100%;
    height: 100%;
  }
}
@keyframes borderColor {
  0% {
    border-bottom-color: #ff9f1c;
    border-left-color: #ff9f1c;
  }
  50% {
    border-bottom-color: #ff9f1c;
    border-left-color: #ff9f1c;
  }
  51% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  100% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}
@keyframes borderColorNewDanger {
  0% {
    border-bottom-color: #FF8195;
    border-left-color: #FF8195;
  }
  50% {
    border-bottom-color: #FF8195;
    border-left-color: #FF8195;
  }
  51% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  100% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}
@keyframes borderColorGreen {
  0% {
    border-bottom-color: #55D2BC;
    border-left-color: #55D2BC;
  }
  50% {
    border-bottom-color: #55D2BC;
    border-left-color: #55D2BC;
  }
  51% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  100% {
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
}

.noMarginUnderSelect select {
  margin-left: 0px !important;
  margin-right: 4px !important;
}

.right-inner-addon {
  position: relative;
}

.right-inner-addon input {
  padding-right: 35px !important;
}

.right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 12px 12px;
  margin-top: -7px !important;
  opacity: 0.4;
  pointer-events: none;
}

.right-inner-addon svg {
  position: absolute;
  right: 0px;
  padding: 2px 2px;
  margin-top: 8px !important;
  margin-right: 5px !important;
  opacity: 0.4;
  pointer-events: none;
}

.right-inner-addon-devis {
  position: relative;
}

.right-inner-addon-devis input {
  padding-right: 35px !important;
  width: 80%;
}

.right-inner-addon-devis i {
  position: absolute;
  right: 0px;
  padding: 12px 12px;
  margin-top: -7px !important;
  opacity: 0.4;
  pointer-events: none;
}

.right-inner-addon-devis #form_options_remise_montant {
  max-width: 90px !important;
}

.right-inner-addon-devis svg {
  position: absolute;
  left: 0px;
  padding: 2px 2px;
  margin-top: 10px !important;
  margin-left: 67px !important;
  opacity: 0.4;
  pointer-events: none;
}
@media (max-width: 700px) {
  .right-inner-addon-devis svg {
    margin-top: 16px !important;
    left: 0px !important;
    margin-left: 67px;
  }
}

._setRightMobile {
  left: 0;
  margin-right: auto;
  position: absolute;
  top: 0;
  font-size: 0.9rem;
}
@media (max-width: 778px) {
  ._setRightMobile {
    right: 0;
    left: auto;
    margin-left: auto;
    margin-right: 10px;
    margin-top: 5px;
    font-size: 1.2rem;
  }
}

._menu_invoicing {
  max-width: 400px;
  margin-bottom: 30px;
  center: 0;
  bottom: 0;
}
@media (max-width: 778px) {
  ._menu_invoicing {
    width: 102%;
    max-width: 102%;
    margin-bottom: 0px;
  }
}

._menu_invoicing2 {
  margin-top: -30px;
  background-color: #2A3465;
}
@media (max-width: 778px) {
  ._menu_invoicing2 {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
    background-color: rgba(243, 243, 253, 0.6666666667) !important;
    border-radius: 0px !important;
  }
  ._menu_invoicing2 button {
    border: 1px solid #566CF0;
  }
  ._menu_invoicing2 .btn-light {
    background-color: rgba(243, 243, 253, 0.9803921569) !important;
  }
}

#containerInvoicing {
  background-image: url("../../public/icones/fond_facture.svg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  max-height: 100vh;
}
#containerInvoicing #list {
  max-height: 80vh;
}
#containerInvoicing ._content_height {
  min-height: 70vh;
  background-color: white;
}
@media (max-width: 991px) {
  #containerInvoicing {
    max-height: none !important;
    background-repeat: no-repeat;
    background-size: contain;
  }
  #containerInvoicing #list {
    max-height: none !important;
  }
  #containerInvoicing ._content_height {
    min-height: 10vh;
    padding-bottom: 50px !important;
  }
}
@media (max-width: 768px) {
  #containerInvoicing {
    background-image: none !important;
  }
  #containerInvoicing ._content_height {
    box-shadow: none !important;
    background-color: rgba(234, 232, 240, 0.6666666667) !important;
  }
  #containerInvoicing #list {
    background-color: white !important;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    margin-top: -40px !important;
    max-height: none !important;
    min-height: 500px !important;
  }
}

._hover_animation_invoicing .opacity_animate {
  opacity: 0.8;
}

._hover_animation_invoicing:hover .opacity_animate {
  opacity: 1;
}

@media (max-width: 768px) {
  ._media_text_resize_08 {
    font-size: 0.8rem;
    font-weight: 600 !important;
  }
}

@media (max-width: 768px) {
  ._media_text_resize_07 {
    font-size: 0.66rem !important;
  }
}

@media (max-width: 768px) {
  ._media_text_resize_075 {
    font-size: 0.72rem !important;
  }
}

.checkbox_contain input[type=checkbox] {
  min-width: 10px;
  min-height: 10px;
}

._max_height_options_invoicing {
  max-height: 80vh;
}
@media (max-width: 768px) {
  ._max_height_options_invoicing {
    max-height: 90vh;
  }
}

.lottie_animation {
  width: 370px;
  height: 370px;
}
@media (max-width: 768px) {
  .lottie_animation {
    width: 100%;
    min-width: 250px;
    height: 250px;
  }
}

.tabStats {
  border-color: transparent !important;
}
.tabStats .nav-link {
  background-color: transparent !important;
  color: white;
  opacity: 0.7;
  font-size: 0.9rem;
}
.tabStats .nav-link.active {
  background-color: transparent !important;
  color: #00B38F !important;
  opacity: 1 !important;
  border-color: transparent !important;
  font-weight: bold;
}
.tabStats .nav-link:hover {
  border-color: transparent !important;
  color: #00B38F !important;
  opacity: 1 !important;
}

.dashiell {
  font-family: "DM Serif Display", serif;
  font-weight: 400;
  font-style: normal;
}

._resize_card_stat_mp {
  min-width: 290px;
}
@media (max-width: 1245px) and (min-width: 993px) {
  ._resize_card_stat_mp {
    min-width: 100%;
    max-width: 100%;
  }
}

.bg_stat_more {
  background-image: url("../../public/icones/bg_stat_more.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

._media_more_horses {
  min-height: 70vh;
  max-height: 70vh;
}
@media (max-width: 945px) {
  ._media_more_horses {
    min-height: 72vh;
    max-height: 72vh;
  }
}

._media_more_horses2 {
  min-height: 75vh;
  max-height: 75vh;
}
@media (max-width: 945px) {
  ._media_more_horses2 {
    min-height: 82vh;
    max-height: 82vh;
  }
}

._media_more_horses3 {
  min-height: 67vh;
  max-height: 67vh;
  overflow-y: auto;
}
@media (max-width: 945px) {
  ._media_more_horses3 {
    min-height: 72vh;
    max-height: 72vh;
    overflow-y: auto;
  }
}

.pac-matched {
  color: #566CF0 !important;
  font-weight: 500 !important;
}

.pac-icon {
  display: none !important;
}

.pac-item-query {
  color: #2A3465 !important;
}

.pac-item {
  color: rgba(42, 52, 101, 0.662745098);
}

._bg_did_you_know {
  background: rgb(80, 56, 244);
  background: -moz-linear-gradient(195deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
  background: -webkit-linear-gradient(195deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
  background: linear-gradient(195deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
}

._margin_top_banner_galerie {
  margin-top: -420px;
}
@media (max-width: 645px) {
  ._margin_top_banner_galerie {
    min-height: 120px;
  }
}

._nav_pro p {
  color: #2A3465;
}

.nav_pro ._active_onglet {
  color: #566CF0;
}

._nav_pro p:hover {
  color: #566CF0;
}

.bg_devis_blue {
  background-image: url("../../public/icones/devis/_bg_blue.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

#bg_horse {
  background-image: url("../../public/icones/background_horse.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.bg_horse {
  background-image: url("../../public/icones/background_horse.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 445px) {
  ._infos_devis_media {
    margin-left: -15px !important;
  }
}

._hover_devis:hover {
  background-color: rgba(234, 233, 255, 0.2666666667);
}

@media (max-width: 610px) {
  ._hover_devis {
    border-bottom: 0.3px solid rgba(226, 226, 226, 0.9058823529);
  }
}

@media (max-width: 1310px) {
  ._media_devis {
    min-width: 100% !important;
  }
}

.customized-tab p {
  font-weight: 400;
  opacity: 0.6;
  font-size: 0.7rem;
}
.customized-tab p.active {
  font-weight: 500 !important;
  opacity: 1;
}

._media_font_size_devis_state {
  font-size: 0.7rem;
}
@media (max-width: 1310px) {
  ._media_font_size_devis_state {
    font-size: 0.6rem;
  }
}

._hover_override:hover p {
  color: white !important;
}

.tox-menubar span {
  font-weight: 400;
  font-size: 0.8rem;
  color: #2A3465;
}

.bg-light-primary {
  background-color: rgba(86, 108, 240, 0.0666666667);
}

.bg-light-bluedark {
  background-color: rgba(42, 52, 101, 0.0666666667);
}

.bg-light-real-green {
  background-color: rgba(85, 210, 188, 0.0666666667);
}

._bg_today_1 {
  background-image: url("../../public/icones/decors_today_full.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

._bg_today_2 {
  background-image: url("../../public/icones/decors_today_full_orange.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.color_orange_today {
  color: #FF7B31;
}

@media (max-width: 810px) {
  ._mt_today_media {
    padding-top: 30px !important;
  }
}

._width_square_today {
  width: 47%;
}
@media (max-width: 1210px) {
  ._width_square_today {
    max-width: 145px;
    width: 47%;
  }
}
@media (max-width: 710px) {
  ._width_square_today {
    width: 45%;
  }
}
@media (max-width: 410px) {
  ._width_square_today {
    width: 46%;
  }
}

.personnalizedForm input, .personnalizedForm textarea {
  color: #2A3465;
  font-weight: 400;
  padding: 21px;
  font-size: 0.9rem;
  background-color: rgba(42, 52, 101, 0.0666666667);
  border: 0px solid transparent;
}
.personnalizedForm input::placeholder, .personnalizedForm textarea::placeholder {
  opacity: 0.3 !important;
  color: #2A3465;
  font-weight: 400 !important;
  font-size: 0.9rem;
}
.personnalizedForm input:focus, .personnalizedForm textarea:focus {
  background-color: rgba(42, 52, 101, 0.0666666667) !important;
}

.personnalizedForm label {
  color: #2A3465;
  font-size: 0.8rem;
  font-weight: 500;
}

.personnalizedForm .bi {
  position: absolute;
  margin-left: -26px;
}

.personnalizedForm ._icons {
  max-width: 0px !important;
}

.personnalizedFormWhite input, .personnalizedFormWhite textarea {
  color: white;
  background-color: rgba(255, 255, 255, 0.1215686275);
  border: 1px solid rgba(255, 255, 255, 0.3098039216);
}
.personnalizedFormWhite input::placeholder, .personnalizedFormWhite textarea::placeholder {
  color: rgba(255, 255, 255, 0.1647058824);
}
.personnalizedFormWhite input:focus, .personnalizedFormWhite textarea:focus {
  background-color: rgba(255, 255, 255, 0.1215686275) !important;
}
.personnalizedFormWhite input:read-only, .personnalizedFormWhite textarea:read-only {
  background-color: rgba(255, 255, 255, 0.1215686275);
  border: 1px solid rgba(255, 255, 255, 0.3098039216);
}

.choicesRegisterType .type {
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.choicesRegisterType .type p {
  color: #2A3465;
}

.choicesRegisterType .type:hover p {
  color: #566CF0 !important;
}

.under-div-50px > div {
  min-width: 20px !important;
  max-width: 20px !important;
}

.landing_ib p, .landing_ib img {
  display: inline-block;
}

._mr_ml_land {
  margin-left: 7%;
  margin-right: 7%;
}
@media (max-width: 1110px) {
  ._mr_ml_land {
    margin-left: 1%;
    margin-right: 1%;
  }
}
@media (max-width: 910px) {
  ._mr_ml_land {
    margin-left: 1%;
    margin-right: 0%;
  }
}

._land_1 {
  margin-right: -300px;
}
._land_1 ._deworm_land {
  width: 80px;
  min-height: 30px;
}
@media (max-width: 1110px) {
  ._land_1 {
    margin-right: -460px;
  }
}
@media (max-width: 993px) {
  ._land_1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    min-width: 460px !important;
    max-width: 490px !important;
  }
}
@media (max-width: 753px) {
  ._land_1 {
    min-width: 660px !important !important;
    max-width: 660px !important;
    margin-left: 40px;
  }
  ._land_1 .remind_land {
    margin-left: 20px !important;
    min-width: 170px;
    max-width: 170px;
  }
  ._land_1 ._deworm_land {
    width: 50px;
    max-width: 60px !important;
    max-height: 25px !important;
  }
  ._land_1 ._deworm_land image, ._land_1 ._deworm_land svg {
    max-width: 60px !important;
    max-height: 25px !important;
  }
  ._land_1 ._pay_meth_land image, ._land_1 ._pay_meth_land svg {
    max-height: 85px !important;
  }
}

._land_2 {
  margin-right: -40px;
}
@media (max-width: 1110px) {
  ._land_2 {
    margin-left: 130px;
    min-width: 460px !important;
  }
}
@media (max-width: 993px) {
  ._land_2 {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    min-width: 460px !important;
  }
  ._land_2 h1, ._land_2 p, ._land_2 h2, ._land_2 form {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (max-width: 653px) {
  ._land_2 {
    margin-left: -20px;
    margin-right: auto;
    margin-top: 10px;
    min-width: 260px !important;
  }
  ._land_2 h1, ._land_2 p, ._land_2 h2, ._land_2 form {
    text-align: left !important;
    margin-left: 0px !important;
    margin-right: auto !important;
  }
}

._land_2_pro {
  margin-right: -40px;
  margin-top: -50px;
}
@media (max-width: 1110px) {
  ._land_2_pro {
    margin-left: 130px;
    min-width: 460px !important;
  }
}
@media (max-width: 993px) {
  ._land_2_pro {
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    min-width: 460px !important;
  }
  ._land_2_pro h1, ._land_2_pro p, ._land_2_pro h2, ._land_2_pro form {
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@media (max-width: 653px) {
  ._land_2_pro {
    margin-left: -20px;
    margin-right: auto;
    margin-top: 10px;
    min-width: 260px !important;
  }
  ._land_2_pro h1, ._land_2_pro p, ._land_2_pro h2, ._land_2_pro form {
    text-align: left !important;
    margin-left: 0px !important;
    margin-right: auto !important;
  }
}

._ml_land_2 {
  margin-left: -200px;
}
._ml_land_2 ._w_pay_land {
  width: 230px;
  border-radius: 10px;
}
@media (max-width: 992px) {
  ._ml_land_2 {
    margin-left: -15px !important;
  }
}
@media (max-width: 753px) {
  ._ml_land_2 {
    margin-left: -25px !important;
  }
}
@media (max-width: 653px) {
  ._ml_land_2 {
    margin-left: -25px !important;
  }
  ._ml_land_2 ._w_pay_land {
    width: 170px;
    border-radius: 5px;
  }
}

._ml_land_2_pro {
  margin-left: -200px;
}
._ml_land_2_pro ._w_pay_land {
  width: 230px;
  border-radius: 10px;
}
@media (max-width: 992px) {
  ._ml_land_2_pro {
    margin-left: -180px !important;
  }
}
@media (max-width: 753px) {
  ._ml_land_2_pro {
    margin-left: -50px !important;
  }
}
@media (max-width: 653px) {
  ._ml_land_2_pro {
    margin-left: -46px !important;
  }
  ._ml_land_2_pro ._w_pay_land {
    width: 170px;
    border-radius: 5px;
  }
}

._ml_land_3 {
  margin-left: -200px;
}
._ml_land_3 ._w_pay_land {
  border-radius: 10px;
  width: 260px;
  height: 160px;
}
@media (max-width: 992px) {
  ._ml_land_3 {
    margin-left: -15px !important;
  }
}
@media (max-width: 753px) {
  ._ml_land_3 {
    margin-left: -115px !important;
  }
}
@media (max-width: 653px) {
  ._ml_land_3 {
    margin-left: -125px !important;
  }
  ._ml_land_3 ._w_pay_land {
    width: 180px !important;
    height: 120px !important;
    border-radius: 5px;
  }
}

._img_phone_land {
  border: 10px solid white;
  height: 470px;
  width: 230px;
  border-radius: 25px;
}
@media (max-width: 753px) {
  ._img_phone_land {
    width: 230px !important;
    height: 400px !important;
    min-width: 150px !important;
    min-height: 310px !important;
    max-width: 150px !important;
    max-height: 310px !important;
    border: 5px solid white !important;
    border-radius: 15px;
    margin-left: 30px;
    -webkit-box-shadow: 1px 5px 17px 3px rgba(0, 0, 0, 0.09);
    box-shadow: 1px 5px 17px 3px rgba(0, 0, 0, 0.09);
  }
  ._img_phone_land p {
    font-size: 0.4rem !important !important;
  }
  ._img_phone_land ._phone_land {
    width: 50px !important;
    min-width: 50px !important;
    min-height: 50px !important;
    height: 50px !important;
    border-radius: 3px;
    margin-top: 10px;
  }
  ._img_phone_land ._horse_head_land svg {
    height: 40px;
    max-height: 40px;
  }
  ._img_phone_land ._bg_did_you_know {
    min-height: 70px !important;
  }
}
._img_phone_land ._phone_land {
  width: 80px;
  min-width: 80px;
  min-height: 80px;
  height: 80px;
  border-radius: 10px;
}
._img_phone_land ._bg_did_you_know {
  min-height: 90px;
}

._img_phone_land_no_media {
  border: 10px solid white;
  height: 470px;
  width: 230px;
  border-radius: 25px;
}
._img_phone_land_no_media ._phone_land {
  width: 80px;
  min-width: 80px;
  min-height: 80px;
  height: 80px;
  border-radius: 10px;
}
._img_phone_land_no_media ._bg_did_you_know {
  min-height: 90px;
}

@media (max-width: 753px) {
  ._ml_suivi_land {
    margin-left: -40px !important;
  }
}

@media (max-width: 653px) {
  ._ponies_land svg {
    max-width: 380px;
    margin-left: 20px;
    margin-top: -20px;
  }
}

._files_land {
  margin-left: auto;
}
@media (max-width: 653px) {
  ._files_land {
    margin-left: -70px !important;
  }
}

@media (max-width: 653px) {
  .w_doc_land {
    max-width: 200px;
  }
}

._arrow_land {
  left: 0;
  margin-top: 290px;
  min-width: 100px;
  min-height: 30px;
  margin-left: -30px;
}
@media (max-width: 1253px) {
  ._arrow_land {
    margin-left: -4%;
    margin-top: 320px;
  }
}
@media (max-width: 1093px) {
  ._arrow_land {
    margin-left: -8%;
    margin-top: 320px;
  }
}
@media (max-width: 1024px) {
  ._arrow_land {
    margin-left: -9%;
    margin-top: 340px;
  }
}
@media (max-width: 991px) {
  ._arrow_land {
    margin-left: -40px;
    margin-top: 220px;
  }
}

.mastercard-icon {
  display: inline-block;
  height: 1em;
  isolation: isolate;
  position: relative;
  line-height: 1em;
  vertical-align: middle;
  width: 1.611em;
}

.mastercard-icon::before,
.mastercard-icon::after {
  border-radius: 0.5em;
  content: "";
  display: inline-block;
  height: 1em;
  position: absolute;
  width: 1em;
}

.mastercard-icon::before {
  background-color: #eb001b;
  left: 0;
  top: 0;
}

.mastercard-icon::after {
  background-color: #f79e1b;
  mix-blend-mode: hard-light;
  right: 0;
  top: 0;
}

@media (max-width: 753px) {
  ._resize_cards svg {
    width: 90px;
    height: 30px;
  }
}

@media (max-width: 753px) {
  ._resize_bg_gradient_land svg {
    max-width: 400px;
  }
}

.content_ml_land_pay {
  margin-left: 0px;
}
@media (max-width: 753px) {
  .content_ml_land_pay {
    margin-left: -90px !important;
  }
}

._prof_object_width {
  width: 666px;
}
@media (max-width: 690px) {
  ._prof_object_width {
    width: 80% !important;
  }
}

._prof_object_width_3 {
  width: 666px;
}
@media (max-width: 690px) {
  ._prof_object_width_3 {
    width: 86% !important;
  }
}

._prof_object_width_2 {
  width: 330px;
}
@media (max-width: 690px) {
  ._prof_object_width_2 {
    width: 80% !important;
  }
}

#bg-header-app {
  background-image: url("../../public/icones/diag-primary-bg.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

._w_add_app {
  min-width: 430px;
  max-width: 430px;
}
@media (max-width: 1090px) {
  ._w_add_app {
    min-width: 430px;
    max-width: 430px;
  }
}
@media (max-width: 769px) {
  ._w_add_app {
    min-width: 470px;
    max-width: 470px;
  }
}
@media (max-width: 470px) {
  ._w_add_app {
    min-width: 100%;
    max-width: 100%;
  }
}

._container_w_add_app {
  min-width: 400px;
  left: 0;
  width: 100%;
  max-width: 100%;
}
@media (max-width: 1090px) {
  ._container_w_add_app {
    min-width: 400px;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 769px) {
  ._container_w_add_app {
    min-width: 400px;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 470px) {
  ._container_w_add_app {
    min-width: 300px;
    max-width: 100%;
  }
}

.main-form-app {
  margin-top: -65px;
}
@media (max-width: 410px) {
  .main-form-app {
    margin-top: -50px;
  }
}
@media (max-width: 1430px) and (min-height: 1100px) {
  .main-form-app {
    margin-top: -120px;
  }
}

.correctDP a {
  background-color: transparent !important;
  border-color: transparent !important;
  text-align: center !important;
}
.correctDP a.active {
  padding-right: 9px !important !important;
}
.correctDP .ui-datepicker-header {
  min-height: 40px !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
.correctDP .ui-datepicker-title {
  padding-top: 20px !important;
  margin-left: 40px;
}

#addAppDatepicker #ui-datepicker-div {
  display: none;
  background-color: #fff;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
#addAppDatepicker table {
  border-collapse: collapse;
  border-spacing: 0;
}
#addAppDatepicker .ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #78909C;
}
#addAppDatepicker .ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
#addAppDatepicker .ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #546E7A;
  font-size: 0.875rem;
  text-decoration: none;
  background-color: transparent !important;
  text-align: center;
  border: 0px solid transparent;
}
#addAppDatepicker .ui-datepicker-calendar tbody td a:hover {
  background-color: #EAECFF;
}
#addAppDatepicker .ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: #566CF0 !important;
  color: white;
  padding: 0 0 !important;
  border: 0px solid transparent !important;
}
#addAppDatepicker .ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}
#addAppDatepicker .ui-datepicker-header a.ui-corner-all:hover {
  background-color: #EAECFF;
}
#addAppDatepicker .ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.8rem;
  background-position: 50%;
  transform: rotate(180deg);
}
#addAppDatepicker .ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.8rem;
  background-position: 50%;
}
#addAppDatepicker .ui-datepicker-header a > span {
  display: none;
}
#addAppDatepicker .ui-datepicker-title {
  text-align: center;
  line-height: 1rem;
  margin-bottom: 0rem;
  font-size: 0.775rem;
  font-weight: 500;
  padding-bottom: 0rem;
  font-weight: 400 !important;
  margin-top: 0px;
}
#addAppDatepicker .ui-datepicker-week-col {
  color: #78909C;
  font-weight: 400;
  font-size: 0.75rem;
}

#reconductDatePicker .ui-datepicker-header a.ui-datepicker-next, #reconductDatePicker .ui-datepicker-header a.ui-datepicker-prev {
  margin-top: 4px;
}
#reconductDatePicker .ui-datepicker-header {
  background-color: transparent !important;
  min-height: 40px;
  border: 0px solid transparent;
}
#reconductDatePicker .ui-datepicker-title {
  margin-top: 8px;
}
#reconductDatePicker .ui-datepicker-inline {
  border: 0px solid transparent;
}
#reconductDatePicker .ui-datepicker-calendar tbody td a:hover {
  background-color: #EAECFF !important;
}
@media (max-width: 790px) {
  #reconductDatePicker #addAppDatepicker {
    position: absolute !important;
    margin-top: 30px;
    margin-left: -80px;
  }
}

.newSelectHour select {
  max-width: 50px;
  width: 50px;
  margin-left: 5px;
  margin-right: 5px;
}

.h_add_app_horses {
  min-height: 59vh;
  max-height: 59vh;
}
@media (max-width: 1090px) {
  .h_add_app_horses {
    min-height: 55vh;
    max-height: 55vh;
  }
}
@media (max-width: 790px) {
  .h_add_app_horses {
    min-height: 54vh;
    max-height: 54vh;
  }
}
@media (max-width: 490px) {
  .h_add_app_horses {
    min-height: 45vh;
    max-height: 45vh;
  }
}

.h_add_app_horses_multi {
  min-height: 49vh;
  max-height: 49vh;
}
@media (max-width: 410px) {
  .h_add_app_horses_multi {
    min-height: 37vh;
    max-height: 37vh;
  }
}

.tarteaucitronH1 {
  color: #2A3465 !important;
  text-align: left !important;
  font-weight: 500 !important;
}

#tarteaucitronInfo {
  border: 0px solid transparent !important;
  text-align: left !important;
  opacity: 0.7 !important;
  max-width: 100% !important;
}

._max_width_lg_hour {
  max-width: 90px;
}
@media (max-width: 1010px) {
  ._max_width_lg_hour {
    max-width: none;
  }
}

._max_width_options_app {
  max-width: 100px;
}
@media (max-width: 910px) {
  ._max_width_options_app {
    max-width: 50px;
  }
}

#successWaitAddHorse {
  min-width: 300px;
}
@media (max-width: 610px) {
  #successWaitAddHorse {
    min-width: 200px;
  }
}

.gradientUniqueRdv {
  background: rgb(80, 56, 244);
  background: -moz-linear-gradient(20deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
  background: -webkit-linear-gradient(20deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
  background: linear-gradient(20deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5038f4",endColorstr="#9961f6",GradientType=1);
}

.gradientFlottantRdv {
  background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 160%);
}

._monitorHeight {
  min-height: 85vh;
  max-height: 85vh;
}
@media (max-width: 610px) {
  ._monitorHeight {
    min-height: 85vh;
    max-height: 85vh;
  }
}

@media (min-width: 1010px) {
  ._lg_min_w_ch {
    min-width: 700px;
  }
}

.forceActiveDrpdwn {
  display: block;
}

.blocToAdd, .goBloc {
  cursor: pointer;
}

.blocToAdd:hover, .goBloc:hover {
  border-color: #566CF0 !important;
}
.blocToAdd:hover p, .goBloc:hover p {
  color: #566CF0 !important;
}
.blocToAdd:hover i, .goBloc:hover i {
  color: #566CF0 !important;
}

.updateField {
  cursor: pointer;
}

.bg_edit_cr {
  background-image: url("../../public/icones/bg_edit_cr.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.hoverCR:hover {
  background-color: rgba(83, 99, 255, 0.0392156863);
}

.pForceMargin p {
  margin-bottom: 5px;
}

#enteteCR {
  min-height: 600px;
  height: 80vh;
  max-height: 80vh !important;
  overflow: auto;
  background-color: rgba(255, 255, 255, 0.1647058824);
}
@media (max-width: 767px) {
  #enteteCR {
    min-height: 120px;
    height: 18vh;
    max-height: 18vh;
    overflow: visible;
    background-color: #2E2A47;
  }
}

@media (max-width: 767px) {
  #divActionCr {
    position: fixed;
    bottom: 0;
    padding-bottom: 23px !important;
    padding-top: 10px !important;
    background-color: rgba(213, 212, 218, 0.6352941176) !important;
    margin-left: 0px !important;
    left: 0;
  }
}

._mh_cr {
  min-height: 70px;
}
@media (max-width: 767px) {
  ._mh_cr {
    min-height: 10px;
  }
}

._cr_resume {
  margin-top: -20px;
  min-height: 70vh;
  max-height: 80vh;
  border-radius: 5px;
}
@media (max-width: 767px) {
  ._cr_resume {
    max-height: none !important;
    min-height: 78vh;
    border-radius: 0px !important;
    margin-top: -1px !important;
  }
}

._cr_resume_2 {
  max-height: 80vh;
}
@media (max-width: 767px) {
  ._cr_resume_2 {
    max-height: none !important;
  }
}

.animatedBgCr {
  background: linear-gradient(-45deg, rgba(238, 119, 82, 0.2274509804), rgba(231, 60, 126, 0.2274509804), rgba(35, 166, 213, 0.2274509804), rgba(35, 213, 171, 0.2274509804));
  background-size: 400% 400%;
  animation: gradientCr 4s ease infinite;
  padding: 2px;
  border-radius: 5px;
}

@keyframes gradientCr {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.diagonal-split-background {
  background-color: rgba(255, 255, 255, 0.1647058824);
  min-height: 130px;
  height: 15vh;
  max-height: 15vh;
  overflow: hidden;
}
@media (max-width: 767px) {
  .diagonal-split-background {
    background-color: #013A6B;
    min-height: 150px;
    background-image: -webkit-linear-gradient(275deg, #050025 80%, #FFFFFF 30%);
  }
}

@media (max-width: 767px) {
  ._w_fill_cr_media {
    min-height: 70vh;
    max-height: 70vh;
  }
}

._bg_create_cr {
  background-color: #050025;
}
@media (max-width: 767px) {
  ._bg_create_cr {
    background-color: #2E2A47;
  }
}

#addHorseSecondVolet select {
  margin-left: 10px !important;
}

.infiniteBtn {
  opacity: 0.4;
}

.infiniteBtn:hover {
  opacity: 1;
}

.infiniteBtn.active {
  opacity: 1;
}

#renderSchema .selectable {
  cursor: pointer;
}

#renderSchema .selectable:hover {
  opacity: 0.4;
  fill-opacity: 0.8;
}

.activeAdded {
  color: #566CF0 !important;
  background-color: rgba(226, 223, 255, 0.1647058824);
}
.activeAdded span {
  color: #566CF0 !important;
}

.helpChamps {
  display: none !important;
}

.helpChamps.activeHelpChamps {
  display: flex !important;
}

.bg-danger-second {
  background-color: #EA5550;
}

#menu_remise_replace {
  min-width: 140px;
  width: 200px;
  max-width: 300px;
  min-height: 100vh;
}

@media (min-width: 768px) {
  #menu_remise {
    min-width: 230px;
    max-width: 290px;
    position: fixed;
    z-index: 999;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
  }
}
@media (max-width: 766px) {
  #menu_remise {
    position: fixed;
    z-index: 10000000000000000000;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    min-width: 100%;
    max-width: 100%;
    padding-bottom: 100px !important;
  }
}

#menu_mobile_remise {
  position: fixed;
  top: 0;
  background-color: #665BFF;
  z-index: 9 !important;
}

.chooseMethod p {
  color: #2A3465;
  opacity: 0.4;
  cursor: pointer;
}

.chooseMethod p:hover, .chooseMethod .actif {
  color: #566CF0;
  opacity: 1 !important;
}

@media (max-width: 768px) {
  ._media_header {
    background-color: #665BFF;
  }
}

@media (max-width: 768px) {
  .mt_20_media {
    margin-top: -20px;
  }
}

@media (max-width: 768px) {
  .biggerFontMobile._07 {
    font-size: 0.8rem !important;
  }
}
@media (max-width: 768px) {
  .biggerFontMobile._08 {
    font-size: 0.9rem !important;
  }
}

@media (max-width: 768px) {
  .border-sm-bottom {
    border-bottom: 0.6px solid #CCCCCC;
  }
}

@media (max-width: 768px) {
  ._no_border_sm {
    border: 0px solid transparent !important;
  }
}

@media (max-width: 768px) {
  #bg_header_sms {
    background-color: #6C26F8;
    min-height: 110px;
  }
}

@media (max-width: 768px) {
  ._buy_pack_mobile {
    background-color: white;
    margin-top: -100px;
    border-radius: 15px;
    padding: 20px;
  }
}

._height_head_buy_sms_pack {
  height: 100px;
}
@media (max-width: 768px) {
  ._height_head_buy_sms_pack {
    height: 30px;
  }
}

._height_global_buy_sms_pack {
  height: 300px;
}
@media (max-width: 768px) {
  ._height_global_buy_sms_pack {
    height: 160px;
  }
}

@media (max-width: 768px) {
  .buyPack .send {
    position: fixed;
    bottom: 0;
    margin-bottom: 20px;
    z-index: 99999999999;
    width: 93%;
  }
}

.selectProfilSms:hover {
  background-color: rgba(108, 38, 248, 0.1294117647) !important;
  border-color: rgba(108, 38, 248, 0.1294117647) !important;
}
.selectProfilSms:hover p {
  color: #6C26F8 !important;
}

.selectProfilSmsActive {
  background-color: rgba(108, 38, 248, 0.1294117647) !important;
  border-color: rgba(108, 38, 248, 0.1294117647) !important;
}
.selectProfilSmsActive p {
  color: #6C26F8 !important;
}

@media (min-width: 768px) {
  ._sms_setting_notification_content {
    min-height: 100vh;
    max-height: 100vh;
    overflow: scroll;
    background-color: #1F0045;
    position: fixed;
    right: 0;
  }
}

@media (max-width: 768px) {
  ._header_media_notification_sms {
    background-color: #566CF0;
  }
}

._min_height_notif_sms_media {
  min-height: 100vh;
}
@media (max-width: 768px) {
  ._min_height_notif_sms_media {
    min-height: 240px;
  }
}

@media (max-width: 768px) {
  .text-media-sms-notification {
    color: rgba(102, 102, 102, 0.6666666667);
  }
}
@media (min-width: 769px) {
  .text-media-sms-notification {
    color: rgba(230, 230, 230, 0.6666666667);
  }
}

@media (min-width: 769px) {
  .text-md-white {
    color: white;
  }
}

.under-div-no-margin > div {
  margin: 0px !important;
  padding: 0px !important;
}

._resize_select_prefixe select {
  min-width: 80px !important;
  width: 80px !important;
}

._border_numero .telephone {
  border: 1px solid grey !important !important;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 5px !important;
}

._ann_svg_media {
  height: 100px;
}
@media (max-width: 769px) {
  ._ann_svg_media {
    height: 200px;
  }
}

._ml_sms_help {
  margin-left: auto;
}
@media (max-width: 1169px) {
  ._ml_sms_help {
    margin-left: 0px;
  }
}
@media (max-width: 1009px) {
  ._ml_sms_help {
    margin-left: -70px;
  }
}
@media (max-width: 869px) {
  ._ml_sms_help {
    margin-left: -150px;
  }
}
@media (max-width: 869px) {
  ._ml_sms_help {
    margin-left: auto;
  }
}

._res_img_sms_land {
  height: 35vh;
}
@media (max-width: 869px) {
  ._res_img_sms_land {
    height: 35vh;
    margin-left: -40px;
  }
}

._res_fig_sms_land {
  width: 350px;
}
@media (max-width: 869px) {
  ._res_fig_sms_land {
    width: 290px;
  }
}

._res_land_2_img {
  margin-right: auto;
}
@media (max-width: 1069px) {
  ._res_land_2_img {
    margin-right: 0px;
  }
}
@media (max-width: 999px) {
  ._res_land_2_img {
    margin-right: -100px;
  }
}
@media (max-width: 979px) {
  ._res_land_2_img {
    margin-right: -200px;
  }
}
@media (max-width: 879px) {
  ._res_land_2_img {
    margin-right: auto !important;
    margin-left: auto !important;
  }
}

@media (min-width: 779px) {
  .border-md {
    border: 1px solid #E6E6E6;
  }
}

#chatMessage .imessage {
  background-color: #fff;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  margin: 0 auto 0rem;
  max-width: 100%;
}
#chatMessage .imessage p {
  border-radius: 1.15rem;
  line-height: 1.25;
  max-width: 100%;
  padding: 0.5rem 0.875rem;
  position: relative;
  word-wrap: break-word;
}
#chatMessage .imessage p::before,
#chatMessage .imessage p::after {
  bottom: -0.1rem;
  content: "";
  height: 1rem;
  position: absolute;
}
#chatMessage p.from-me {
  align-self: flex-end;
  background-color: #248bf5;
  color: #fff;
}
#chatMessage p.from-me::before {
  border-bottom-left-radius: 0.8rem 0.7rem;
  border-right: 1rem solid #248bf5;
  right: -0.35rem;
  transform: translate(0, -0.1rem);
}
#chatMessage p.from-me::after {
  background-color: #fff;
  border-bottom-left-radius: 0.5rem;
  right: -40px;
  transform: translate(-30px, -2px);
  width: 10px;
}
#chatMessage p[class^=from-] {
  margin: 0.5rem 0;
  width: fit-content;
}
#chatMessage p.from-me ~ p.from-me {
  margin: 0.25rem 0 0;
}
#chatMessage p.from-me ~ p.from-me:not(:last-child) {
  margin: 0.25rem 0 0;
}
#chatMessage p.from-me ~ p.from-me:last-child {
  margin-bottom: 0.5rem;
}
#chatMessage p.from-them {
  align-items: flex-start;
  background-color: #e5e5ea;
  color: #000;
}
#chatMessage p.from-them:before {
  border-bottom-right-radius: 0.8rem 0.7rem;
  border-left: 1rem solid #e5e5ea;
  left: -0.35rem;
  transform: translate(0, -0.1rem);
}
#chatMessage p.from-them::after {
  background-color: #fff;
  border-bottom-right-radius: 0.5rem;
  left: 20px;
  transform: translate(-30px, -2px);
  width: 10px;
}
#chatMessage p[class^=from-].emoji {
  background: none;
  font-size: 2.5rem;
}
#chatMessage p[class^=from-].emoji::before {
  content: none;
}
#chatMessage .no-tail::before {
  display: none;
}

.newloader .spinner {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #474bff 95%, rgba(0, 0, 0, 0)) 50% 1.1px/13.4px 13.4px no-repeat, radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 15.7px), rgba(71, 75, 255, 0.1) 0);
  animation: spinner-aur408 1s infinite linear;
}
@keyframes spinner-aur408 {
  to {
    transform: rotate(1turn);
  }
}

._w_day_reconduct {
  width: 230px;
}
@media (max-width: 779px) {
  ._w_day_reconduct {
    width: 100px;
    max-width: 100px;
  }
}
@media (max-width: 1079px) {
  ._w_day_reconduct {
    width: 200px;
  }
}

@media (min-width: 827px) {
  .w_min_md_planning {
    min-width: 450px;
  }
}
@media (min-width: 800px) and (max-width: 827px) {
  .w_min_md_planning {
    min-width: 400px;
  }
}

@media (max-width: 767px) {
  ._bg_sm_blue_reconduct {
    background: rgb(80, 56, 244);
    background: -moz-linear-gradient(20deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
    background: -webkit-linear-gradient(20deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
    background: linear-gradient(20deg, rgb(80, 56, 244) 0%, rgb(153, 97, 246) 83%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5038f4",endColorstr="#9961f6",GradientType=1);
  }
}

@media (max-width: 767px) {
  ._h_sm_reconduct_header {
    min-height: 29vh;
    max-height: 29vh;
    height: 29vh;
  }
}

.firstSelectNoPaddingLeft select:first-of-type {
  margin-left: 0px !important;
}

._min_w_select_hour select {
  min-width: 100px;
  margin-right: 10px;
  margin-left: 10px !important;
}

._min_w_select_date select {
  min-width: 60px !important;
  width: 60px !important;
}
@media (max-width: 767px) {
  ._min_w_select_date select {
    min-width: 40px !important;
    width: 40px !important;
    margin-right: 10px !important;
  }
}

._min_w_select_date_mobile select {
  min-width: 46px !important;
  width: 46px !important;
  margin-right: 10px !important;
}

@media (orientation: landscape) {
  .draw_handle_paysage {
    display: flex;
  }
}
@media (orientation: portrait) {
  .draw_handle_paysage {
    display: none;
  }
}

@media (orientation: landscape) {
  .notice_handle_paysage {
    display: none;
  }
}
@media (orientation: portrait) {
  .notice_handle_paysage {
    display: flex;
  }
}

.stop-scroll {
  /* want to fix the height to the window height */
  height: calc(var(--window-inner-height) - 1px);
  /* want to block all overflowing content */
  overflow: hidden;
  /* want to exclude padding from the height */
  box-sizing: border-box;
  overscroll-behavior-y: contain !important;
  -webkit-overflow-scrolling: touch;
}

#drawCircleHelp {
  width: 280px;
}
@media (max-width: 767px) {
  #drawCircleHelp {
    width: 380px;
  }
}

.drawMenuSchema {
  position: fixed !important;
  bottom: 0;
  z-index: 999999;
  max-width: 700px;
  width: 700px;
}
@media (max-width: 420px) {
  .drawMenuSchema {
    max-width: 350px;
    width: 350px;
  }
}

@media (min-width: 420px) {
  ._max_width_140_md {
    max-width: 140px;
    margin-top: auto;
  }
}
@media (max-width: 420px) {
  ._max_width_140_md {
    margin-top: 15px !important;
  }
}

.show_only_portrait_mobile {
  display: none;
}
@media (max-width: 420px) {
  .show_only_portrait_mobile {
    display: flex;
  }
}

.hide_only_portrait_mobile {
  display: flex;
}
@media (max-width: 420px) {
  .hide_only_portrait_mobile {
    display: none;
  }
}

@media (max-width: 420px) {
  ._w_help_div {
    width: 80%;
    margin-bottom: 110px;
  }
}
@media (min-width: 420px) {
  ._w_help_div {
    width: 600px;
    margin-bottom: 60px;
  }
}
@media (min-width: 820px) {
  ._w_help_div {
    width: 600px;
    margin-bottom: 103px;
  }
}

#colorPan {
  margin-top: -120px;
  margin-left: -15px;
}

.dontBreak {
  page-break-inside: avoid !important !important;
  break-inside: avoid-page !important !important;
}

@media print {
  .dontBreak {
    page-break-inside: avoid !important !important;
    break-inside: avoid-page !important !important;
  }
}
._active_dot {
  opacity: 0;
  display: absolute;
}

._activeDrawBtn ._active_dot {
  opacity: 1;
}

@media (max-width: 820px) {
  #addCanvas {
    border: 1px solid rgba(226, 226, 226, 0.6274509804);
  }
}

.customDropDown {
  position: relative;
  margin-top: -2px;
  width: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

#client_resize_banner_right {
  position: fixed;
  right: 0;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  width: 40vw;
  max-width: 420px;
}
@media (max-width: 991px) {
  #client_resize_banner_right {
    position: static;
    height: auto;
    min-height: 10vh;
    max-height: inherit;
    width: 100%;
    max-width: 900px !important;
    background-color: transparent !important;
  }
}

@media (max-width: 991px) {
  #clientExterieur #go_submit {
    position: fixed;
    bottom: 0;
    z-index: 99;
    margin-bottom: 20px !important;
  }
}

#launchSearchHorses {
  font-size: 1.2rem;
}
@media (max-width: 991px) {
  #launchSearchHorses {
    font-size: 30px !important;
    margin-top: -9px !important;
    color: #566CF0 !important;
  }
}

@media (max-width: 991px) {
  .dateInputPlaceholder .raccAge {
    margin-top: 24px !important;
    padding-top: 10px !important;
    width: 100px;
  }
}

.dateInputPlaceholder .birth {
  max-width: 100px;
  padding-left: 4px !important;
  height: 30px;
  font-size: 0.8rem;
}
@media (max-width: 991px) {
  .dateInputPlaceholder .birth {
    padding-top: 10px !important;
    max-width: 130px !important;
    width: 130px;
  }
}

@media (max-width: 991px) {
  .raccAgeMaxWidthMobile {
    max-width: 40px !important;
  }
}

.dateInputPlaceholder input[type=date], .dateInputPlaceholder input[type=date]::-webkit-datetime-edit-day-field, .dateInputPlaceholder input[type=date]::-webkit-datetime-edit-month-field, .dateInputPlaceholder input[type=date]::-webkit-datetime-edit-year-field {
  opacity: var(--field-opacity, 0) !important;
}

.dateInputPlaceholder input[type=date] {
  --field-opacity: 1;
}

.underLabelOpac label {
  opacity: 0.7;
}

.under-div-m-0 > div {
  margin: 0;
  padding: 0;
}

.hoverGrey:hover {
  background-color: #F4F4FF;
}

@media (min-width: 768px) {
  .menuListHorse {
    height: 30px;
    width: 30px;
    background-color: rgba(86, 108, 240, 0.0666666667);
  }
}

#modalSelectHorsesList {
  border-radius: 20px;
  width: 80vw;
  height: 70vh;
  margin-top: auto;
  margin-bottom: auto;
}
#modalSelectHorsesList #contentListSelect {
  height: 78%;
}
@media (max-width: 765px) {
  #modalSelectHorsesList {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    bottom: 0;
    height: 80vh;
    width: 100vw;
  }
  #modalSelectHorsesList #contentListSelect {
    height: 55vh;
  }
}

#_uid_css_09 {
  height: 80vh;
}
@media (max-width: 790px) {
  #_uid_css_09 {
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    margin-top: -30px;
    min-height: 80vh;
    height: auto;
  }
}
#_uid_css_09 #firstStep {
  height: 80vh;
}
@media (max-width: 768px) {
  #_uid_css_09 #firstStep {
    min-height: 60vh;
    height: auto;
  }
}

@media (min-width: 790px) {
  #_med_uid_342 {
    min-width: 380px !important;
  }
}

@media (min-width: 790px) {
  ._mx_land_35 {
    max-width: 35px !important;
  }
}

.choiceSelected {
  border: 1px solid #566CF0;
  background-color: rgba(86, 108, 240, 0.0666666667);
}
.choiceSelected p {
  color: #566CF0;
}

.choiceUnselected {
  background-color: rgba(42, 52, 101, 0.0666666667);
}
.choiceUnselected p {
  color: #2A3465;
  opacity: 0.7;
}

.customNav a {
  color: #B3B3B3;
  background-color: transparent !important;
  font-size: 0.9rem;
  border: 0px solid transparent !important;
  border-radius: 6px;
  transition: linear 0.2s;
}
.customNav a.active {
  color: #566CF0 !important;
  background-color: rgba(86, 108, 240, 0.0666666667) !important;
  border: 0px solid transparent;
  font-weight: 500;
}
.customNav a:hover {
  color: #566CF0 !important;
  border: 0px solid transparent !important;
  background-color: rgba(86, 108, 240, 0.0666666667);
}
.customNav .nav {
  border: 0px solid transparent !important;
}

.leaflet-pane {
  z-index: 0 !important;
}

.leaflet-tile-container {
  z-index: 0 !important;
}

.leaflet-container {
  z-index: 0 !important;
}

@media (min-width: 1310px) {
  #menuMobile_0239 {
    display: none;
  }
}
@media (max-width: 1310px) {
  #menuMobile_0239 {
    display: block;
    position: absolute !important;
    margin-top: -30px;
    width: 80%;
  }
}
@media (max-width: 769px) {
  #menuMobile_0239 {
    width: 90%;
  }
}

@media (min-width: 1310px) {
  #bigContainer_0345 .details {
    display: flex;
  }
}
@media (max-width: 1309px) {
  #bigContainer_0345 .details {
    display: none;
  }
}
@media (min-width: 768px) {
  #bigContainer_0345 .detailsMobile {
    display: none;
  }
}
@media (max-width: 768px) {
  #bigContainer_0345 .detailsMobile {
    display: flex;
  }
}
@media (max-width: 1309px) {
  #bigContainer_0345 .landingPro {
    margin-left: 0px !important;
  }
}
@media (max-width: 768px) {
  #bigContainer_0345 {
    min-height: 240px;
    border-radius: 0px !important;
  }
}

@media (min-width: 1310px) {
  #customNav_0234 {
    display: flex;
    flex-direction: row;
  }
}
@media (max-width: 1309px) {
  #customNav_0234 {
    display: none;
  }
}

#profilContainer_023 {
  min-height: 100vh;
  max-height: 100vh;
  overflow: scroll;
}
@media (max-width: 1309px) {
  #profilContainer_023 {
    min-height: 40vh;
    height: auto;
    max-height: none !important;
  }
}

#container_02342 {
  max-height: none !important;
}
@media (min-width: 1309px) {
  #container_02342 {
    min-height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
}

#horseContainer_02394 {
  max-height: 100vh;
  min-height: 100vh;
  overflow-y: scroll;
}
#horseContainer_02394 .horseList {
  height: 80vh;
  max-height: 80vh;
  overflow: scroll;
}
@media (max-width: 776px) {
  #horseContainer_02394 .horseList {
    height: auto;
    max-height: none;
  }
}
@media (max-width: 1309px) {
  #horseContainer_02394 {
    max-height: none;
    height: auto;
    min-height: 100vh;
    position: fixed;
    width: 34%;
    right: 0;
  }
}
@media (max-width: 1009px) {
  #horseContainer_02394 {
    width: 40%;
  }
}
@media (max-width: 776px) {
  #horseContainer_02394 {
    position: static;
    width: 100%;
  }
}
@media (max-width: 776px) {
  #horseContainer_02394 .leaflet-control {
    margin-top: 60px;
  }
}

@media (max-width: 1309px) {
  #containerMenuMobile {
    margin-left: 80px;
    margin-top: -20px;
  }
}
@media (max-width: 1109px) {
  #containerMenuMobile {
    margin-left: 80px;
    margin-top: -20px;
  }
}
@media (max-width: 992px) {
  #containerMenuMobile {
    margin-left: 40px;
    margin-top: -20px;
  }
}
@media (max-width: 768px) {
  #containerMenuMobile {
    margin-left: 6%;
    margin-top: 0px;
    max-width: 94%;
  }
}

@media (min-width: 992px) and (max-width: 1109px) {
  #notifsSms_0234 {
    /*min-width:450px !important;
    width:100% !important;
    border:2px solid red;
    margin-top:20px !important;
    margin-right:auto !important;*/
    width: 220px !important;
    max-width: 220px !important;
  }
  #notifsSms_0234 img {
    display: none;
  }
}

@media (min-width: 776px) {
  .bg-med-white {
    background-color: rgba(255, 255, 255, 0.9333333333);
  }
}

@media (max-width: 1175px) {
  #contain_sett_sms {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 1275px) {
  #_prof_client_details_rdv {
    position: fixed;
    height: 280px;
    width: 23vw;
    min-width: 23vw;
    overflow-y: auto;
  }
}
@media (min-width: 1175px) {
  #_prof_client_details_rdv {
    position: fixed;
    height: 280px;
    width: 300px;
    overflow-y: auto;
  }
}
@media (min-width: 992px) {
  #_prof_client_details_rdv {
    width: 250px;
    position: fixed;
    overflow-y: auto;
    height: 280px;
  }
}
@media (max-width: 991px) {
  #_prof_client_details_rdv {
    width: 55vw;
    position: fixed;
    overflow-y: auto;
    height: 400px;
    bottom: 0;
    z-index: 99999;
    margin-left: -30px;
    display: none;
  }
}
@media (max-width: 768px) {
  #_prof_client_details_rdv {
    width: 94vw;
    position: fixed;
    overflow-y: auto;
    height: 400px;
    bottom: 0;
    z-index: 99999;
    margin-left: -30px;
    display: none;
    border: 1px solid rgba(226, 226, 226, 0.6274509804);
  }
}

@media (min-width: 992px) {
  #shadeBackground {
    width: 60vw;
    position: fixed;
    overflow-y: auto;
    height: 280px;
  }
}
@media (max-width: 991px) {
  #shadeBackground {
    width: 60vw;
    position: fixed;
    overflow-y: auto;
    height: 400px;
    bottom: 0;
    z-index: 99999;
    margin-left: -30px;
    display: none;
  }
}

/* Reset some default styles for consistency across browsers */
.select-container select {
  appearance: none;
  border: none;
  background: none;
  padding: 6px;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  width: 100%;
  outline: none;
  cursor: pointer;
  font-size: 0.9rem;
  border: none; /* Remove additional border added by Symfony */
  background: transparent; /* Remove additional background added by Symfony */
  -webkit-appearance: none; /* Remove default styling in some versions of Safari */
  min-width: 200px;
  color: #2A3465;
}

/* Style for the select container */
.select-container > div {
  position: relative;
  display: inline-block;
  background-color: rgba(42, 52, 101, 0.0666666667);
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 4px;
  padding-left: 9px;
  cursor: pointer;
  min-width: 150px;
}

.select-container-override-min-width select {
  min-width: 100px;
}
.select-container-override-min-width > div {
  min-width: 70px;
}

/*
.select-container>div::after {
  content: '\e5c5';
  font-family: 'Glyphicons Halflings';
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 14px;
}*/
.select-container > div::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 13px;
  transform: translateY(-50%);
  width: 13px;
  height: 12px;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="grey" d="M7.293 10.293a1 1 0 0 1-1.414 0L1.586 5.879A1 1 0 1 1 2.999 4.465l4.708 4.708L12.414 4.464a1 1 0 0 1 1.415 1.415l-5.708 5.709z"></path></svg>');
  background-size: cover;
  opacity: 0.6;
  pointer-events: none;
}

/* Style for the selected option */
.select-container > div select option:checked {
  font-weight: bold;
}

.select-container > div select:focus {
  background-color: rgba(42, 52, 101, 0.0666666667) !important;
}

/* Hover effect */
.select-container > div:hover {
  border-color: rgba(42, 52, 101, 0.1647058824);
}

._part_2_pro svg {
  width: 80%;
}
@media (max-width: 762px) {
  ._part_2_pro svg {
    width: 100% !important;
  }
}

.floatAnim {
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}
#_media_dep_tournees {
  margin-left: auto;
}
@media (max-width: 1292px) {
  #_media_dep_tournees {
    margin-left: -140px !important;
  }
}
@media (max-width: 1100px) {
  #_media_dep_tournees {
    margin-left: -210px !important;
  }
}
@media (max-width: 790px) {
  #_media_dep_tournees {
    margin-left: -230px !important;
  }
}
@media (max-width: 768px) {
  #_media_dep_tournees {
    margin-left: 0px !important;
  }
}
@media (max-width: 568px) {
  #_media_dep_tournees {
    margin-left: -100px !important;
  }
}

#animatedCard .card-container {
  position: relative;
  width: 300px;
  height: 400px;
  perspective: 1000px;
}
#animatedCard .card {
  position: absolute;
  width: 100%;
  height: auto;
  background-size: cover;
  background-position: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  transform-style: preserve-3d;
  transition: transform 0.5s, opacity 0.6s;
  backface-visibility: hidden;
}
#animatedCard .card:nth-child(1) {
  z-index: 3;
  transform: translateY(0) scale(1);
  opacity: 1;
}
#animatedCard .card:nth-child(2) {
  z-index: 2;
  transform: rotate(3deg) scale(0.7);
  opacity: 0.8;
}
#animatedCard .card:nth-child(3) {
  z-index: 1;
  transform: rotate(-3deg) scale(0.5);
  opacity: 0.6;
}

#_media_7_pro {
  margin-left: -200px !important;
}
@media (max-width: 1242px) {
  #_media_7_pro {
    margin-left: -260px !important;
  }
}
@media (max-width: 1100px) {
  #_media_7_pro {
    margin-left: -320px !important;
  }
}
@media (max-width: 1000px) {
  #_media_7_pro {
    margin-left: -390px !important;
  }
}
@media (max-width: 768px) {
  #_media_7_pro {
    margin-left: -200px !important;
  }
  #_media_7_pro #_media_rap_7 {
    margin-left: -40px !important;
  }
}

.testimoniesTitles .inactiveTestimony {
  opacity: 0.4;
  cursor: pointer;
}

@media (max-width: 568px) {
  #Calque_Main_Pres {
    margin-left: -100px !important;
  }
}

#menuPhoneLand1 {
  margin-top: -15px;
}
@media (max-width: 568px) {
  #menuPhoneLand1 {
    margin-top: -10px;
  }
}

#containerMapGlobal {
  top: 0;
  margin-top: 23vh;
}
@media (max-width: 768px) {
  #containerMapGlobal {
    margin-top: 5px;
    left: 30%;
    right: 50vw;
  }
}
@media (max-width: 668px) {
  #containerMapGlobal {
    margin-top: 5px;
    left: 13%;
  }
}

#detailsStructure {
  width: 300px;
  max-width: 300px;
  top: 0;
}
@media (max-width: 768px) {
  #detailsStructure {
    position: fixed;
    bottom: 0 !important;
    width: 100vw;
    max-width: 100vw;
    left: 0 !important;
    max-height: 400px !important;
    margin-bottom: 0 !important;
    top: auto;
  }
}

@media (max-width: 568px) {
  .forceWhiteMobile input {
    background-color: rgba(255, 255, 255, 0.6666666667) !important;
  }
  .forceWhiteMobile input:focus {
    background-color: rgba(255, 255, 255, 0.6666666667) !important;
  }
}

.good_bg-md-white {
  background-color: white;
}
@media (max-width: 568px) {
  .good_bg-md-white {
    background-color: transparent;
  }
}

._bgcarriere {
  background-color: #F2F2F2;
}

.menuHorse p {
  opacity: 0.4;
  transition: 0.2s;
  cursor: pointer;
}

.menuHorse p:hover {
  opacity: 1;
}

.showBlocSoin {
  background-color: rgba(255, 255, 255, 0.1215686275);
}
.showBlocSoin p {
  color: white;
}

.showBlocSoinActive {
  background-color: white !important;
}
.showBlocSoinActive p {
  color: black !important;
}

._prof_square {
  min-width: 185px;
  width: 200px;
}
@media (max-width: 568px) {
  ._prof_square {
    min-width: 150px;
    width: 160px;
  }
}

.activatedDivCheck {
  border: 1px solid #566CF0;
  color: #566CF0;
}
.activatedDivCheck p, .activatedDivCheck i, .activatedDivCheck select, .activatedDivCheck option {
  color: #566CF0;
}
.activatedDivCheck .bi-check-circle {
  display: block;
}
.activatedDivCheck .bi-circle {
  display: none;
}

.inactivatedDivCheck {
  border: 1px solid #E2E2E2;
  color: #E2E2E2;
}
.inactivatedDivCheck p, .inactivatedDivCheck i, .inactivatedDivCheck select, .inactivatedDivCheck option {
  color: #A2A2A2;
}
.inactivatedDivCheck .bi-circle {
  display: block;
}
.inactivatedDivCheck .bi-check-circle {
  display: none;
}

.inactivatedDivCheck:hover {
  border: 1px solid #566CF0 !important;
}
.inactivatedDivCheck:hover p, .inactivatedDivCheck:hover i, .inactivatedDivCheck:hover select, .inactivatedDivCheck:hover option {
  color: #566CF0 !important;
}

#radialProgressBar {
  /*===== The CSS =====*/
}
#radialProgressBar #wrapper {
  position: relative;
  width: 404px;
}
#radialProgressBar .progress {
  width: 100px;
  height: 110px;
}
#radialProgressBar .progress .track, #radialProgressBar .progress .fill {
  fill: rgba(0, 0, 0, 0);
  stroke-width: 3;
  transform: rotate(90deg) translate(0px, -80px);
}
#radialProgressBar .progress.blue .track {
  stroke: rgb(104, 214, 198);
}
#radialProgressBar .progress.red .track {
  stroke: #FF8B6A;
}
#radialProgressBar .progress.blue .fill {
  stroke: rgb(104, 214, 198);
  stroke-dasharray: 219.9907836914;
  stroke-dashoffset: -219.9907836914;
  transition: stroke-dashoffset 1s;
}
#radialProgressBar .progress.red .fill {
  stroke: rgba(226, 226, 226, 0.6352941176);
  stroke-dasharray: 219.9907836914;
  stroke-dashoffset: -219.9907836914;
  transition: stroke-dashoffset 1s;
}
#radialProgressBar .progress .fill {
  stroke: rgba(226, 226, 226, 0.6352941176);
}
#radialProgressBar .progress.blue .value, #radialProgressBar .progress.blue .text {
  fill: rgb(104, 214, 198);
  text-anchor: middle;
}
#radialProgressBar .progress.red .value, #radialProgressBar .progress.red .text {
  fill: #FF8B6A;
  text-anchor: middle;
}
#radialProgressBar .progress .text {
  font-size: 12px;
}
#radialProgressBar .noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.customNav .nav-link {
  font-size: 0.8rem;
  font-weight: 400;
  color: #CDCBD5;
}
.customNav .nav-link.active {
  color: #566CF0;
  background-color: transparent;
  border: 0px solid transparent;
}

@media (max-width: 798px) {
  #moove_export_mobile {
    margin-top: -70px !important;
  }
}

#customAlertConfirm_ {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0 !important;
  background-color: rgba(0, 0, 0, 0.4352941176);
  z-index: 9999999999 !important;
}
#customAlertConfirm_ #confirm {
  background-color: white;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
  padding: 27px;
  max-width: 400px;
}
@media (max-width: 798px) {
  #customAlertConfirm_ #confirm {
    width: 100vw;
    height: 56vh;
    max-width: none !important;
    bottom: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

.customAlertConfirm_ {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0 !important;
  background-color: rgba(0, 0, 0, 0.4352941176);
  z-index: 9999999999 !important;
}
.customAlertConfirm_ .confirm {
  background-color: white;
  border-radius: 10px;
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
  padding: 27px;
  max-width: 400px;
}
@media (max-width: 798px) {
  .customAlertConfirm_ .confirm {
    width: 100vw;
    min-height: 56vh;
    height: auto;
    max-width: none !important;
    bottom: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}

@media (max-width: 758px) {
  #bg_round {
    background-image: url("../../public/icones/bg_round.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
}

._handle_mobile_sf img {
  display: none;
}
._handle_mobile_sf p, ._handle_mobile_sf h3 {
  color: #1A0055 !important;
}
@media (max-width: 430px) {
  ._handle_mobile_sf img {
    display: block;
  }
  ._handle_mobile_sf p, ._handle_mobile_sf h3 {
    color: white !important;
  }
}

#_resize_add_horse {
  height: 70vh;
}
@media (max-width: 758px) {
  #_resize_add_horse {
    height: 61vh;
  }
}
@media (max-width: 400px) {
  #_resize_add_horse {
    height: 58vh;
  }
}

.divCheckActivate ._inactivated {
  border: 1px solid rgba(30, 0, 101, 0.6352941176);
  opacity: 0.4;
  transition: 0.3s;
}
.divCheckActivate ._inactivated p {
  color: #1E0065;
  font-weight: 400;
}
.divCheckActivate ._inactivated:hover {
  border: 1px solid #566CF0;
  background-color: rgba(86, 108, 240, 0.0666666667);
  opacity: 1;
}
.divCheckActivate ._inactivated:hover p {
  color: #566CF0;
  font-weight: 500;
}
.divCheckActivate ._activated {
  border: 1px solid #566CF0;
  background-color: rgba(86, 108, 240, 0.0666666667);
}
.divCheckActivate ._activated p {
  color: #566CF0;
  font-weight: 500;
}

.note-toolbar {
  border-bottom: 1px solid #E2E2E2;
  padding-bottom: 10px;
}
.note-toolbar button {
  border: 1px solid #E2E2E2;
}
.note-toolbar .activeMe {
  background-color: rgba(226, 226, 226, 0.6666666667) !important;
}
.note-toolbar .note-icon-caret {
  display: none;
}
.note-toolbar .note-dropdown-menu li {
  padding: 5px;
  border-bottom: 1px solid #E2E2E2;
}
.note-toolbar .note-dropdown-menu li a {
  color: #2A3465;
}

.note-editable li {
  list-style: circle;
}

.bgGradientBlurred {
  background: rgb(80, 56, 244);
  background: -moz-linear-gradient(20deg, rgba(80, 56, 244, 0.5172443978) 0%, rgba(85, 111, 245, 0.637692577) 58%, rgba(153, 97, 246, 0.6012780112) 43%);
  background: -webkit-linear-gradient(20deg, rgba(80, 56, 244, 0.5172443978) 0%, rgba(85, 111, 245, 0.637692577) 58%, rgba(153, 97, 246, 0.6012780112) 33%);
  background: linear-gradient(20deg, rgba(80, 56, 244, 0.5172443978) 0%, rgba(85, 111, 245, 0.637692577) 58%, rgba(153, 97, 246, 0.6012780112) 13%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5038f4",endColorstr="#9961f6",GradientType=1);
}

.bgGradientVertical {
  background: rgb(118, 105, 223);
  background: -moz-linear-gradient(178deg, rgba(118, 105, 223, 0.16) 0%, rgba(85, 111, 245, 0.0476365546) 8%, rgba(199, 166, 247, 0.1) 27%, rgba(56, 134, 244, 0) 40%);
  background: -webkit-linear-gradient(178deg, rgba(118, 105, 223, 0.16) 0%, rgba(85, 111, 245, 0.0476365546) 8%, rgba(199, 166, 247, 0.1) 27%, rgba(56, 134, 244, 0) 40%);
  background: linear-gradient(178deg, rgba(118, 105, 223, 0.16) 0%, rgba(85, 111, 245, 0.0476365546) 8%, rgba(199, 166, 247, 0.2) 21%, rgba(56, 134, 244, 0) 40%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7669df",endColorstr="#3886f4",GradientType=1);
}

.inactive_menu p {
  opacity: 0.4;
}

@media (min-width: 758px) {
  ._min_w_media_reassort {
    min-width: 550px;
  }
}

@media (max-width: 758px) {
  #header_produit_stock {
    background-color: #1D004B;
  }
}

#squareCurrentStock {
  width: 54%;
}
@media (max-width: 758px) {
  #squareCurrentStock {
    width: 100%;
  }
}

@media (max-width: 758px) {
  #searchStock {
    background-color: rgba(255, 255, 255, 0.1294117647);
    color: white;
  }
}

@media (max-width: 758px) {
  #searchStock::placeholder {
    color: white;
  }
}

@media (max-width: 758px) {
  ._mt_sm_50 {
    margin-top: -50px;
  }
}

@media (max-width: 758px) {
  ._flex_wrap_md {
    flex-wrap: wrap;
  }
}

._bg_blurred {
  background-image: url("../../public/icones/blurred_bg.jpg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height: 16vh !important;
}

._bg_blurred_blue {
  background-image: url("../../public/icones/blurred_blue.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 16vh !important;
}

._bg_blurred_zoom {
  background-image: url("../../public/icones/blurred_bg.jpg");
  background-repeat: no-repeat;
  background-size: 190%;
  background-position: right;
  min-height: 16vh !important;
}

._bg_blurred_opac {
  background-image: url("../../public/icones/blurred_bg_opacity.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 16vh !important;
}

._mt_up_stock_header_index {
  margin-top: 50px;
}
@media (max-width: 758px) {
  ._mt_up_stock_header_index {
    background-color: #FAFBFC;
    margin-top: -50px;
  }
}

.gradient_transparent {
  background: rgb(118, 105, 223);
  background: -moz-linear-gradient(177deg, rgba(118, 105, 223, 0.6853116246) 0%, rgb(239, 243, 250) 24%, rgb(255, 255, 255) 99%);
  background: -webkit-linear-gradient(177deg, rgba(118, 105, 223, 0.6853116246) 0%, rgb(239, 243, 250) 24%, rgb(255, 255, 255) 99%);
  background: linear-gradient(177deg, rgba(118, 105, 223, 0.6853116246) 0%, rgb(239, 243, 250) 24%, rgb(255, 255, 255) 99%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#7669df",endColorstr="#ffffff",GradientType=1);
}

#bloc1 {
  min-height: 1180px;
}

#_fac_1 {
  background-color: #86869E;
  width: 880px;
  min-height: 1px;
  height: 1px;
}

#_fac_2 {
  width: 200px;
  vertical-align: top;
}

#_fac_3 {
  color: #7311FF;
}

._fac_w_250 {
  width: 250px;
}

._fac_w_340 {
  width: 340px;
}

.vertical-align-top {
  vertical-align: top;
}

._fac_w_50 {
  width: 50px;
}

._fac_w_100 {
  width: 100px;
}

._fac_w_110 {
  width: 110px;
}

._fac_w_130 {
  width: 130px;
}

._fac_w_70 {
  width: 70px;
}

._fac_w_200 {
  width: 200px;
}

._fac_w_450 {
  width: 450px;
}

._fac_w_550 {
  width: 550px;
}

.color7311FF {
  color: #7311FF;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

._fac_max_w_113 {
  max-width: 113px;
}

._fac_max_w_130 {
  max-width: 130px;
}

.overflow-visible {
  overflow: visible;
}

.page-break-inside-auto {
  page-break-inside: auto;
}

._pt_40 {
  padding-top: 40px;
}

._fac_w_580 {
  width: 580px;
}

._fac_w_360 {
  width: 360px;
}

._fac_w_280 {
  width: 280px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.color-white {
  color: white;
}

._fac_mt_40 {
  margin-top: 40px;
}

._fac_ml_50 {
  margin-left: 50px;
}

.page-break-inside-avoid {
  page-break-inside: avoid !important;
}

._fac_mt_20 {
  margin-top: 20px;
}

._fac_max_h_10 {
  max-height: 10px;
}

._fac_w_600 {
  width: 600px;
}

._fac_w_300 {
  width: 300px;
}

.colorFF4C37 {
  color: #FF4C37 !important;
}

._fac_round_border {
  border: 1px solid #FF4C37;
  border-radius: 10px;
  padding: 10px;
  margin-left: 45px;
  margin-top: 10px;
  width: 270px;
}

#_span_fac_inside {
  font-weight: 800 !important;
  font-size: 16px !important;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}